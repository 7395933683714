import React, { useState, useEffect } from "react";

interface LineIconProps {
  heightFactor?: number; // Коэффициент масштабирования
  color?: string;
}

export const Line: React.FC<LineIconProps> = ({
  heightFactor = 20, // Можно подстраивать коэффициент
  color = "#000",
}) => {
  const [size, setSize] = useState(32); // Начальный размер

  useEffect(() => {
    const updateSize = () => {
      const newSize = Math.min(
        40,
        Math.max(24, window.innerHeight / heightFactor)
      );
      setSize(newSize);
    };

    updateSize(); // Вызываем при монтировании
    window.addEventListener("resize", updateSize); // Слушаем изменения экрана
    return () => window.removeEventListener("resize", updateSize); // Убираем слушатель
  }, [heightFactor]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2px"
      height={`${size}px`}
      fill="none"
      viewBox="0 0 2 32"
    >
      <path stroke={color} strokeWidth="2" d="M1 .5v31"></path>
    </svg>
  );
};
