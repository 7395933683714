import React, { useState, useEffect } from "react";

interface TwitterXIconProps {
  heightFactor?: number; // Коэффициент масштабирования
  color?: string;
  width?: number;
  height?: number;
}

export const TwitterXIcon: React.FC<TwitterXIconProps> = ({
  width = 32,
  height = 32,
  heightFactor = 20, // Можно подстраивать коэффициент
  color = "#000",
}) => {
  const [size, setSize] = useState(32); // Начальный размер

  useEffect(() => {
    const updateSize = () => {
      const newSize = Math.min(
        40,
        Math.max(24, window.innerHeight / heightFactor)
      );
      setSize(newSize);
    };

    updateSize(); // Вызываем при монтировании
    window.addEventListener("resize", updateSize); // Слушаем изменения экрана
    return () => window.removeEventListener("resize", updateSize); // Убираем слушатель
  }, [heightFactor]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}px`}
      height={`${size * (32 / 40)}px`}
      viewBox="0 0 32 32"
      fill="none"
      style={{ display: "block" }} // Убираем пробелы вокруг SVG
    >
      <path
        fill={color}
        d="M14.082 19.535 20.122 28H29l-9.966-13.97L27.326 4h-3.361l-6.49 7.848L11.878 4H3l9.525 13.353L3.723 28h3.36zm7.308 5.798L8.073 6.667h2.537l13.317 18.666z"
      ></path>
    </svg>
  );
};
