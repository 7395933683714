import styles from "./styles.module.css";
import { theme } from "../../../constants/theme";
import face from "assets/images/face-our-story.png";
import AudioOn from "assets/svg/audio-on";
import AudioOff from "assets/svg/audio-off";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { CustomButtonSection } from "components/custom-button-section";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import forbesImg from "assets/images/forbes-section2-mobile.png";
import { Slideshow } from "components/animation-forbes";

export const OurStory: React.FC = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const [isAudioOn, setIsAudioOn] = useState(true);
  const navigate = useNavigate();

  const toggleAudio = () => {
    setIsAudioOn((prev) => !prev);
  };
  const handleExploreClick = () => {
    navigate("/detail/neurodivergent-learning");
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.blockContent}>
          {!isDesktop && <Slideshow variant="white" />}
          <div className={styles.content}>
            <CustomHeadline
              size={isDesktop ? 55 : 24}
              text="Reinventing Learning for 1,6B+ Neurodivergent Minds"
              color={
                isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[0]
              }
              styles={{ lineHeight: `${isDesktop ? 52 : 26}px` }}
            />
            <div className={styles.p}>
              <CustomText
                size={isDesktop ? 24 : 14}
                text="A sentient AI framework engineered to accelerate, elevate, and personalize learning for every individual."
                color={
                  isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[0]
                }
                styles={{ lineHeight: `${isDesktop ? 28 : 17}px` }}
              />
            </div>
            <CustomButtonSection
              text="Learn More"
              colorScheme="green"
              onClick={handleExploreClick}
              style={{
                color: isDesktop
                  ? theme.colors.neutral[20]
                  : theme.colors.neutral[0],
                marginTop: isDesktop ? "2%" : "7%",
              }}
            />
          </div>
        </div>
        <img className={styles.image} src={face} alt="fase" />
        {/* <div className={styles.imgAudio} onClick={toggleAudio}>
          {isAudioOn ? <AudioOn /> : <AudioOff />}
        </div> */}
      </div>
    </>
  );
};
