import React, { useState, useEffect } from "react";

interface CheckIconProps {
  heightFactor?: number;
  color?: string;
}

export const Check: React.FC<CheckIconProps> = ({
  heightFactor = 20,
  color = "#000",
}) => {
  const [size, setSize] = useState(32);

  useEffect(() => {
    const updateSize = () => {
      const newSize = Math.min(
        40,
        Math.max(24, window.innerHeight / heightFactor)
      );
      setSize(newSize);
    };

    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [heightFactor]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      fill="none"
      viewBox="0 0 32 32"
      style={{ display: "block" }}
    >
      <path
        fill={color}
        d="m16 20-7-6.907L10.108 12 16 17.814 21.892 12 23 13.093z"
      ></path>
    </svg>
  );
};
