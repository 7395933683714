import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { CustomButtonSection } from "components/custom-button-section";
import sIcon from "../../assets-web3/image/s-section7-web-3.png";
import { useEffect, useRef, useState } from "react";
import { Section6Web3 } from "../section6-web3";
import network from "../../assets-web3/image/image 91.png";
import tolen from "../../assets-web3/image/Screenshot 2025-01-30 at 21.15.33 2.png";
import { useContext } from "react";
import { ScrollContext } from "web3-landing-page";
import { storage, ref, getDownloadURL } from "../../../config/firebase";
import fallbackImage from "../../../assets/images/fon-section-9-mobile.png";

export const Section7Web3 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoError, setVideoError] = useState(false);
  const { scrollToSection } = useContext(ScrollContext);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoUrlMp4, setVideoUrlMp4] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchVideoUrls = async () => {
      try {
        const timeout = setTimeout(() => {
          setVideoError(true);
          console.error("Video fetch timeout: Falling back to image.");
        }, 5000);

        const videoFile = device === "mobile" ? "5(400).webm" : "5(1920).webm";
        const mp4File = "5(1920).mp4";

        try {
          const webmUrl = await getDownloadURL(ref(storage, videoFile));

          const canPlayWebM = document
            .createElement("video")
            .canPlayType("video/webm");
          if (canPlayWebM) {
            setVideoUrl(webmUrl);
          } else {
            throw new Error("WebM not supported");
          }
        } catch (error) {
          console.warn("WebM not found or not supported, using MP4 fallback.");

          const mp4Url = await getDownloadURL(ref(storage, mp4File));
          setVideoUrlMp4(mp4Url);
        }

        clearTimeout(timeout);
      } catch (error) {
        console.error("Error fetching video:", error);
        setVideoError(true);
      }
    };

    fetchVideoUrls();
  }, [device]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute("playsinline", "");
      videoRef.current.setAttribute("muted", "");
      videoRef.current.play().catch(() => console.log("Autoplay blocked"));
    }
  }, [videoUrl]);

  return (
    <div
      className={`${styles.container} ${videoError ? styles.fallbackBg : ""}`}
    >
      {!isLoaded && (
        <img
          src={fallbackImage}
          alt="Video placeholder"
          className={styles.videoBg}
        />
      )}
      {!videoError && videoUrl && (
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          className={styles.videoBg}
          onError={() => setVideoError(true)}
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
          preload="auto"
        >
          <source src={videoUrl} type="video/webm" />
          {videoUrlMp4 && <source src={videoUrlMp4} type="video/mp4" />}
        </video>
      )}
      {!isDesktop && <img src={sIcon} alt="img" />}
      <div className={styles.centuries}>
        <CustomHeadline
          size={isDesktop ? 60 : 24}
          text="GreenToken: The Fuel of Cognitive AI"
          color={theme.colors.neutral[0]}
          styles={{
            fontWeight: 600,
            lineHeight: isDesktop ? "54px" : "26px",
            textAlign: isDesktop ? "left" : "center",
          }}
        />
        <CustomText
          size={isDesktop ? 24 : 19}
          text="$SCRB powers neuroadaptive AI, rewards intelligence sharing, and drives decentralized learning economies."
          color={theme.colors.neutral[0]}
          styles={{
            textAlign: isDesktop ? "left" : "center",
            margin: "0 auto",
            lineHeight: isDesktop ? "28px" : "23px",
            fontWeight: 400,
            marginBottom: isDesktop ? "1.35dvw" : "",
          }}
        />
        {isDesktop && <Section6Web3 />}
        <CustomButtonSection
          text="Buy $SCRB Now"
          colorScheme="green"
          onClick={() => scrollToSection("payment")}
          style={{
            color: theme.colors.neutral[0],
            marginTop: isDesktop ? "1.35dvw" : "",
          }}
        />
      </div>
      {isDesktop && (
        <div className={styles.blockImg}>
          <div className={styles.networkTockenBlock}>
            <div className={`${styles.network} ${styles.token}`}>
              <CustomText
                size={isDesktop ? 32 : 17}
                text="Network"
                color={theme.colors.neutral[0]}
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: "23px",
                  fontWeight: 400,
                }}
              />
              <img src={network} alt="img" className={styles.img1} />
              <CustomText
                size={isDesktop ? 32 : 17}
                text="Base"
                color={theme.colors.neutral[0]}
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: "23px",
                  fontWeight: 400,
                }}
              />
            </div>

            <div className={styles.network}>
              <CustomText
                size={isDesktop ? 32 : 17}
                text="Token supply"
                color={theme.colors.neutral[0]}
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: "23px",
                  fontWeight: 400,
                }}
              />
              <img src={tolen} alt="img" className={styles.img2} />
              <CustomText
                size={isDesktop ? 32 : 17}
                text="9b SCRB"
                color={theme.colors.neutral[0]}
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: "23px",
                  fontWeight: 400,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
