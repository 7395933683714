import { useState, useEffect } from "react";
import styles from "./paymentOptions.module.css";
import { SelectableCard } from "../selectableCard/SelectableCard";
import usdtIcon from "../../../assets-web3/image/cryptocurrency_usdt.png";
import usdcIcon from "../../../assets-web3/image/Mask group.png";
import bnbIcon from "../../../assets-web3/image/cryptocurrency_bnb.png";
import ethIcon from "../../../assets-web3/image/cryptocurrency_eth.png";

import { usePresaleContract } from "../../presale/usePresaleContract";
import { formatEther } from "viem";
import { useDeviceType } from "hooks";

const PaymentOptions: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [buyAmount, setBuyAmount] = useState("");

  const { buyTokens, isBuyLoading, isBuySuccess } = usePresaleContract();

  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const options = [
    {
      id: "usdt",
      text: "USDT",
      icon: usdtIcon,
      textSize: isDesktop ? "0.83dvw" : "3.7dvw",
    },
    {
      id: "usdc",
      text: "USDC",
      icon: usdcIcon,
      textSize: isDesktop ? "0.83dvw" : "3.7dvw",
    },
    {
      id: "bnb",
      text: "BNB",
      icon: bnbIcon,
      textSize: isDesktop ? "0.83dvw" : "3.7dvw",
    },
    {
      id: "eth",
      text: "ETH",
      icon: ethIcon,
      textSize: isDesktop ? "0.83dvw" : "3.7dvw",
    },
  ];
  const handleSelect = (id: string) => {
    if (selectedOption === id) return;
    setSelectedOption(id);
  };

  return (
    <div className={styles.grid}>
      {options.map((option) => (
        <SelectableCard
          key={option.id}
          text={option.text}
          icon={option.icon}
          textSize={option.textSize}
          /*lineHeight={option.lineHeight}*/
          isSelected={selectedOption === option.id}
          onClick={() => handleSelect(option.id)}
        />
      ))}
    </div>
  );
};

export default PaymentOptions;
