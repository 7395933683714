import React, { CSSProperties, useState, useEffect } from "react";
import s from "./styles.module.css";

interface IProps {
  text: string;
  size?: number;
  color?: string;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  styles?: CSSProperties;
  designWidth?: number;
  designHeight?: number;
  mobileDesignWidth?: number;
  mobileDesignHeight?: number;
}

export const CustomHeadline = ({
  text,
  size = 24,
  color = "#000000",
  variant = "h2",
  styles,
  designWidth = 1920,
  designHeight = 1080,
  mobileDesignWidth = 430,
  mobileDesignHeight = 800,
}: IProps) => {
  const [fontSize, setFontSize] = useState(size);

  const calculateFontSize = (
    base: number,
    screenWidth: number,
    screenHeight: number
  ) => {
    const isMobile = screenWidth < 768;
    const currentDesignWidth = isMobile ? mobileDesignWidth : designWidth;
    const currentDesignHeight = isMobile ? mobileDesignHeight : designHeight;

    const widthScale = screenWidth / currentDesignWidth;
    const heightScale = screenHeight / currentDesignHeight;

    const scaleFactor = (widthScale + heightScale) / 2;
    return base * scaleFactor;
  };

  useEffect(() => {
    const handleResize = () => {
      const newFontSize = calculateFontSize(
        size,
        window.innerWidth,
        window.innerHeight
      );
      setFontSize(newFontSize);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size, designWidth, designHeight, mobileDesignWidth, mobileDesignHeight]);

  const getBaseLineHeight = (): number => {
    if (styles && styles.lineHeight !== undefined) {
      if (typeof styles.lineHeight === "number") return styles.lineHeight;
      if (typeof styles.lineHeight === "string")
        return parseFloat(styles.lineHeight);
    }
    return size - 2;
  };

  const baseLineHeight = getBaseLineHeight();

  const computedLineHeight = `${(baseLineHeight / size) * fontSize}px`;

  const commonStyles = {
    ...styles,
    fontSize: `${fontSize}px`,
    lineHeight: computedLineHeight,
    color,
  };

  switch (variant) {
    case "h1":
      return <h1 style={commonStyles}>{text}</h1>;
    case "h2":
      return (
        <h2 style={commonStyles} className={s.h2}>
          {text}
        </h2>
      );
    case "h3":
      return (
        <h3 style={commonStyles} className={s.h3}>
          {text}
        </h3>
      );
    default:
      return (
        <h2 style={commonStyles} className={s.h2}>
          {text}
        </h2>
      );
  }
};
