import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { logEvent } from "../utils/analytics";

export const usePageTracking = () => {
  const location = useLocation();
  const prevPathRef = useRef<string>("");
  const pageEntryTimeRef = useRef<number>(Date.now());

  useEffect(() => {
    const currentPath = location.pathname;
    const currentTime = Date.now();

    if (prevPathRef.current && prevPathRef.current !== currentPath) {
      const timeSpentOnPrevPage = currentTime - pageEntryTimeRef.current;

      logEvent("page_exit", {
        page_path: prevPathRef.current,
        time_spent: Math.round(timeSpentOnPrevPage / 1000),
      });
    }

    logEvent("page_view", {
      page_path: currentPath,
      page_location: window.location.href,
      page_title: document.title,
    });

    prevPathRef.current = currentPath;
    pageEntryTimeRef.current = currentTime;

    return () => {
      const timeSpent = Date.now() - pageEntryTimeRef.current;
      logEvent("page_exit", {
        page_path: currentPath,
        time_spent: Math.round(timeSpent / 1000),
      });
    };
  }, [location]);
};
