import { useCallback, useEffect } from "react";
import { parseEther, parseUnits } from "viem";
import {
  useReadContract,
  useSendTransaction,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import ABI from "./abi.json";
import * as erc20Abi from "./abi.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const COLD_WALLET_ADDRESS =
  "0x1c3090778Ef35D317AacD13480893759E3F62EEc" as const;

const CONTRACT_ADDRESS = "0xAA783B31CE24c99163Edd3c4A858BfA2696F2837" as const;

const USDT_CONTRACT_ADDRESS =
  "0xAA783B31CE24c99163Edd3c4A858BfA2696F2837" as const;
const USDC_CONTRACT_ADDRESS =
  "0xAA783B31CE24c99163Edd3c4A858BfA2696F2837" as const;

const USDT_DECIMALS = 6 as const;
const USDC_DECIMALS = 18 as const;

interface PresaleContractReturn {
  currentPrice: bigint | undefined;
  currentStage: bigint | undefined;
  stageDuration: bigint | undefined;
  fundsRaisedInUsd: bigint | undefined;
  buyTokens: (amount: string) => void;
  approveTokens: (amount: string, token: string, decimals: number) => void;
  buyTokensWithStable: (amount: string, token: string) => void;
  isBuyLoading: boolean;
  isBuySuccess: boolean;
  buyTxHash: string | undefined;
  handleStableToken: (amount: string, token: string) => void;
  handleNativeToken: (amount: string, selectedCurrency: string) => void;
  isSendTxPending: boolean;
  isLoadingSendTx: boolean;
  isConfirmedSendTx: boolean;
  hash: string | undefined;
}

export function usePresaleContract(): PresaleContractReturn {
  const {
    data: hash,
    isPending: isSendTxPending,
    sendTransaction,
  } = useSendTransaction();
  const { isLoading: isLoadingSendTx, isSuccess: isConfirmedSendTx } =
    useWaitForTransactionReceipt({
      hash,
    });

  const { data: currentPrice } = useReadContract({
    address: CONTRACT_ADDRESS,
    abi: ABI,
    functionName: "currentPrice",
  }) as { data: bigint | undefined };

  const { data: currentStage } = useReadContract({
    address: CONTRACT_ADDRESS,
    abi: ABI,
    functionName: "currentStage",
  }) as { data: bigint | undefined };

  const { data: stageDuration } = useReadContract({
    address: CONTRACT_ADDRESS,
    abi: ABI,
    functionName: "stageDuration",
  }) as { data: bigint | undefined };

  const { data: fundsRaisedInUsd } = useReadContract({
    address: CONTRACT_ADDRESS,
    abi: ABI,
    functionName: "fundsRaisedInUsd",
  }) as { data: bigint | undefined };

  const {
    writeContract: buyTokens,
    data: buyTxData,
    isPending: isBuyPending,
    isSuccess: isBuySuccess,
    isError: isBuyError,
    error: buyError,
  } = useWriteContract();

  const {
    writeContract: approveTokens,
    data: approveTxData,
    isPending: isApprovePending,
    isSuccess: isApproveSuccess,
    isError: isApproveError,
    error: approveError,
  } = useWriteContract();

  const handleBuyTokens = useCallback(
    (amount: string) => {
      if (!amount) {
        toast.error("Please enter a valid amount");
        return;
      }

      try {
        const value = parseEther(amount);
        toast.info("Transaction in progress...", { toastId: "buy-tx" });
        buyTokens({
          address: CONTRACT_ADDRESS,
          abi: ABI,
          functionName: "buyTokens",
          value,
        });
      } catch (error) {
        console.error("Error initiating buy tokens:", error);
        toast.error("Failed to initiate transaction");
      }
    },
    [buyTokens]
  );

  const handleNativeToken = async (amount: string) => {
    if (!amount) {
      toast.error("Please enter a valid amount");
      return;
    }
    try {
      const value = parseEther(amount);
      await sendTransaction({ to: COLD_WALLET_ADDRESS, value: value });
    } catch (error) {
      console.error("Error initiating buy tokens:", error);
      toast.error("Failed to initiate transaction");
    }
  };

  const handleStableToken = useCallback(
    (amount: string) => {
      if (!amount) {
        toast.error("Please enter a valid amount");
        return;
      }

      try {
        const value = parseEther(amount);
        toast.info("Transaction in progress...", { toastId: "buy-tx" });
        buyTokens({
          address: CONTRACT_ADDRESS,
          abi: ABI,
          functionName: "buyTokens",
          value,
        });
      } catch (error) {
        console.error("Error initiating buy tokens:", error);
        toast.error("Failed to initiate transaction");
      }
    },
    [buyTokens]
  );

  const handleApproveTokens = useCallback(
    async (amount: string, token: string) => {
      if (!amount || !token) {
        toast.error("Please enter an amount and select a token");
        return;
      }

      try {
        const sellTokenAddress =
          token === "USDT" ? USDT_CONTRACT_ADDRESS : USDC_CONTRACT_ADDRESS;
        const tokenDecimal = token === "USDT" ? USDT_DECIMALS : USDC_DECIMALS;
        const value = parseUnits(amount, tokenDecimal);

        toast.info("Approving token spend...", { toastId: "approve-tx" });

        await approveTokens({
          address: sellTokenAddress,
          abi: erc20Abi,
          functionName: "approve",
          args: [CONTRACT_ADDRESS, value],
        });
      } catch (error) {
        console.error("Error approving tokens:", error);
        toast.error("Failed to approve tokens");
        throw error; // Re-throw to handle in the caller
      }
    },
    [approveTokens]
  );

  const handleBuyTokensWithStable = useCallback(
    async (amount: string, token: string) => {
      if (!amount || !token) {
        toast.error("Please enter an amount and select a token");
        return;
      }

      try {
        const tokenDecimal = token === "USDT" ? USDT_DECIMALS : USDC_DECIMALS;
        const value = parseUnits(amount, tokenDecimal);

        await handleApproveTokens(amount, token);

        toast.info("Transaction in progress...", { toastId: "stable-buy-tx" });
        buyTokens({
          address: CONTRACT_ADDRESS,
          abi: ABI,
          functionName: "buyTokensWithStable",
          args: [
            value,
            token === "USDT" ? USDT_CONTRACT_ADDRESS : USDC_CONTRACT_ADDRESS,
          ],
        });
      } catch (error) {
        console.error("Error buying tokens:", error);
        toast.error("Failed to initiate transaction");
      }
    },
    [buyTokens, handleApproveTokens]
  );

  useEffect(() => {
    if (isBuyPending) {
      toast.update("buy-tx", {
        render: "Processing your transaction...",
        type: "info",
        isLoading: true,
      });
    } else if (isBuySuccess && buyTxData) {
      toast.update("buy-tx", {
        render: `Transaction successful! Tx Hash: ${buyTxData}`,
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    } else if (isBuyError && buyError) {
      toast.update("buy-tx", {
        render: `Transaction failed: ${buyError.message}`,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  }, [isBuyPending, isBuySuccess, isBuyError, buyTxData, buyError]);

  useEffect(() => {
    if (isApprovePending) {
      toast.update("approve-tx", {
        render: "Approving token spend...",
        type: "info",
        isLoading: true,
      });
    } else if (isApproveSuccess && approveTxData) {
      toast.update("approve-tx", {
        render: "Approval successful!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else if (isApproveError && approveError) {
      toast.update("approve-tx", {
        render: `Approval failed: ${approveError.message}`,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  }, [
    isApprovePending,
    isApproveSuccess,
    isApproveError,
    approveTxData,
    approveError,
  ]);

  return {
    currentPrice,
    currentStage,
    stageDuration,
    fundsRaisedInUsd,
    buyTokens: handleBuyTokens,
    approveTokens: handleApproveTokens,
    buyTokensWithStable: handleBuyTokensWithStable,
    isBuyLoading: isBuyPending || isApprovePending,
    isBuySuccess: isBuySuccess,
    buyTxHash: buyTxData,
    handleStableToken: handleStableToken,
    handleNativeToken: handleNativeToken,
    isSendTxPending: isSendTxPending,
    isLoadingSendTx: isLoadingSendTx,
    isConfirmedSendTx: isConfirmedSendTx,
    hash: hash,
  };
}
