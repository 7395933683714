import { SVGProps } from "react";

export const EthIcon = ({
  width = 18,
  height = 18,
  fill = "white", 
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18C4.02919 18 0 13.9708 0 9C0 4.02919 4.02919 0 9 0C13.9708 0 18 4.02919 18 9C18 13.9708 13.9708 18 9 18ZM13.4966 9.12319L9.28012 2.25L5.0625 9.12375L9.28012 11.5723L13.4966 9.12319ZM13.5 9.909L9.28012 12.3564L5.0625 9.90956L9.28012 15.7472L13.5 9.909Z"
      fill={fill} 
    />
    <path
      d="M9.2793 2.25V7.23938L13.4964 9.12375L9.2793 2.25ZM9.2793 12.357V15.7472L13.4992 9.909L9.2793 12.357Z"
      fill="black"
      fillOpacity="0.298"
    />
    <path
      d="M9.2793 11.572L13.4964 9.12348L9.2793 7.24023V11.572Z"
      fill="white"
    />
    <path
      d="M5.0625 9.12348L9.28012 11.572V7.24023L5.0625 9.12348Z"
      fill="black"
      fillOpacity="0.298"
    />
  </svg>
);