import { Modal, Navbar } from "components";
import styles from "./styles.module.css";
import { useCallback, useContext, useState } from "react";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { CustomButtonSection } from "components/custom-button-section";
import slogo from "../../assets-web3/image/s-logo-web3.png";
import Slideshow from "components/animation-forbes";
import { SocialCounter } from "../socialCounter";
import { InstagramIcon, TelegramIcon, TwitterXIcon } from "assets/svg";
import { ScrollContext } from "web3-landing-page";

export const Section1Web3 = () => {
  const [translate, setTranslate] = useState(0);

  const [activeSection, setActiveSection] = useState(0);
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const { scrollToSection } = useContext(ScrollContext);

  const navigateToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className={styles.container}>
      {!isDesktop && (
        <>
          <Navbar
            activeSection={activeSection}
            navigateToTop={navigateToTop}
            theme="white"
            fixed={false}
          />
          <Modal
            setTranslate={setTranslate}
            setActiveSection={setActiveSection}
          />
        </>
      )}
      <div className={styles.content}>
        <div className={styles.bgPromotion}>
          <div className={styles.textPomotion}>
            <CustomText
              size={isDesktop ? 18 : 16}
              text="50% Off – The Window is Closing Soon."
              color={theme.colors.neutral[0]}
              styles={{
                textAlign: "center",
                margin: "0 auto",
                lineHeight: "24px",
                fontWeight: 500,
              }}
            />
          </div>
        </div>

        <img src={slogo} alt="img" className={styles.logoS} />

        <CustomHeadline
          size={isDesktop ? 60 : 26}
          text="The First Sentient AI Framework Powered by Neurofeedback."
          color={theme.colors.neutral[0]}
          styles={{
            lineHeight: isDesktop ? "54" : "30px",
            textAlign: isDesktop ? "left" : "center",
            width: isDesktop ? "90%" : "",
          }}
        />
        <CustomText
          size={isDesktop ? 24 : 16}
          text="A new bridge between human cognition and AI—adaptive, responsive, and built for intelligence sovereignty."
          color={theme.colors.neutral[0]}
          styles={{
            textAlign: isDesktop ? "left" : "center",
            margin: isDesktop ? "0" : "0 auto",
            lineHeight: isDesktop ? "28" : "21px",
            fontWeight: 400,
            width: isDesktop ? "80%" : "",
          }}
        />
        <div className={styles.blockAnimation}>
          <Slideshow
            variant="white"
            style={{
              margin: isDesktop ? "0 auto" : "0",
            }}
            styleImg={{
              left: isDesktop ? "" : "50%",
              transform: isDesktop ? "" : "translateX(-50%)",
            }}
          />
        </div>

        <CustomButtonSection
          text="Buy $SCRB Now"
          colorScheme="green"
          onClick={() => scrollToSection("payment")}
          style={{
            color: theme.colors.neutral[0],
            marginTop: isDesktop ? "0" : "1.35dvw",
          }}
        />

        <div className={styles.blckSocial}>
          <SocialCounter
            minValue={33}
            maxValue={500}
            icon={<TelegramIcon color="#fff" heightFactor={20} width={24} />}
          />
          <SocialCounter
            minValue={9}
            maxValue={500}
            icon={<InstagramIcon color="#fff" heightFactor={20} width={24} />}
          />
          <SocialCounter
            minValue={13}
            maxValue={500}
            icon={<TwitterXIcon color="#fff" heightFactor={20} width={24} />}
          />
        </div>
      </div>
    </div>
  );
};
