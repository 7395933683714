import * as React from "react";

interface IconProps {
  width?: number | string; // Опциональный пропс для ширины
}

export const Icon3Section7: React.FC<IconProps> = ({ width = 44 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="37"
    fill="none"
    viewBox="0 0 44 37"
  >
    <g clipPath="url(#clip0_410_12603)">
      <path
        fill="#fff"
        d="M26.23 1.193H17.77c-9.347 0-16.923 7.611-16.923 17s7.576 16.999 16.923 16.999h8.461c9.347 0 16.923-7.611 16.923-17S35.577 1.194 26.23 1.194"
      ></path>
      <path
        stroke="#00CB94"
        d="M26.23 1.193H17.77c-9.347 0-16.923 7.611-16.923 17s7.576 16.999 16.923 16.999h8.461c9.347 0 16.923-7.611 16.923-17S35.577 1.194 26.23 1.194Z"
      ></path>
      <path
        fill="#00CB94"
        d="M16.253 25.842v-15.57h6.778c3.008 0 4.802 1.478 4.802 3.873v.022c0 1.64-1.214 3.075-2.804 3.313v.194c2.052.162 3.545 1.705 3.545 3.7v.023c0 2.73-2.052 4.445-5.36 4.445zm5.951-13.153h-2.707v4.057h2.439c1.75 0 2.706-.734 2.706-2.018v-.022c0-1.262-.891-2.017-2.438-2.017m.108 6.204h-2.815v4.521h2.89c1.869 0 2.879-.798 2.879-2.277v-.021c0-1.446-1.042-2.223-2.954-2.223"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_410_12603">
        <path fill="#fff" d="M0 .343h44v35.698H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
