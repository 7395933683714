import React, { FC } from "react";
import styles from "./styles.module.css";

import black1 from "assets/images/forbes-animetion-img/black/black1.png";
import black2 from "assets/images/forbes-animetion-img/black/black2.png";
import black3 from "assets/images/forbes-animetion-img/black/black3.png";
import black4 from "assets/images/forbes-animetion-img/black/black4.png";
import black5 from "assets/images/forbes-animetion-img/black/black5.png";
import black6 from "assets/images/forbes-animetion-img/black/black6.png";
import black7 from "assets/images/forbes-animetion-img/black/black7.png";
import black8 from "assets/images/forbes-animetion-img/black/black8.png";
import black9 from "assets/images/forbes-animetion-img/black/black9.png";

import white1 from "assets/images/forbes-animetion-img/white/white1.png";
import white2 from "assets/images/forbes-animetion-img/white/white2.png";
import white3 from "assets/images/forbes-animetion-img/white/white3.png";
import white4 from "assets/images/forbes-animetion-img/white/white4.png";
import white5 from "assets/images/forbes-animetion-img/white/white5.png";
import white6 from "assets/images/forbes-animetion-img/white/white6.png";
import white7 from "assets/images/forbes-animetion-img/white/white7.png";
import white8 from "assets/images/forbes-animetion-img/white/white8.png";
import white9 from "assets/images/forbes-animetion-img/white/white9.png";

import whiteShort1 from "../../web3-landing-page/assets-web3/image/whiteShort1.png";
import whiteShort2 from "../../web3-landing-page/assets-web3/image/whiteShort2.png";
import whiteShort3 from "../../web3-landing-page/assets-web3/image/whiteShort3.png";
import whiteShort4 from "../../web3-landing-page/assets-web3/image/whiteShort4.png";
import whiteShort5 from "../../web3-landing-page/assets-web3/image/whiteShort5.png";

import panda from "assets/images/forbes-animetion-img/white/panda.png";
import aethir from "assets/images/forbes-animetion-img/white/aethir.png";

interface SlideshowProps {
  variant: "black" | "white" | "white5" | "white2";
  style?: React.CSSProperties;
  styleImg?: React.CSSProperties;
}

export const Slideshow: FC<SlideshowProps> = ({ variant, style, styleImg }) => {
  const blackImages = [
    black1,
    black2,
    black3,
    black4,
    black5,
    black6,
    black7,
    black8,
    black9,
  ];
  const whiteImages = [
    white1,
    white2,
    white3,
    white4,
    white5,
    white6,
    white7,
    white8,
    white9,
  ];
  const whiteShortImages = [
    whiteShort1,
    whiteShort2,
    whiteShort3,
    whiteShort4,
    whiteShort5,
  ];

  const white2Images = [panda, aethir];

  let images: string[] = [];
  let containerClass = "";

  if (variant === "black") {
    images = blackImages;
    containerClass = styles.slideshowContainer9;
  } else if (variant === "white") {
    images = whiteImages;
    containerClass = styles.slideshowContainer9;
  } else if (variant === "white5") {
    images = whiteShortImages;
    containerClass = styles.slideshowContainer5;
  } else {
    images = white2Images;
    containerClass = styles.slideshowContainer2;
  }
  return (
    <div className={containerClass} style={style}>
      {images.map((img, index) => (
        <img
          key={index}
          src={img}
          alt={`Слайд ${index + 1}`}
          style={styleImg}
        />
      ))}
    </div>
  );
};

export default Slideshow;
