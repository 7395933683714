// DetailPage.js
import React, { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import buttonIcon from "assets/images/Listening Button.png";
import { CustomButtonSection } from "components/custom-button-section";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import {
  icon17,
  icon18,
  icon19,
  icon20,
  icon21,
  icon22,
  icon23,
  icon25,
  icon24,
} from "../imagas";
import close from "assets/images/close-icon.png";
import { Modal } from "components/modal";
import { CloseBlock } from "components/closemomileDetail";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage8: React.FC<DetailPageProps> = ({ handleClose }) => {
  const [translate, setTranslate] = useState(0);
  const [activeSection, setActiveSection] = useState<number>(0);
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const navigate = useNavigate();
  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section4)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);
  const handClickSection7 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/sentient-ai-framework");
  };
  const handClickSection9 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/ai-powered");
  };

  return (
    <div className={styles.section8}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        theme="black"
        logoVariant="noLogoText"
        blur
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        <img
          src={close}
          alt="img"
          className={styles.button}
          onClick={() => handleClose(NavId.Section8, 8)}
        />
        <div className={styles.content}>
          <div className={styles.block1}>
            {!isDesktop && (
              <CloseBlock
                id={NavId.Section8}
                index={8}
                handleClose={handleClose}
              />
            )}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Voices That Resonate, Stories That Stay"
                color={theme.colors.neutral[20]}
              />
              <div className={styles.p}>
                <img src={buttonIcon} alt="buttonIcon" />
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text="Tap to hear this page come to life."
                  color={theme.colors.neutral[30]}
                  styles={{ fontStyle: "italic", lineHeight: "28px" }}
                />
              </div>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="The voice of a mentor, a loved one, a guide—it’s more than sound. It’s memory, trust, and emotion woven into every word. MindScriber’s voice cloning technology transforms learning by bringing familiarity into the experience. Whether it’s the reassuring tone of a parent, the wisdom of a teacher, or the inspiration of a public figure, voices shape the way we absorb and retain knowledge.."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="The science behind memory suggests that we remember more when we feel connected. That’s why the voices we trust—those familiar cadences that archive meaning—become anchors for knowledge. MindScriber’s Language Neural Network (LLN) builds on this foundation, leveraging advanced speech synthesis and neural modeling to clone voices with unparalleled accuracy. Every subtle inflection, rhythm, and tone is preserved, ensuring that speech patterns remain true to their source."
                color={theme.colors.neutral[30]}
              />
            </div>
            <div className={styles.centuries}>
              <img className={styles.icon} src={icon24} alt="icon" />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="“People don’t just listen to words. They listen to emotion.”"
                color={theme.colors.neutral[30]}
                styles={{
                  fontStyle: "italic",
                  textAlign: "center",
                  margin: "0 auto",
                  width: isDesktop ? undefined : "60%",
                  fontWeight: "300",
                }}
              />
            </div>
            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="LLN doesn’t just replicate sound; it understands and adapts. By analyzing the cognitive response to different voices, MindScriber ensures that learning experiences are personalized for maximum engagement and retention. This isn’t just about generating synthetic speech—it’s about creating an interactive, human-like auditory experience that enhances understanding and recall."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
          <div className={styles.line}></div>
          <div className={styles.block2}>
            <div className={styles.centuries}>
              <img className={styles.icon} src={icon17} alt="icon" />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="“The voice you trust is the voice you remember.”"
                color={theme.colors.neutral[30]}
                styles={{
                  fontStyle: "italic",
                  textAlign: "center",
                  margin: "0 auto",
                  width: isDesktop ? undefined : "60%",
                  fontWeight: "300",
                }}
              />
            </div>

            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Beyond personalization, this technology is about accessibility. The barriers of monotone AI-generated speech are gone. Learning can now be dynamic, immersive, and entirely tailored to the individual. Whether refining skills, absorbing new languages, or unlocking deeper understanding, MindScriber ensures that every interaction feels natural, engaging, and powerful."
                color={theme.colors.neutral[30]}
              />
            </div>

            <div className={styles.centuries}>
              <img className={styles.icon} src={icon25} alt="icon" />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="“The future of learning doesn’t sound robotic. It sounds human.”"
                color={theme.colors.neutral[30]}
                styles={{
                  fontStyle: "italic",
                  textAlign: "center",
                  margin: "0 auto",
                  width: isDesktop ? "100%" : "60%",
                  fontWeight: "300",
                }}
              />
            </div>

            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="With an expanding library of voices, from cultural icons to personal recordings, users can curate their own learning environment, selecting voices that elevate their understanding. More than information, this is a revolution in emotional intelligence—a way to learn that feels alive, connected, and uniquely yours."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber doesn’t just enhance learning. It changes the way we listen, understand, and remember."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
        </div>
        <CustomText
          size={isDesktop ? 20 : 15}
          text="“The human voice is the organ of the soul.” — Henry Wadsworth Longfellow"
          color={theme.colors.neutral[30]}
          styles={{
            fontStyle: "italic",
            textAlign: !isDesktop ? "center" : undefined,
            margin: "0 auto",
            width: isDesktop ? undefined : "80%",
          }}
        />
        <div className={styles.blockButtons}>
          <CustomButtonSection
            text="Back"
            colorScheme="green"
            onClick={handClickSection7}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
          <CustomButtonSection
            text="Forward"
            colorScheme="green"
            onClick={handClickSection9}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
        </div>
      </div>
      <div className={styles.fon}>
        <div className={styles.containerFonIcon}>
          <img src={icon18} alt="icon" />
          <img src={icon19} alt="icon" />
          <img src={icon20} alt="icon" />
          <img src={icon21} alt="icon" />
          <img src={icon22} alt="icon" />
          <img src={icon23} alt="icon" />
        </div>
      </div>
    </div>
  );
};
