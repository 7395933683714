import {
  Challenge,
  GetInTouch,
  JourneyAhead,
  Layout,
  OurMissions,
  OurStory,
  ScriberWearable,
  SectionContainer,
  Team,
  TechFramework,
  Tokenomiks,
  WeOffer,
} from "components";
import { Section10 } from "components/sections/section-10";
import { Section11 } from "components/sections/section-11";
import { Section12 } from "components/sections/section-12";
import { Section13Mobile } from "components/sections/section-12/section-12-13-mobile";
import { useDeviceType } from "hooks";
import { useLocation } from "react-router-dom";
import { NavId } from "types/navigation";

export const HomePage = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const { state } = useLocation();

  return (
    <Layout
      activeSectionNumber={state?.activeSectionNumber}
      activeId={state?.activeId}
    >
      <SectionContainer id={NavId.Section1}>
        <OurMissions />
      </SectionContainer>

      <SectionContainer
        id={NavId.Section2}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <OurStory />
      </SectionContainer>

      <SectionContainer id={NavId.Section3}>
        <Challenge />
      </SectionContainer>

      <SectionContainer id={NavId.Section4}>
        <ScriberWearable />
      </SectionContainer>

      <SectionContainer id={NavId.Section5}>
        <TechFramework />
      </SectionContainer>

      <SectionContainer id={NavId.Section6}>
        <Tokenomiks />
      </SectionContainer>

      <SectionContainer id={NavId.Section14}>
        <WeOffer />
      </SectionContainer>

      <SectionContainer id={NavId.Section7}>
        <JourneyAhead />
      </SectionContainer>

      <SectionContainer
        id={NavId.Section8}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Team />
      </SectionContainer>

      <SectionContainer
        id={NavId.Section9}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <GetInTouch />
      </SectionContainer>

      <SectionContainer id={NavId.Section10}>
        <Section10 />
      </SectionContainer>

      <SectionContainer id={NavId.Section11}>
        <Section11 />
      </SectionContainer>

      {isDesktop ? (
        <SectionContainer id={NavId.Section12}>
          <Section12 />
        </SectionContainer>
      ) : (
        <SectionContainer id={NavId.Section14}>
          <Section12 />
        </SectionContainer>
      )}

      {!isDesktop && (
        <SectionContainer id={NavId.Section12}>
          <Section13Mobile />
        </SectionContainer>
      )}
    </Layout>
  );
};
