import React from "react";
import { useDeviceType } from "hooks";

interface IconProps extends React.SVGProps<SVGSVGElement> {}

export const IconCustom3: React.FC<IconProps> = (props) => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  return (
    <svg
      width="59"
      height="60"
      viewBox="0 0 59 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props} // передаёт любые дополнительные пропсы внутрь <svg>
    >
      <path d="M13.5208 49.6668L8.34843 38.4605C7.73482 37.1304 7.61645 35.6249 8.01467 34.2152C8.41289 32.8056 9.3015 31.5845 10.5204 30.7721L12.8607 29.2111C13.0575 29.0798 13.2881 29.0081 13.5246 29.0046C13.7612 29.0011 13.9937 29.066 14.1944 29.1914L14.7499 29.5392L12.4514 12.7242C12.3493 11.9806 12.5445 11.2267 12.9947 10.6261C13.1445 10.4262 13.3321 10.2578 13.547 10.1305C13.7619 10.0032 13.9997 9.91942 14.2469 9.88403C14.4942 9.84865 14.746 9.86232 14.9879 9.92425C15.2299 9.98619 15.4572 10.0952 15.6571 10.245L15.8205 10.3679C16.3245 10.7465 16.7043 11.2652 16.9096 11.8614L21.9639 26.4626C22.0185 26.6207 22.1286 26.7536 22.2738 26.8366C22.419 26.9197 22.5893 26.9472 22.7533 26.9142C22.9172 26.8812 23.0636 26.7898 23.1653 26.657C23.267 26.5243 23.317 26.3591 23.3062 26.1922L22.2208 9.37107C22.1909 8.90084 22.2615 8.42965 22.4279 7.98882C22.5943 7.54799 22.8526 7.14764 23.1857 6.81441C23.5701 6.43022 24.0827 6.20126 24.6254 6.17132C25.1681 6.14139 25.7027 6.31258 26.1271 6.65216L26.545 6.98649C27.2599 7.55816 27.7365 8.37526 27.8824 9.27889L30.6345 26.3397C30.6606 26.5082 30.7474 26.6613 30.8786 26.7701C31.0098 26.8789 31.1763 26.936 31.3467 26.9306C31.5171 26.9251 31.6796 26.8575 31.8035 26.7406C31.9275 26.6236 32.0044 26.4652 32.0197 26.2955L33.6521 9.42639C33.6995 8.93554 33.8449 8.4592 34.0797 8.02555C34.3145 7.59189 34.6338 7.20973 35.0189 6.90168L35.2721 6.69886C35.7127 6.34645 36.2678 6.1689 36.8311 6.20019C37.3945 6.23149 37.9265 6.46944 38.3254 6.86849C38.9707 7.51257 39.3333 8.38774 39.3333 9.29978V24.9729C39.3339 25.0451 39.3409 25.117 39.3542 25.188C39.4918 25.8198 40.3252 29.1004 43.0208 29.9989C44.1356 30.3702 45.9265 33.4406 47.1667 35.8092C48.0763 37.5473 47.9891 39.6159 47.0365 41.3306L42.4062 49.6668C42.4062 49.6668 38.1041 54.5835 28.2708 54.5835C18.4374 54.5835 14.3406 51.3053 13.5208 49.6668Z" stroke={isDesktop ? "black" : "#00CB94"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

