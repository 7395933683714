import { CustomHeadline, CustomText } from "components/ui";
import styles from "./styles.module.css";
import { theme } from "../../../constants/theme";
import { useDeviceType } from "hooks";
import { CustomButtonSection } from "components/custom-button-section";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AudioOn from "assets/svg/audio-on";
import AudioOff from "assets/svg/audio-off";

export const JourneyAhead = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  const [isAudioOn, setIsAudioOn] = useState(true);

  const toggleAudio = () => {
    setIsAudioOn((prev) => !prev);
  };

  const navigate = useNavigate();
  const handleExploreClick = () => {
    navigate("/detail/sentient-ai-framework");
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <CustomHeadline
          size={isDesktop ? 55 : 24}
          text="Smarter Tools. Effortless Learning."
          color={theme.colors.neutral[0]}
          styles={{ lineHeight: `${isDesktop ? 52 : 26}px` }}
        />
        <div className={styles.p}>
          <CustomText
            size={isDesktop ? 24 : 14}
            text={
              isDesktop
                ? "From Dyslexia-Friendly UI to Personalized Voices and Subconscious Optimization—MindScriber tools transform how we process knowledge."
                : "From Dyslexia-Friendly UI to Personalized Voices and Subconscious Retention—MindScriber tools redefine how we learn."
            }
            color={theme.colors.neutral[0]}
            styles={{
              lineHeight: `${isDesktop ? 28 : 17}px`,
            }}
          />
        </div>
        <CustomButtonSection
          text="Explore Tools"
          colorScheme="green"
          onClick={handleExploreClick}
          style={{
            marginTop: isDesktop ? "2%" : "4%",
          }}
        />
      </div>
      {/* <div className={styles.imgAudio} onClick={toggleAudio}>
        {isAudioOn ? <AudioOn /> : <AudioOff />}
      </div> */}
    </div>
  );
};
