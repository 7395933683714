import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModalState {
  isOpen: boolean;
  buyToken: boolean;
  modalType: "" | "burger" | "default" | "linkSocial";
}

const initialState: ModalState = {
  isOpen: false,
  buyToken: false,
  modalType: "",
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
      state.modalType = "default";
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.modalType = "";
    },
    toggleModal: (
      state,
      action: PayloadAction<"burger" | "default" | "linkSocial" | undefined>
    ) => {
      if (!state.isOpen) {
        state.isOpen = true;
        state.modalType = action.payload ?? "default";
      } else {
        state.isOpen = false;
        state.modalType = "";
      }
    },
    toggleBuyToken: (state) => {
      state.buyToken = !state.buyToken;
    },
  },
});

export const { openModal, closeModal, toggleModal, toggleBuyToken } =
  modalSlice.actions;

export default modalSlice.reducer;
