// DetailPage.js
import React, { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import buttonIcon from "assets/images/Listening Button.png";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/fon-section4-info.png";
import fon2 from "assets/images/fon-secrion4-info-mobile.png";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import close from "assets/images/close-icon.png";
import { Modal } from "components/modal";
import { CloseBlock } from "components/closemomileDetail";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage4: React.FC<DetailPageProps> = ({ handleClose }) => {
  const [translate, setTranslate] = useState(0);

  const [activeSection, setActiveSection] = useState<number>(0);

  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  const navigate = useNavigate();

  const handClickSection3 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/scriber wearable");
  };
  const handClickSection5 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/ai-framework");
  };

  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section4)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);

  return (
    <div className={styles.section4}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        theme="black"
        logoVariant="noLogoText"
        blur
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        {isDesktop && (
          <img
            src={close}
            alt="img"
            className={styles.button}
            onClick={() => handleClose(NavId.Section5, 4)}
          />
        )}

        <div className={styles.content}>
          <div className={styles.block1}>
            {!isDesktop && (
              <CloseBlock
                id={NavId.Section5}
                index={4}
                handleClose={handleClose}
              />
            )}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Understanding AI Agents and Intelligence"
                color={theme.colors.neutral[20]}
              />

              <div className={styles.p}>
                <img src={buttonIcon} alt="buttonIcon" />
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text="Tap to hear this page come to life."
                  color={theme.colors.neutral[30]}
                  styles={{ fontStyle: "italic", lineHeight: "28px" }}
                />
              </div>

              <CustomText
                size={isDesktop ? 20 : 15}
                text="For centuries, intelligence was considered a uniquely human trait—the ability to learn, adapt, and shape the trajectory of civilization. But AI agents have evolved beyond static tools, becoming dynamic partners in cognition, growth, and discovery. The world wasn’t built for those who think differently, yet intelligence has always been about adaptation, not conformity."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="A young boy sits in a classroom, staring at a math problem. He knows the answer, but the words won’t come. The numbers blur, his thoughts move differently—unseen, unrecognized. The system wasn’t designed for him."
                color={theme.colors.neutral[30]}
              />
            </div>
            <CustomText
              size={isDesktop ? 20 : 15}
              text="“The world was never built for those who think differently. But those who change it always are.”"
              color={theme.colors.neutral[30]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
                width: isDesktop ? "95%" : "100%",
                fontWeight: "300",
              }}
            />
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Beyond Automation: Intelligence That Grows"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Intelligence isn’t about repeating patterns—it’s about predicting them. The human brain doesn’t learn through static repetition; it thrives on adaptation, relationships, and immersion. AI agents evolve the same way, analyzing vast networks of variables to recognize patterns, solve unseen challenges, and unlock insights beyond human capability."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="A researcher spends years searching for a breakthrough in Alzheimer’s treatment, testing theories, tracing genetic pathways—until her AI assistant recognizes an unseen correlation. In that moment, a decade’s worth of work takes a leap forward."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="This is the power of adaptive intelligence."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>

          <div className={styles.line}></div>

          <div className={styles.block2}>
            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="In medicine, business, and learning, AI agents don’t just process data—they recognize relationships, interpret complexity, and refine strategies faster than any human brain alone."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="“The greatest breakthroughs don’t come from more data. They come from seeing what others miss.”"
                color={theme.colors.neutral[30]}
                styles={{
                  fontStyle: "italic",
                  textAlign: "center",
                  margin: "0 auto",
                  width: isDesktop ? "95%" : "100%",
                  fontWeight: "300",
                }}
              />
            </div>
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="A New Era of Learning, Work, and Innovation"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="For the first time, technology doesn’t just deliver information. It understands the learner. A financial analyst stares at his screen, lost in spreadsheets—told his whole life that he’s “not good with numbers.” But his AI assistant notices a pattern, breaking it down in a way that sparks clarity. His intuition, his grasp with data, had eluded him for years—not because he lacked intelligence, but because intelligence isn’t one-size-fits-all."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="From AI mentors that enhance productivity to cognitive agents that guide learning-as-intelligence, it’s not about replacing humans. It’s about amplifying what it means to be human."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="The future isn’t about algorithms that just present information. It’s about systems that support, guide, and deliver intelligence in a way that feels intuitive, seamless, and humane."
                color={theme.colors.neutral[30]}
              />
            </div>
            <CustomText
              size={isDesktop ? 20 : 15}
              text="“Machines don’t replace intelligence. They redefine what’s possible.”"
              color={theme.colors.neutral[30]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
                width: isDesktop ? "95%" : "80%",
                fontWeight: "300",
              }}
            />
            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="At their core, AI agents function through a dynamic process. They analyze data from text, voice, images, and human interactions. This perception fuels reasoning, allowing them to recognize intent, form contextual connections, and anticipate what comes next."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
        </div>
        <CustomText
          size={isDesktop ? 20 : 15}
          text="“The measure of intelligence is the ability to change.” — Albert Einstein"
          color={theme.colors.neutral[30]}
          styles={{
            fontStyle: "italic",
            textAlign: !isDesktop ? "center" : undefined,
            margin: "0 auto",
            width: isDesktop ? undefined : "80%",
          }}
        />
        <div className={styles.blockButtons}>
          <CustomButtonSection
            text="Back"
            colorScheme="green"
            onClick={handClickSection3}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
          <CustomButtonSection
            text="Forward"
            colorScheme="green"
            onClick={handClickSection5}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
        </div>
      </div>
      <img className={styles.fon} src={isDesktop ? fon : fon2} alt="fon" />
    </div>
  );
};
