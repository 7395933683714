import type React from "react";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DetailPage } from "components/sections/section-1/detailPage/DetailPage";
import { DetailPage2 } from "components/sections/section-2/detailPage/DetailPage";
import { DetailPage3 } from "components/sections/section-3/detailPage/DetailPage";
import { DetailPage4 } from "components/sections/section-4/detailPage/DetailPage";
import { DetailPage5 } from "components/sections/section-5/detailPage/DetailPage";
import { DetailPage7 } from "components/sections/section-7/detailPage/DetailPage";
import { DetailPage8 } from "components/sections/section-8/detailPage/DetailPage";
import { DetailPage10 } from "components/sections/section-10/detailPage/DetailPage";
import { DetailPage11 } from "components/sections/section-11/detailPage/DetailPage";
import { DetailPage12 } from "components/sections/section-12/detailPage/DetailPage";
import { closeModal } from "store";
import { useDispatch } from "react-redux";
import { DetailPage9 } from "components/sections/section-9/detailPage/DetailPage";
import { DetailPage35 } from "components/sections/section-3.5/detailPage/DetailPage";
import { useDeviceType } from "hooks";
import { DetailPage1Web3 } from "web3-landing-page/components/section2-web-3/detailPage/DetailPage";
import { WebThreeScrollableContainer } from "web3-landing-page/components/scrolling";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

const detailPageMap: Record<string, React.FC<DetailPageProps>> = {
  "sentient-framework": DetailPage,
  "neurodivergent-learning": DetailPage2,
  "adaptive-intelligence": DetailPage4,
  "early-diagnosis": DetailPage3,
  "scriber wearable": DetailPage35,
  "ai-framework": DetailPage5,
  "sentient-ai-framework": DetailPage7,
  "voice-cloning-science": DetailPage8,
  "ai-powered": DetailPage9,
  "scaling-intelligence": DetailPage11,
  "token-economy": DetailPage10,
  team: DetailPage12,
  "trouble-connecting": DetailPage1Web3,
};

const DetailPageContainer = () => {
  const { detailId } = useParams<{ detailId?: string }>();
  const [translate, setTranslate] = useState(0);
  const [activeSection, setActiveSection] = useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { device } = useDeviceType();
  const isWeb3 = window.location.hostname.startsWith("web3");

  const handleClose = useCallback(
    (sectionId: string, sectionNumber: number) => {
      dispatch(closeModal());

      setActiveSection(sectionNumber);

      if (device === "mobile") {
        setTranslate(sectionNumber * window.innerHeight);
      }

      navigate("/", {
        replace: true,
        state: { activeId: sectionId, activeSectionNumber: sectionNumber },
      });

      setTimeout(() => {
        if (device === "desktop") {
          document
            .getElementById(sectionId)
            ?.scrollIntoView({ behavior: "instant" });
        } else {
          setTranslate(sectionNumber * window.innerHeight);

          const container = document.querySelector(".scroll-container");
          if (container) {
            container.scrollTop = sectionNumber * window.innerHeight;
          }
        }
      }, 250);
    },
    [navigate, setTranslate, setActiveSection, dispatch, device]
  );

  if (isWeb3 && detailId !== "trouble-connecting" && detailId !== "team") {
    return <h1>Page Not Found</h1>;
  }

  if (!detailId || !detailPageMap[detailId]) {
    return <h1>Page Not Found</h1>;
  }

  const PageComponent = detailPageMap[detailId];

  return (
    <WebThreeScrollableContainer>
      <PageComponent handleClose={handleClose} />
    </WebThreeScrollableContainer>
  );
};

export default DetailPageContainer;
