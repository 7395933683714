import { useLocation } from "react-router-dom";
import { Section1Web3 } from "./components/section1-web-3";
import { Section2Web3 } from "./components/section2-web-3";
import { Section4Web3 } from "./components/section4-web3";
import { Section3Web3 } from "./components/section3-web-3";
import { Section5Web3 } from "./components/section5-web-3";
import { Section6Web3 } from "./components/section6-web3";
import { Section7Web3 } from "./components/section7-web-3";
import { Section8Web3 } from "./components/section8-web-3";
import { Section9Web3 } from "./components/section9-web-3";
import { Section10Web3 } from "./components/section10-web3";
import { Section11Web3 } from "./components/section11-web-3";
import { Section12Web3 } from "./components/section12-web3/indev";
import { Section13Web3 } from "./components/section13-web-3";
import { Section14Web3 } from "./components/section14-web3";
import { Section15Web3 } from "./components/section15-web-3";
import { MobileSection9 } from "./components/MobileSection9";
import { Section12 } from "components/sections/section-12";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContainerWeb3 } from "./containerWeb3";
import { useDeviceType } from "hooks";
import { createContext } from "react";

export const ScrollContext = createContext<{
  scrollToSection: (sectionId: string) => void;
}>({
  scrollToSection: () => {},
});

export const WebThree = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  const [activeSection, setActiveSection] = useState<number>(0);
  const [translate, setTranslate] = useState(0);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.getElementById(location.hash.substring(1));
      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
      }
    }
  }, [location]);

  const scrollToSection = (sectionId: string) => {
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <ScrollContext.Provider value={{ scrollToSection }}>
      <ContainerWeb3>
        {!isDesktop && <Section1Web3 />}

        <div id="payment">
          <Section2Web3 />
        </div>

        <Section3Web3 />

        {!isDesktop && <Section4Web3 />}

        {isDesktop && <Section10Web3 />}

        {!isDesktop && <Section5Web3 />}

        {!isDesktop && <Section6Web3 />}

        <Section7Web3 />

        <Section8Web3 />

        {!isDesktop && <Section9Web3 />}

        {isDesktop && <Section11Web3 />}

        {isDesktop && <Section9Web3 />}

        {isDesktop && <Section4Web3 />}

        {isDesktop && <MobileSection9 />}

        {!isDesktop && <Section10Web3 />}

        {!isDesktop && <Section11Web3 />}

        {!isDesktop && <Section12Web3 />}

        {!isDesktop && <Section13Web3 />}

        {!isDesktop && <Section14Web3 />}

        {!isDesktop && <Section15Web3 />}

        {isDesktop && <Section12 />}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ContainerWeb3>
    </ScrollContext.Provider>
  );
};
