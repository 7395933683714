import React from "react";
import styles from "./styles.module.css";
import closeMobile from "assets/images/material-symbols-light_arrow-back.png";
import { CustomText } from "components/ui";
import { theme } from "constants/theme";

interface CloseBlockProps {
  id: string;
  index: number;
  handleClose: (id: string, index: number) => void;
  text?: string;
  textColor?: string;
  textStyles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
  imgSrc?: string;
}

export const CloseBlock: React.FC<CloseBlockProps> = ({
  id,
  index,
  handleClose,
  text = "Back to main page",
  textColor = theme.colors.neutral[30],
  textStyles,
  containerStyles,
  imgSrc,
}) => {
  return (
    <div
      className={styles.blockClose}
      style={containerStyles}
      onClick={() => handleClose(id, index)}
    >
      <img src={imgSrc ? imgSrc : closeMobile} alt="img" />
      <CustomText
        size={14}
        text={text}
        color={textColor}
        styles={{
          fontStyle: "italic",
          textAlign: "center",
          margin: "0 auto",
          lineHeight: "28px",
          ...textStyles,
        }}
      />
    </div>
  );
};
