import { analytics, logEvent as firebaseLogEvent } from "config/firebase";

/**
 * Safely log an event to Firebase Analytics
 * @param eventName The name of the event to log
 * @param eventParams The parameters to include with the event
 */
export const logEvent = (
  eventName: string,
  eventParams: Record<string, any> = {}
) => {
  if (analytics) {
    firebaseLogEvent(analytics, eventName, eventParams);
  }
};
