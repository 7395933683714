import React, { useState, useEffect } from "react";

interface BurgerMenuIconProps {
  heightFactor?: number; // Коэффициент масштабирования
  color?: string;
}

export const BurgerMenuIcon: React.FC<BurgerMenuIconProps> = ({
  heightFactor = 20, // Можно подстраивать коэффициент
  color = "#000",
}) => {
  const [size, setSize] = useState(40); // Начальный размер

  useEffect(() => {
    const updateSize = () => {
      const newSize = Math.min(
        40, // Максимальный размер
        Math.max(24, window.innerHeight / heightFactor) // Минимальный размер
      );
      setSize(newSize);
    };

    updateSize(); // Вызываем при монтировании
    window.addEventListener("resize", updateSize); // Слушаем изменения экрана
    return () => window.removeEventListener("resize", updateSize); // Убираем слушатель
  }, [heightFactor]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size * (32 / 40)}px`} // Пропорциональная высота
      viewBox="0 0 40 32"
      fill="none"
      style={{ display: "block" }}
    >
      <path
        fill={color}
        d="M0 3.5h40v5H0zM0 13.5h40v5H0zM0 23.5h40v5H0z"
      ></path>
    </svg>
  );
};
