import { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import buttonIcon from "assets/images/Listening Button.png";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/fon-dection-2-info.png";
import fon2 from "assets/images/fon2-section2-info.png";
import logoForbes from "assets/images/logo-forbes.png";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import close from "assets/images/close-icon.png";
import { Modal } from "components/modal";
import closeMobile from "assets/images/material-symbols-light_arrow-back.png";
import { CloseBlock } from "components/closemomileDetail";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage2: React.FC<DetailPageProps> = ({ handleClose }) => {
  const [translate, setTranslate] = useState(0);

  const [activeSection, setActiveSection] = useState<number>(0);

  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  const navigate = useNavigate();

  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section1)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);

  const handClickSection3 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/early-diagnosis");
  };
  const handClickSection1 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/sentient-framework");
  };

  return (
    <div className={styles.section2}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        theme="black"
        logoVariant="noLogoText"
        blur
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        {isDesktop && (
          <img
            src={close}
            alt="img"
            className={styles.button}
            onClick={() => handleClose(NavId.Section2, 1)}
          />
        )}

        <div className={styles.content}>
          <div className={styles.block1}>
            {!isDesktop && (
              <CloseBlock
                id={NavId.Section2}
                index={1}
                handleClose={handleClose}
              />
            )}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Reinventing Learning for 1,6B+ Neurodivergent Minds"
                color={theme.colors.neutral[20]}
              />

              <div className={styles.p}>
                <img src={buttonIcon} alt="buttonIcon" />
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text="Tap to hear this page come to life."
                  color={theme.colors.neutral[30]}
                  styles={{ fontStyle: "italic", lineHeight: "28px" }}
                />
              </div>

              <CustomText
                size={isDesktop ? 20 : 15}
                text="I never fit the mold. Not in school, not in the rigid structure of traditional learning, and certainly not in a world that saw dyslexia as a disadvantage. From the age of six, I was already questioning everything—why some kids struggled while others thrived, why the system demanded that we all learn the same way, and why no one was asking the most fundamental question: what if our minds weren’t built to function in a single, linear direction?"
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="I was born into a family of intellectuals. My father, an IT professor and thermophysicist, immersed me in the world of technology from an early age. Systems thinking, problem-solving, and innovation were ingrained in me long before I could articulate them. But my real education didn’t come from textbooks—it came from studying people, understanding how they think, how they feel, and why they struggle."
                color={theme.colors.neutral[30]}
              />
            </div>
            <CustomText
              size={isDesktop ? 20 : 15}
              text="“What if learning worked differently?”"
              color={theme.colors.neutral[30]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
              }}
            />
            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="By the time I was eight, I had made a decision. I was going to dedicate my life to understanding the human mind—not just at a theoretical level, but at a deeply personal one. I wanted to unlock the way people learn, think, and retain knowledge in a way that was individualized to them."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="This obsession led me to develop my own system of learning. Martial arts became my first laboratory, a discipline that taught me how structure, adaptability, and resilience work together to shape intelligence. By my teenage years, I had already built a framework that took me from an undiagnosed neurodivergent student to a world karate champion."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="At 17, I moved to New York alone. I spoke only a few words of English, had no financial support, and no safety net. "
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Everything I built, I built from the ground up—not by following conventional paths, but by refining knowledge through direct experience. "
                color={theme.colors.neutral[30]}
              />
            </div>
            <CustomText
              size={isDesktop ? 20 : 15}
              text="“Your mind isn’t the problem—the system is.”"
              color={theme.colors.neutral[30]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
              }}
            />

            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="My journey took an unexpected turn when President George W. Bush personally assisted in securing my U.S. passport, ensuring that I could continue my mission. My approach to learning had been redefined—not through the traditional system, but by proving that when you push forward with discipline and adaptability, the world opens in ways you never expect."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="I never touched alcohol, steroids, or stimulants."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
          <div className={styles.line}></div>
          <div className={styles.block2}>
            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="I never relied on medication to push through limits. Instead, I trained my body and mind relentlessly, uncovering the science of cognitive endurance, neuroplasticity, and the connection between movement, memory, and focus."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="That same mindset shaped my work with children. When I founded Karate City in Columbus Circle, it wasn’t just about martial arts—it was about rewiring how young minds learn, particularly those with autism, ADHD, and dyslexia. "
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="We weren’t just teaching kids how to fight—we were helping them unlock cognitive and emotional potential in a way traditional education never could. High-net-worth families, Hollywood elites, politicians, and even royal households entrusted their children to my methods because they saw something fundamentally different: a system that actually worked."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="But it wasn’t enough."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="I realized the problem wasn’t about helping a select few—it was about reinventing learning itself"
                color={theme.colors.neutral[30]}
              />
            </div>

            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="From Technology to Learning Reinvented"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber is not just another learning platform. It is the first sentient AI framework designed to adapt to the way the brain actually learns—not the way outdated systems force it to."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="It is built for the 1.6 billion neurodivergent minds who have been overlooked, misunderstood, or forced into a one-size-fits-all approach.It is a shift from the industrial model of education to the intelligent era of learning."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="We are no longer confined to rigid curricula. AI agents, powered by neuroscience, can now help individuals learn in the way that aligns with their natural strengths. MindScriber integrates cutting-edge AI technology, subconscious learning frameworks, and neuroscientific breakthroughs—leveraging what we pioneered in cognitive conditioning, emotional intelligence, and adaptive neurofeedback."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="It is the bridge between human intelligence and machine learning, unlocking a future where learning is personalized, dynamic, and intuitive."
                color={theme.colors.neutral[30]}
              />
            </div>

            {isDesktop && (
              <div className={styles.centuries}>
                <CustomHeadline
                  size={isDesktop ? 32 : 24}
                  text="Why Us? Why Now?"
                  color={theme.colors.neutral[20]}
                />

                <CustomText
                  size={isDesktop ? 20 : 15}
                  text="Because no one else understands this intersection of human performance, neuroscience, and AI the way we do."
                  color={theme.colors.neutral[30]}
                />
                <CustomText
                  size={isDesktop ? 20 : 15}
                  text="Because we have seen, firsthand, what happens when you give children the right tools—and the tragedy of what happens when they are left to struggle without them."
                  color={theme.colors.neutral[30]}
                />
                <CustomText
                  size={isDesktop ? 20 : 15}
                  text="Because learning isn’t broken—it has simply never been designed for the way the brain actually works."
                  color={theme.colors.neutral[30]}
                />
                <CustomText
                  size={isDesktop ? 20 : 15}
                  text="With MindScriber, we are not just enhancing education."
                  color={theme.colors.neutral[30]}
                />
                <CustomText
                  size={isDesktop ? 20 : 15}
                  text="We are redefining intelligence itself."
                  color={theme.colors.neutral[30]}
                />
              </div>
            )}
          </div>
        </div>
        <CustomText
          size={isDesktop ? 20 : 15}
          text="“A computer is a bicycle for the mind.” — Steve Jobs"
          color={theme.colors.neutral[30]}
          styles={{
            fontStyle: "italic",
            textAlign: !isDesktop ? "center" : undefined,
            margin: "0 auto",
          }}
        />
        <div className={styles.buttonsLogo}>
          {isDesktop && <img src={logoForbes} alt="logo" />}
          <div className={styles.blockButtons}>
            <CustomButtonSection
              text="Back"
              colorScheme="green"
              onClick={handClickSection1}
              style={{
                color: theme.colors.neutral[20],
                width: isDesktop ? "8.177dvw" : "40.69dvw",
              }}
            />
            <CustomButtonSection
              text="Forward"
              colorScheme="green"
              onClick={handClickSection3}
              style={{
                color: theme.colors.neutral[20],
                width: isDesktop ? "8.177dvw" : "40.69dvw",
              }}
            />
          </div>
        </div>
      </div>
      <img className={styles.fon} src={isDesktop ? fon : fon2} alt="fon" />
    </div>
  );
};
