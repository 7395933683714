import { Section1Web3 } from "web3-landing-page/components/section1-web-3";
import styles from "./styles.module.css";
import { CustomText } from "components/ui";
import { theme } from "constants/theme";
import { useDeviceType } from "hooks";
import { CustomButtonSection } from "components/custom-button-section";
import { useFormik } from "formik";
import * as Yup from "yup";
import { db } from "config/firebase";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";

export const Section1Desktop = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  const [submitStatus, setSubmitStatus] = useState<{
    success: boolean | null;
  }>({ success: null });

  useEffect(() => {
    if (submitStatus.success) {
      const timer = setTimeout(() => {
        setSubmitStatus({ success: null });
        formik.resetForm();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [submitStatus.success]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const q = query(
          collection(db, "rewardpool"),
          where("email", "==", values.email)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          setSubmitStatus({ success: true });
          return;
        }

        await addDoc(collection(db, "rewardpool"), {
          ...values,
          createdAt: new Date(),
        });

        setSubmitStatus({ success: true });
      } catch (error) {
        console.error("Error saving form data to Firestore: ", error);
      }
    },
  });

  const isButtonDisabled =
    !(formik.isValid && formik.dirty) || submitStatus.success;
  return (
    <div className={styles.container}>
      <Section1Web3 />
      <div className={styles.pool}>
        <CustomText
          size={isDesktop ? 22 : 23}
          text="Win $10,000 in SCRB or USDC!"
          color={theme.colors.neutral[0]}
          styles={{
            lineHeight: "30px",
            fontWeight: 600,
          }}
        />
        <CustomText
          size={isDesktop ? 16 : 23}
          text="Hold $50+ $SCRB, follow & engage—power the ecosystem & qualify."
          color={theme.colors.neutral[0]}
          styles={{
            lineHeight: "21px",
            fontWeight: 400,
          }}
        />
        <div className={styles.containerInput}>
          <input
            type="email"
            className={styles.subscribeInput}
            placeholder={
              submitStatus.success
                ? "Check your inbox for updates."
                : "Your@email.com"
            }
            value={formik.values.email}
            onChange={formik.handleChange}
            disabled={submitStatus.success ?? false}
          />
          <CustomButtonSection
            text={submitStatus.success ? "You're In!" : "Join the Reward Pool"}
            colorScheme="green"
            style={{
              color: theme.colors.neutral[0],
              height: isDesktop ? "100%" : "",
            }}
            type="submit"
            onClick={() => formik.handleSubmit()}
            disabled={isButtonDisabled || (submitStatus.success ?? false)}
          />
        </div>
      </div>
    </div>
  );
};
