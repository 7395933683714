import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { CustomButtonSection } from "components/custom-button-section";
import Slideshow from "components/animation-forbes";
import { useFormik } from "formik";
import * as Yup from "yup";
import { db } from "config/firebase";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";

export const Section5Web3 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  const [submitStatus, setSubmitStatus] = useState<{
    success: boolean | null;
  }>({ success: null });

  useEffect(() => {
    if (submitStatus.success) {
      const timer = setTimeout(() => {
        setSubmitStatus({ success: null });
        formik.resetForm();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [submitStatus.success]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const q = query(
          collection(db, "rewardpool"),
          where("email", "==", values.email)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          setSubmitStatus({ success: true });
          return;
        }

        await addDoc(collection(db, "rewardpool"), {
          ...values,
          createdAt: new Date(),
        });

        setSubmitStatus({ success: true });
      } catch (error) {
        console.error("Error saving form data to Firestore: ", error);
      }
    },
  });

  const handleOurMissionClick = () => {
    window.location.href = "https://mindscriber.com/detail/early-diagnosis";
  };

  const isButtonDisabled =
    !(formik.isValid && formik.dirty) || submitStatus.success;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.centuries}>
          <CustomHeadline
            size={25}
            text="Reinventing Intelligence. Powering the Future."
            color={
              isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[0]
            }
            styles={{
              lineHeight: "27px",
              textAlign: "center",
              fontWeight: "600",
              width: "85%",
            }}
          />
          <CustomText
            size={isDesktop ? 20 : 15}
            text="MindScriber merges AI, neurofeedback, and blockchain to create a decentralized learning and intelligence-sharing ecosystem"
            color={theme.colors.neutral[0]}
            styles={{
              textAlign: "center",
              // fontStyle: "italic",
              lineHeight: "20px",
              fontWeight: 400,
            }}
          />
          <CustomButtonSection
            text="Our Mission"
            colorScheme="green"
            onClick={handleOurMissionClick}
          />
        </div>
        <CustomText
          size={isDesktop ? 20 : 18}
          text="Supported by:"
          color={theme.colors.neutral[0]}
          styles={{
            textAlign: "center",
            lineHeight: "23px",
            fontWeight: 400,
          }}
        />
        <div className={styles.blockAnimation}>
          <Slideshow
            variant="white5"
            style={{
              margin: "0 auto",
            }}
            styleImg={{
              left: "50%",
              transform: "translateX(-50%)",
              height: "30px",
            }}
          />
        </div>

        <div className={styles.centuries2}>
          <CustomHeadline
            size={21}
            text="Win $10,000 in SCRB or USDC!"
            color={
              isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[0]
            }
            styles={{
              lineHeight: "30px",
              textAlign: "center",
              fontWeight: "600",
            }}
          />
          <CustomText
            size={isDesktop ? 20 : 15}
            text="Hold $50+ $SCRB, follow & engage—power the ecosystem & qualify."
            color={theme.colors.neutral[0]}
            styles={{
              textAlign: "center",
              fontStyle: "italic",
              lineHeight: "20px",
              fontWeight: 400,
            }}
          />
        </div>
        <input
          type="email"
          className={styles.input}
          placeholder={
            submitStatus.success
              ? "Check your inbox for updates."
              : "Your@email.com"
          }
          value={formik.values.email}
          onChange={formik.handleChange}
          disabled={submitStatus.success ?? false}
        />
        <CustomButtonSection
          text={submitStatus.success ? "You're In!" : "Join the Reward Pool"}
          colorScheme="green"
          type="submit"
          onClick={() => formik.handleSubmit()}
          disabled={isButtonDisabled || (submitStatus.success ?? false)}
        />
      </div>
    </div>
  );
};
