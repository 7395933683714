import { CustomText } from "components/ui";
import styles from "./styes.module.css";
import { theme } from "constants/theme";
import { FC } from "react";
import { useDeviceType } from "hooks";

interface CardTeamProps {
  img: string;
  p1: string;
  p2: string;
  p3: string;
}

export const CardTeam: FC<CardTeamProps> = ({ img, p1, p2, p3 }) => {
  const { device } = useDeviceType();

  const isDesktop = device === "desktop";

  return (
    <div className={styles.teamMember}>
      <img src={img} alt={p1} className={styles.avatar} />
      <div className={styles.memberInfo}>
        <CustomText
          size={isDesktop ? 28 : 18}
          text={p1}
          color={theme.colors.neutral[0]}
          styles={{
            lineHeight: isDesktop ? 30 : 24,
            fontWeight: 700,
          }}
        />
        <CustomText
          size={isDesktop ? 16 : 12}
          text={p2}
          color={theme.colors.neutral[0]}
          styles={{
            lineHeight: isDesktop ? 22 : 16,
          }}
        />
        <CustomText
          size={isDesktop ? 18 : 14}
          text={p3}
          color={theme.colors.neutral[0]}
          styles={{
            lineHeight: isDesktop ? 20 : 17,
          }}
        />
      </div>
    </div>
  );
};
