import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { useDispatch } from "react-redux";
import { useDeviceType } from "hooks";
import { closeModal } from "store";

export const BurgerBlock = () => {
  const { device } = useDeviceType();
  const dispatch = useDispatch();

  const isDesktop = device === "desktop";

  const isWeb3Site = window.location.hostname === "web3.mindscriber.com";

  const generateLink = (path: string) => {
    if (isWeb3Site && path.startsWith("/detail/")) {
      return `https://mindscriber.com${path}`;
    }
    return path;
  };

  return (
    <div className={styles.container}>
      <ul>
        <li>
          <Link
            to={generateLink("/detail/sentient-framework")}
            onClick={() => dispatch(closeModal())}
          >
            Sentient Framework
          </Link>
        </li>
        <li>
          <Link
            to={generateLink("/detail/neurodivergent-learning")}
            onClick={() => dispatch(closeModal())}
          >
            Neurodivergent Learning
          </Link>
        </li>
        <li>
          <Link
            to={generateLink("/detail/adaptive-intelligence")}
            onClick={() => dispatch(closeModal())}
          >
            Adaptive Intelligence
          </Link>
        </li>
        <li>
          <Link
            to={generateLink("/detail/voice-cloning-science")}
            onClick={() => dispatch(closeModal())}
          >
            Voice Cloning Science
          </Link>
        </li>
        <li>
          <Link
            to={generateLink("/detail/ai-framework")}
            onClick={() => dispatch(closeModal())}
          >
            Enterprise AI Solutions
          </Link>
        </li>
        <li>
          <Link
            to={generateLink("/detail/token-economy")}
            onClick={() => dispatch(closeModal())}
          >
            SCRB Token Economy
          </Link>
        </li>
        <li>
          <Link
            to="#"
            onClick={() => {
              dispatch(closeModal());
              window.location.href = "https://web3.mindscriber.com";
            }}
          >
            Our IDO Launch
          </Link>
        </li>
        <li>
          <Link
            to={generateLink("/detail/team")}
            onClick={() => dispatch(closeModal())}
          >
            The Team
          </Link>
        </li>
      </ul>
      {isDesktop && (
        <div className={styles.link}>
          <a
            href="https://t.me/mindscriber"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram
          </a>
          <a
            href="https://www.instagram.com/scriber_scrb/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://x.com/MindScriber"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </div>
      )}
    </div>
  );
};
