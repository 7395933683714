import { CustomHeadline, CustomText } from "components/ui";
import styles from "./styles.module.css";
import { theme } from "../../../constants/theme";
import { useDeviceType } from "hooks";
import { CustomButtonSection } from "components/custom-button-section";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AudioOn from "assets/svg/audio-on";
import AudioOff from "assets/svg/audio-off";

export const Section11 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const [isAudioOn, setIsAudioOn] = useState(true);

  const toggleAudio = () => {
    setIsAudioOn((prev) => !prev);
  };

  const navigate = useNavigate();
  const handleExploreClick = () => {
    navigate("/detail/scaling-intelligence");
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <CustomHeadline
          size={isDesktop ? 55 : 24}
          text={
            isDesktop
              ? "AI-Powered Learning for Organizations"
              : "Transform Your Organization With MindScriber"
          }
          color={theme.colors.neutral[0]}
          styles={{ lineHeight: `${isDesktop ? 52 : 26}px` }}
        />
        <div className={styles.p}>
          <CustomText
            size={isDesktop ? 24 : 14}
            text={
              "Revolutionary AI tools designed to optimize workflow, enhance decision-making, and drive real-time efficiency across industries."
            }
            color={theme.colors.neutral[0]}
            styles={{
              lineHeight: `${isDesktop ? 28 : 17}px`,
            }}
          />
        </div>
        <CustomButtonSection
          text="Contact Us"
          colorScheme="green"
          onClick={handleExploreClick}
          style={{
            marginTop: isDesktop ? "2%" : "7%",
            marginBottom: "1.35dvw",
          }}
        />
      </div>
      {/* <div className={styles.imgAudio} onClick={toggleAudio}>
        {isAudioOn ? <AudioOn /> : <AudioOff />}
      </div> */}
    </div>
  );
};
