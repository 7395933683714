import React, { CSSProperties, ReactNode, useState, useEffect } from "react";
import s from "./styles.module.css";

interface IProps {
  text: string | ReactNode;
  size?: number;
  color?: string;
  styles?: CSSProperties;
  designWidth?: number;
  designHeight?: number;
  mobileDesignWidth?: number;
  mobileDesignHeight?: number;
  onClick?: () => void;
}

export const CustomText = ({
  text,
  size = 16,
  color = "#3F3F3F",
  styles,
  designWidth = 1920,
  designHeight = 1080,
  mobileDesignWidth = 430,
  mobileDesignHeight = 800,
  onClick,
}: IProps) => {
  const [fontSize, setFontSize] = useState(size);

  const calculateFontSize = (
    base: number,
    screenWidth: number,
    screenHeight: number
  ) => {
    const isMobile = screenWidth < 768;
    const currentDesignWidth = isMobile ? mobileDesignWidth : designWidth;
    const currentDesignHeight = isMobile ? mobileDesignHeight : designHeight;

    const widthScale = screenWidth / currentDesignWidth;
    const heightScale = screenHeight / currentDesignHeight;

    const scaleFactor = (widthScale + heightScale) / 2;
    return base * scaleFactor;
  };

  useEffect(() => {
    const handleResize = () => {
      const newFontSize = calculateFontSize(
        size,
        window.innerWidth,
        window.innerHeight
      );
      setFontSize(newFontSize);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size, designWidth, designHeight, mobileDesignWidth, mobileDesignHeight]);
  return (
    <p
      style={{
        ...styles,
        fontSize: `${fontSize}px`,
        lineHeight: `${fontSize * 1.2}px`,
        color,
      }}
      className={s.prgrph}
      onClick={onClick}
    >
      {text}
    </p>
  );
};
