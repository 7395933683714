export type Colors = Record<
  "neutral" | "blue" | "green",
  string | Record<number, string>
>;

export const theme = {
  colors: {
    neutral: {
      0: "#FFFFFF",
      20: "#000000",
      30: "#3F3F3F",
      40: "#060503",
      48: "#F2F2F2",
      50: "#060504",
      100: "#D3D3D3",
    },

    blue: {
      100: "#1956E4",
    },
    green: {
      48: "#D3F1E2",
      50: "#002118",
      60: "#B2E8CB",
      70: "#61A781",
      80: "#04CC95",
      90: "#00CB94",
      95: "#007D59",
    },
  },
  borderRadius: {
    full: "50px",
  },
};
export const setThemeVariables = () => {
  const root = document.documentElement;
  root.style.setProperty("--color-text", theme.colors.neutral[0]);
  root.style.setProperty("--color-text-black", theme.colors.neutral[20]);
  root.style.setProperty("--color-text-grey", theme.colors.neutral[30]);
  root.style.setProperty("--primary-green", theme.colors.green[90]);
  root.style.setProperty("--hover-green", theme.colors.green[80]);
  root.style.setProperty("--border-radius", theme.borderRadius.full);
  root.style.setProperty("--grey-text", theme.colors.neutral[100]);
};