import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import styles from "./navbar.module.css";
import {
  BurgerMenuIcon,
  InstagramIcon,
  TelegramIcon,
  TwitterXIcon,
} from "assets/svg";
import LogoBlack from "assets/images/logo-black.png";
import LogoWhite from "assets/images/logo-white.png";
import forbesImg from "assets/images/black-forbes-header.png";
import { toggleModal } from "store";
import { useDeviceType } from "hooks";
import { ThemeType } from "types/themeType";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Check } from "assets/svg/check-nuvbar";
import { Line } from "assets/svg/line-nuvbar";
import Slideshow from "components/animation-forbes";

interface NavbarProps {
  activeSection: number;
  navigateToTop: () => void;
  theme?: ThemeType;
  logoVariant?: "default" | "noLogoText";
  blur?: boolean;
  forbes?: boolean;
  fixed?: boolean;
}

const themeSettings: Record<
  ThemeType,
  {
    logoSrc: any;
    textColor: string;
    iconColor: string;
    borderColor: string;
    catTextColor: string;
  }
> = {
  white: {
    logoSrc: LogoWhite,
    textColor: styles.textWhite,
    iconColor: "#FFF",
    borderColor: "#FFF",
    catTextColor: styles.textWhite,
  },
  black: {
    logoSrc: LogoBlack,
    textColor: styles.textBlack,
    iconColor: "#000",
    borderColor: "#000",
    catTextColor: styles.textBlack,
  },
  half: {
    logoSrc: LogoBlack,
    textColor: styles.textBlack,
    iconColor: "#FFF",
    borderColor: "#FFF",
    catTextColor: styles.textWhite,
  },
};

export const Navbar: React.FC<NavbarProps> = ({
  activeSection,
  navigateToTop,
  theme: customTheme,
  logoVariant = "default",
  blur,
  forbes,
  fixed = true,
}) => {
  const { device } = useDeviceType();
  const isDesktop = useMemo(() => {
    return device === "desktop";
  }, [device]);

  const dispatch = useDispatch();
  const isModalOpen = useSelector((state: any) => state.modal.isOpen);
  const themes: ThemeType[] = useMemo(
    () => [
      "white",
      "half",
      "white",
      "half",
      "half",
      "white",
      "black",
      "white",
      "half",
      "white",
      "half",
      "white",
      isDesktop ? "half" : "black",
      "white",
    ],
    [isDesktop]
  );

  const computedTheme: ThemeType = useMemo(() => {
    return (customTheme || themes[activeSection] || "white") as ThemeType;
  }, [customTheme, themes, activeSection]);

  const { logoSrc, textColor, iconColor, borderColor, catTextColor } =
    themeSettings[computedTheme];

  const handleMenuButtonClick = useCallback(() => {
    dispatch(toggleModal());
  }, [dispatch]);

  const mobileIcons = useMemo(
    () => [
      <TelegramIcon key="telegram" heightFactor={22} color={iconColor} />,
      <InstagramIcon key="instagram" heightFactor={22} color={iconColor} />,
      <TwitterXIcon key="twitter" heightFactor={22} color={iconColor} />,
    ],
    [iconColor]
  );

  const [currentIconIndex, setCurrentIconIndex] = useState(0);

  useEffect(() => {
    if (!isDesktop) {
      const interval = setInterval(() => {
        setCurrentIconIndex(
          (prevIndex) => (prevIndex + 1) % mobileIcons.length
        );
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [isDesktop, mobileIcons.length]);

  const headerClassName = fixed ? styles.header : styles.headerNotFixed;

  return (
    <header className={`${headerClassName} ${blur ? styles.blur : ""}`}>
      {isDesktop ? (
        <div
          className={styles.logoContainer}
          onClick={navigateToTop}
          style={{
            visibility: logoVariant === "noLogoText" ? "hidden" : "visible",
          }}
        >
          <img src={logoSrc} alt="icon" className={styles.logo} />
          <h2 className={`${styles.scrbTitle} ${textColor}`}>MindScriber</h2>
          {isDesktop && activeSection === 1 && (
            <div className={styles.forbes}>
              {" "}
              <Slideshow variant="black" styleImg={{ height: "35px" }} />
            </div>
          )}
        </div>
      ) : (
        <img
          src={logoSrc}
          alt="icon"
          className={styles.logo}
          onClick={navigateToTop}
        />
      )}

      <div className={styles.actions}>
        {isDesktop ? (
          <nav className={styles.socialIcons}>
            <a
              href="https://t.me/mindscriber"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TelegramIcon heightFactor={30} color={iconColor} />
            </a>
            <a
              href="https://www.instagram.com/scriber_scrb/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon heightFactor={30} color={iconColor} />
            </a>
            <a
              href="https://x.com/MindScriber"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterXIcon heightFactor={30} color={iconColor} />
            </a>
          </nav>
        ) : (
          <div
            className={styles.mobileIconContainer}
            onClick={() => dispatch(toggleModal("linkSocial"))}
          >
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={currentIconIndex}
                timeout={2000}
                classNames="fade"
              >
                <div className={styles.mobileIcon}>
                  {mobileIcons[currentIconIndex]}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
        )}
        {!isDesktop && (
          <div onClick={() => dispatch(toggleModal("default"))}>
            <Check heightFactor={30} color={iconColor} />
          </div>
        )}
        {!isDesktop && <Line heightFactor={30} color={iconColor} />}
        <div className={styles.burgerMemuBlock}>
          {isDesktop && (
            <span
              className={catTextColor}
              style={{ borderRight: `2px solid ${borderColor}` }}
              onClick={handleMenuButtonClick}
            >
              All Categories
            </span>
          )}
          <button
            className={styles.menuBtn}
            onClick={() => dispatch(toggleModal("burger"))}
          >
            <div>
              <BurgerMenuIcon heightFactor={30} color={iconColor} />
            </div>
          </button>
        </div>
      </div>
    </header>
  );
};
