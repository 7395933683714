import { Block2 } from "../block-2";
import styles from "../section-12-13-mobile/styles.module.css";

export const Section13Mobile = () => {
  return (
    <>
      <div className={styles.container}>
        <Block2 />
      </div>
    </>
  );
};
