import {
  useState,
  useEffect,
  useCallback,
  FunctionComponent,
  SVGAttributes,
} from "react";
import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { CustomButtonSection } from "components/custom-button-section";
import PaymentOptions from "./paymentOptions/PaymentOptions";
import { NavIdWeb3 } from "types/navigation-web3";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useChainId, useDisconnect } from "wagmi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEthereumContext } from "../presale/ethereumContext";
import { Section1Desktop } from "./desktopWersionBlock";
import { Modal, Navbar } from "components";
import { UsdtIcon } from "../../../assets/svg/usdt";
import { useNavigate } from "react-router-dom";
import { PresaleOffer } from "utils";
import { CurrensySelect } from "./currency-select";
import { PriseIncrease } from "./blockPriseIncrease";

export const Section2Web3 = () => {
  const chainId = useChainId();
  const [translate, setTranslate] = useState(0);
  const [activeSection, setActiveSection] = useState(0);
  const navigate = useNavigate();
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { buyTokens } = useEthereumContext();
  const [buyAmount, setBuyAmount] = useState<string>("");
  // Updated type for selectedCurrency to match react-select's OptionType
  type OptionType = {
    value: "BNB" | "ETH" | "USDT" | "USDC";
    label: string;
    svg: FunctionComponent<SVGAttributes<SVGSVGElement>>;
  };
  const [selectedCurrency, setSelectedCurrency] = useState<OptionType>({
    value: "USDT",
    label: "",
    svg: UsdtIcon,
  });
  const [bonusCode, setBonusCode] = useState<string>("");
  const [legalNoticeChecked, setLegalNoticeChecked] = useState<boolean>(true);
  const [rates, setRates] = useState<{
    currency: string;
    rates: {
      [key: string]: string;
    };
  }>({
    currency: "",
    rates: {},
  });

  const navigateToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleExploreClick = () => {
    navigate("/detail/trouble-connecting");
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || Number(value) >= 0) {
      setBuyAmount(value);
    }
  };
  const handleBonusCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setBonusCode(value);
  };

  const handleCurrencyChange = (newValue: OptionType | null) => {
    if (newValue) {
      setSelectedCurrency(newValue);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.coinbase.com/v2/exchange-rates?currency=${selectedCurrency.value}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch exchange rates");
        }
        const data = await response.json();
        setRates(data.data);
      } catch (err) {
        // fail silently and only for tracking purpose
        console.log("Error from CDP: ");
      } finally {
      }
    };

    fetchData();
  }, [selectedCurrency]);

  const maxFunds = 10000;
  const allocatedFunds = 2500;
  const progressBarWidth = (allocatedFunds / maxFunds) * 100;

  const [showMessage, setShowMessage] = useState(false);
  const handleSubmit = useCallback(async () => {
    if (!buyAmount || !selectedCurrency) {
      toast.error("Please enter an amount and select a currency");
      return;
    }

    try {
      buyTokens(buyAmount, selectedCurrency.value, bonusCode);
    } catch (error) {
      console.error("Transaction error:", error);
    }
  }, [buyAmount, selectedCurrency, buyTokens, bonusCode]);

  const handleLegalNoticeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLegalNoticeChecked(e.target.checked);
  };

  return (
    <div
      id={NavIdWeb3.Section2}
      className={styles.container}
      style={{
        backgroundColor: isConnected ? "hsl(164, 100%, 40%)" : "",
        backgroundImage: isDesktop ? "" : isConnected ? "none" : "",
      }}
    >
      {isDesktop && (
        <>
          <Navbar
            activeSection={activeSection}
            navigateToTop={navigateToTop}
            theme="white"
            fixed={false}
          />
          <Modal
            setTranslate={setTranslate}
            setActiveSection={setActiveSection}
          />
        </>
      )}
      {isDesktop && <Section1Desktop />}
      <div
        className={styles.content}
        style={{
          backgroundColor: isConnected
            ? "hsl(164, 100%, 40%) "
            : isDesktop
            ? "hsla(0, 0.00%, 0.00%, 0.35)"
            : "",
        }}
      >
        <div className={styles.blockPresale}>
          {isConnected ? (
            <CustomHeadline
              size={isDesktop ? 40 : 26}
              text="You're Connected!"
              color={theme.colors.neutral[0]}
              styles={{
                lineHeight: isDesktop ? "50px" : "26px",
                textAlign: "center",
                width: "100%",
              }}
            />
          ) : (
            <>
              <CustomHeadline
                size={isDesktop ? 28 : 26}
                text="$SCRB Presale – Limited Time Offer"
                color={theme.colors.neutral[0]}
                styles={{
                  lineHeight: isDesktop ? 50 : 26,
                  textAlign: "center",
                  width: isDesktop ? "100%" : "80%",
                  fontWeight: 500,
                }}
              />
              <div className={styles.bgPromotion}>
                <div className={styles.textPomotion}>
                  <CustomText
                    size={24}
                    text="Get 50% Off Before TGE"
                    color={theme.colors.neutral[0]}
                    styles={{
                      textAlign: "center",
                      margin: "0 auto",
                      lineHeight: "24px",
                      fontWeight: 700,
                      whiteSpace: isDesktop ? "nowrap" : "normal",
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <PriseIncrease />

        <div className={styles.barBlock}>
          <div className={styles.textBar}>
            <CustomText
              size={isDesktop ? 18 : 14}
              text="Join 6,000+ Investors Securing SCRB at"
              color={theme.colors.neutral[0]}
              styles={{
                lineHeight: "22px",
                fontWeight: 400,
              }}
            />
            <span>
              <CustomText
                size={isDesktop ? 18 : 14}
                text=" 50% off"
                color={
                  isConnected ? theme.colors.neutral[0] : theme.colors.green[90]
                }
                styles={{
                  lineHeight: "22px",
                  fontWeight: 400,
                  display: "block",
                }}
              />
            </span>
          </div>
          <div
            className={styles.progressBar}
            style={{
              backgroundColor: isConnected
                ? "hsl(164, 67%, 55%)"
                : "hsla(0, 0%, 100%, 0.25)",
            }}
          >
            <div
              className={styles.progressFill}
              style={{
                width: `75%`,
                backgroundColor: isConnected
                  ? "hsl(0, 0%, 100%)"
                  : "hsl(164, 100%, 40%)",
              }}
            ></div>
          </div>

          <CustomText
            size={16}
            text="Limited Allocation Remaining"
            color="rgba(255, 255, 255, 0.67)"
            styles={{
              textAlign: "left",
              lineHeight: "22px",
              fontWeight: 400,
            }}
          />
        </div>

        {isConnected ? (
          <>
            <div className={styles.walletInfo}>
              <div className={`${styles.walletBox} ${styles.networkBox}`}>
                {/* <span className={styles.networkIcon}></span> */}
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text={PresaleOffer.getCurrentNetwork(chainId)}
                  color={theme.colors.neutral[0]}
                  styles={{
                    textAlign: "center",
                    fontWeight: 500,
                  }}
                />
              </div>

              <div className={`${styles.walletBox} ${styles.adressBox}`}>
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text={address?.slice(0, 6) + "...." + address?.slice(36, 42)}
                  color={theme.colors.neutral[0]}
                  styles={{
                    textAlign: "center",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                />
              </div>

              <div
                className={`${styles.walletBox} ${styles.disconnectBox}`}
                onClick={() => disconnect()}
              >
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text="Disconnect"
                  color={theme.colors.neutral[0]}
                  styles={{
                    textAlign: "center",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>

            <div className={styles.connectedInfo}>
              <CustomText
                size={18}
                text="You’re IN!, Enter the amount and select your currency to buy $SCRB."
                color={theme.colors.neutral[0]}
                styles={{
                  textAlign: "center",
                  lineHeight: "20px",
                  fontWeight: 400,
                  width: isDesktop ? "79%" : "90%",
                }}
              />

              <div className={styles.connectedContentTop}>
                <div className={styles.amountInputContainer}>
                  <label
                    style={{
                      color: "#ffffff",
                      fontSize: "12px",
                      lineHeight: "22px",
                    }}
                  >
                    Enter Amount
                  </label>
                  <div className={styles.inputSelectWrapper}>
                    <input
                      type="text"
                      value={buyAmount}
                      onChange={handleAmountChange}
                      className={styles.amountInput}
                      placeholder="0"
                      required
                    />
                    <CurrensySelect
                      selectedCurrency={selectedCurrency}
                      handleCurrencyChange={handleCurrencyChange}
                    />
                  </div>
                </div>

                <span className={styles.equalsSign}>=</span>

                <div className={styles.receiveSection}>
                  <label
                    style={{
                      color: "#ffffff",
                      fontSize: "12px",
                      lineHeight: "22px",
                      textAlign: "left",
                      marginBottom: "12px",
                      width: "100%",
                    }}
                  >
                    You recieve
                  </label>
                  <CustomText
                    size={isDesktop ? 16 : 14}
                    text={`${
                      rates.rates?.USD
                        ? (
                            (parseFloat(buyAmount || "0") *
                              parseFloat(rates.rates?.USD)) /
                            0.0009
                          ).toFixed(2)
                        : "N/A"
                    } $SCRB`}
                    color={theme.colors.neutral[0]}
                    styles={{
                      textAlign: "center",
                      lineHeight: "22px",
                      fontWeight: 500,
                    }}
                  />
                </div>
              </div>

              <div className={styles.connectedContentBottom}>
                <div className={styles.bonusCodeContainer}>
                  <label
                    style={{
                      color: "#ffffff",
                      fontSize: "12px",
                      lineHeight: "22px",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    Bonus Code (Optional)
                  </label>
                  <input
                    type="text"
                    value={bonusCode}
                    onChange={handleBonusCodeChange}
                    className={styles.bonusCodeInput}
                    placeholder="Code"
                    required
                  />
                </div>

                <button className={styles.buyMoreButton} onClick={handleSubmit}>
                  Buy Now
                </button>
              </div>

              <div className={styles.connecteLegalContainer}>
                <input
                  type="checkbox"
                  checked={legalNoticeChecked}
                  onChange={handleLegalNoticeChange}
                  className={styles.legalNoticeCheckbox}
                />

                <CustomText
                  size={isDesktop ? 12 : 11}
                  text={
                    <>
                      By clicking 'Buy Now', you confirm that you are not a U.S.
                      citizen or U.S. resident. See{" "}
                      <a href="#" className={styles.legalNoticeLink}>
                        [Legal Notice]
                      </a>
                    </>
                  }
                  color="rgba(255, 255, 255, 0.67)"
                  styles={{
                    textAlign: "left",
                    lineHeight: "16px",
                    fontWeight: 400,
                    width: "90%",
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.blockButton}>
              <CustomText
                size={isDesktop ? 20 : 18}
                text="Secure Your $SCRB Before the Price Goes Up"
                color={theme.colors.neutral[0]}
                styles={{
                  textAlign: "center",
                  lineHeight: "22px",
                  fontWeight: 700,
                  width: isDesktop ? "65%" : "75%",
                }}
              />
              <CustomButtonSection
                text="Connect wallet"
                colorScheme="gradient"
                width={isDesktop ? "9.21dvw" : "43.72dvw"}
                height={isDesktop ? "2.5dvw" : "9.3dvw"}
                style={{
                  fontSize: isDesktop ? "1rem" : "16px",
                  marginBottom: isDesktop ? "" : "1.6dvw",
                  textAlign: "center",
                  lineHeight: "24px",
                }}
                onClick={() => open()}
              />
            </div>

            <div className={styles.walletContainer}>
              <div className={styles.metaMask}></div>
              <div className={styles.trustWallet}></div>
              <div className={styles.allWallets}></div>
            </div>

            <div className={styles.paymentContainer}>
              <CustomText
                text="Pay with"
                color="rgba(255, 255, 255, 0.67)"
                styles={{
                  fontSize: isDesktop ? 16 : 14,
                  lineHeight: "22px",
                  fontWeight: 400,
                }}
              />
              <PaymentOptions />

              <CustomText
                size={isDesktop ? 16 : 14}
                text="Trouble Connecting?"
                color="rgba(255, 255, 255, 0.67)"
                styles={{
                  textAlign: "center",
                  lineHeight: "22px",
                  fontWeight: 400,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={handleExploreClick}
              />
            </div>
          </>
        )}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};
