// DetailPage.js
import React, { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { useDeviceType } from "hooks";
import close from "assets/images/close-icon.png";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import { CardTeam } from "../block-2/card-team";
import igar from "assets/images/igar-section-12.png";
import omer from "assets/images/omer-section-12.png.png";
import sachdev from "assets/images/sachdev-section-12.png";
import oleg from "assets/images/oleg-section-12.png";
import mark from "assets/images/mark-section-12.png";
import alexander from "assets/images/alexander-section-12.png";
import marta from "assets/images/marta-section-12.png";
import Yevheniia from "assets/images/yevheniia.png";
import { Modal } from "components/modal";
import { CloseBlock } from "components/closemomileDetail";
import closeImgMob from "assets/images/material-symbols-light_arrow-back (1).png";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage12: React.FC<DetailPageProps> = ({ handleClose }) => {
  const [translate, setTranslate] = useState(0);

  const [activeSection, setActiveSection] = useState<number>(0);

  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  const navigate = useNavigate();

  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section1)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);

  return (
    <div className={styles.section12}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        logoVariant="noLogoText"
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        {isDesktop && (
          <img
            src={close}
            alt="img"
            className={styles.button}
            onClick={() => handleClose(NavId.Section12, 12)}
          />
        )}

        {!isDesktop && (
          <CloseBlock
            id={NavId.Section12}
            index={13}
            handleClose={handleClose}
            text="Exit Team View"
            textColor="white"
            containerStyles={{ marginLeft: "5%" }}
            imgSrc={closeImgMob}
          />
        )}
        <div className={styles.content}>
          <CardTeam
            img={igar}
            p1="Igar Dyachenko "
            p2="Co-Founder, CEO"
            p3="A dyslexic visionary redefining learning tools for neurodivergent individuals."
          />

          <CardTeam
            img={mark}
            p1="Dr. Mark Litvak "
            p2="Co-Founder, Chief Science Officer"
            p3="A neuroscientist pushing cognitive performance breakthroughs."
          />
          <CardTeam
            img={alexander}
            p1="Alexander Riftine"
            p2="Chief Algorithm Officer"
            p3="Specialist in integrating health monitoring and neurotech systems."
          />
          <CardTeam
            img={sachdev}
            p1="Dr. Nisheta Sachdev"
            p2="CMO and Advisor"
            p3="Blockchain marketing strategist fostering growth, innovation, and partnerships in web3.."
          />

          <CardTeam
            img={omer}
            p1="Omer Cohen"
            p2="Chief Technology Officer"
            p3="Full Stack developer advancing innovative learning tools.."
          />

          <CardTeam
            img={marta}
            p1="Marta Haievyshyn"
            p2="Chief Design Officer"
            p3="UX leader crafting intuitive, learner-friendly designs."
          />

          <CardTeam
            img={oleg}
            p1="Oleg Kryvak "
            p2="Lead Mobile Developer"
            p3="Specialist in mobile ecosystems for neurodivergent tools."
          />

          <CardTeam
            img={Yevheniia}
            p1="Yevheniia Yefymova"
            p2="Frontend Developer"
            p3="Expert in HTML, CSS, JS, React | Performance & Scalable Solutions"
          />
        </div>
      </div>
    </div>
  );
};
