import { CustomHeadline, CustomText } from "components/ui";
import styles from "./styles.module.css";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { ContactFormBlock1 } from "components/sections/section-12/block-1/contact-form";

export const Section15Web3 = () => {
  const { device } = useDeviceType();

  const isDesktop = device === "desktop";

  return (
    <>
      <div className={styles.containerBlock1}>
        <div className={styles.content}>
          <div className={styles.text}>
            <CustomHeadline
              size={isDesktop ? 50 : 24}
              text="Let’s Build the Future Together"
              color={theme.colors.neutral[20]}
              styles={{
                lineHeight: `${isDesktop ? 56 : 26}px`,
                textAlign: "center",
              }}
            />

            <CustomText
              size={isDesktop ? 20 : 14}
              text="Have something big to offer? Partnerships, contributions, or ideas—fill out the form."
              color={theme.colors.neutral[20]}
              styles={{ textAlign: "center" }}
            />
          </div>

          <div className={styles.form}>
            <ContactFormBlock1 />
          </div>
        </div>
        <div>
          <CustomText
            size={isDesktop ? 20 : 15}
            text="© MindScriber 2035"
            color={theme.colors.neutral[20]}
            styles={{ textAlign: "center", lineHeight: "17" }}
          />
        </div>
      </div>
    </>
  );
};
