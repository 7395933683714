// DetailPage.js
import React, { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import buttonIcon from "assets/images/Listening Button.png";
import icon from "assets/images/icon-section-1-info.png";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/fon-section1-ingo.png";
import fon2 from "assets/images/fon-section1-info-mobile.png";
import close from "assets/images/close-icon.png";
import closeMobile from "assets/images/material-symbols-light_arrow-back.png";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import { Modal } from "components/modal";
import shema1 from "assets/images/shema1-section1-detal.png";
import shema2 from "assets/images/shema2-section1-detal.png";
import { CloseBlock } from "components/closemomileDetail";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage: React.FC<DetailPageProps> = ({ handleClose }) => {
  const [translate, setTranslate] = useState(0);

  const [activeSection, setActiveSection] = useState<number>(0);

  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  const navigate = useNavigate();

  const handClickSection2 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/neurodivergent-learning");
  };
  const handClickSection11 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/scaling-intelligence");
  };

  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section1)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);

  return (
    <div className={styles.section1}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        theme="black"
        logoVariant="noLogoText"
        blur
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        {isDesktop && (
          <img
            src={close}
            alt="img"
            className={styles.button}
            onClick={() => handleClose(NavId.Section1, 0)}
          />
        )}

        <div className={styles.content}>
          <div className={styles.block1}>
            {!isDesktop && (
              <CloseBlock
                id={NavId.Section1}
                index={0}
                handleClose={handleClose}
              />
            )}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="The First Sentient AI Framework with Neurofeedback"
                color={theme.colors.neutral[20]}
              />

              <div className={styles.p}>
                <img src={buttonIcon} alt="buttonIcon" />
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text="Tap to hear this page come to life."
                  color={theme.colors.neutral[30]}
                  styles={{
                    fontStyle: "italic",
                    lineHeight: isDesktop ? "28px" : "18px",
                    fontWeight: "300",
                  }}
                />
              </div>

              <CustomText
                size={isDesktop ? 20 : 15}
                text="For the first time in history, artificial intelligence is not just processing information—it is adapting to human cognition itself. MindScriber bridges AI and human intelligence through neuroadaptive learning, emotional calibration, and real-time cognitive alignment, unlocking personalized, autonomous learning at an unprecedented scale."
                color={theme.colors.neutral[30]}
                styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
              />
            </div>
            <CustomText
              size={isDesktop ? 20 : 15}
              text={
                isDesktop
                  ? "“Learning is not just about information. It is about connection—between memory, emotion, and intelligence.”"
                  : "“Neuroplasticity, encrypted reinforcement, and real-time cognition unlock a new frontier in memory retention.”"
              }
              color={theme.colors.neutral[30]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
                lineHeight: isDesktop ? "28px" : "18px",
              }}
            />
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Learning Beyond Limits"
                color={theme.colors.neutral[20]}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="For centuries, learning has been confined to conscious effort—reading, memorization, and repetition. But the human brain is designed to process information beyond wakefulness. Scientific research confirms that memory consolidation occurs during sleep, with neural pathways reorganizing and strengthening connections. Yet, traditional learning models have failed to harness this latent cognitive ability."
                color={theme.colors.neutral[30]}
                styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
              />
            </div>
            <img src={shema1} alt="img" className={styles.icon} />

            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="E-learning is failing millions of learners. With 92% dropout rates, 780M dyslexics unsupported, and zero AI adapting in real time, the education industry is losing an estimated $300B annually due to ineffective, non-personalized learning models. MindScriber addresses this fundamental gap with the first AI-powered neuroadaptive learning framework, unlocking an untapped market by making learning fully adaptive to the individual."
                color={theme.colors.neutral[30]}
                styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
              />
            </div>
            {isDesktop && (
              <div className={styles.centuries}>
                <CustomHeadline
                  size={isDesktop ? 32 : 24}
                  text="Proven Neuroadaptive Intelligence"
                  color={theme.colors.neutral[20]}
                />

                <CustomText
                  size={isDesktop ? 20 : 15}
                  text="With a rapidly growing validation pool, MindScriber has demonstrated a paradigm shift in cognitive optimization. Participants engaging with the Adaptive Sentient Learning Grid (ASLG) experienced a 400% increase in knowledge retention, proving that neuroadaptive learning vastly outperforms traditional memory techniques. Those who listened to their own voice through MindScriber’s reinforcement model showed the highest cognitive gains, unlocking a new frontier in personalized intelligence.Through the Adaptive Sentient Learning Grid (ASLG)—a self-optimizing intelligence layer—MindScriber integrates large learning models, personalized voice replication, and real-time biofeedback to create hyper-personalized learning pathways."
                  color={theme.colors.neutral[30]}
                  styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
                />
              </div>
            )}
          </div>
          <div className={styles.line}></div>
          <div className={styles.block2}>
            {isDesktop && (
              <div className={styles.centuries}>
                <CustomText
                  size={isDesktop ? 20 : 15}
                  text="The Quantum Neural Bridge (QNB) further enhances cross-modal cognition, accurately compressing information by 70% while reducing cognitive fatigue. By decoding neural input at an atomic level, QNB ensures high-speed adaptive feedback, aligning learning sequences to each user’s mental endurance curve."
                  color={theme.colors.neutral[30]}
                  styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
                />
              </div>
            )}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="MindScriber: Unlocking a $1T Cognitive Market"
                color={theme.colors.neutral[20]}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber introduces the world’s first sentient AI framework, designed to work in harmony with the autonomic nervous system. Unlike conventional learning platforms that rely on structured lessons, passive listening, or rote memorization, MindScriber’s proprietary neuroadaptive AI, subconscious reinforcement, and real-time cognitive adaptation enable users to retain knowledge effortlessly—including while they sleep."
                color={theme.colors.neutral[30]}
                styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
              />
            </div>
            <img src={shema2} alt="icon" className={styles.icon} />

            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="By merging AI, neurofeedback, and blockchain, MindScriber powers an entirely new economy of cognitive intelligence:"
                color={theme.colors.neutral[30]}
                styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
              />
              <p>
                {" "}
                <span className={styles.fontSpan}>
                  • $370B AI-Powered Learning
                </span>{" "}
                – Personalized, adaptive education using real-time AI.
              </p>
              <p>
                {" "}
                <span className={styles.fontSpan}>• $585B Sleep Economy</span> –
                Neurotechnology-driven cognitive enhancement.
              </p>
              <p>
                {" "}
                <span className={styles.fontSpan}>
                  • $6.5B Cognitive Neuroscience
                </span>{" "}
                – Advancing memory and performance with AI.
              </p>
            </div>
            {isDesktop && (
              <CustomText
                size={isDesktop ? 20 : 15}
                text={
                  "“High-speed adaptive feedback aligns learning sequences with the mind’s natural rhythm.”"
                }
                color={theme.colors.neutral[30]}
                styles={{
                  fontStyle: "italic",
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: isDesktop ? "28px" : "18px",
                  fontWeight: "300",
                }}
              />
            )}

            {isDesktop && (
              <div className={styles.centuries}>
                <CustomText
                  size={isDesktop ? 20 : 15}
                  text="The Decentralized Cognitive Infrastructure (DCI) safeguards personal learning data through zero-trust encryption and autonomous AI processing, preventing corporate misuse while fostering independent cognitive evolution."
                  color={theme.colors.neutral[30]}
                  styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
                />
              </div>
            )}

            {!isDesktop && (
              <div className={styles.centuries}>
                <CustomHeadline
                  size={isDesktop ? 32 : 24}
                  text="Proven Neuroadaptive Intelligence"
                  color={theme.colors.neutral[20]}
                />

                <CustomText
                  size={isDesktop ? 20 : 15}
                  text="With a rapidly growing validation pool, MindScriber has demonstrated a paradigm shift in cognitive optimization. Participants engaging with the Adaptive Sentient Learning Grid (ASLG) experienced a 400% increase in knowledge retention, proving that neuroadaptive learning vastly outperforms traditional memory techniques. Those who listened to their own voice through MindScriber’s reinforcement model showed the highest cognitive gains, unlocking a new frontier in personalized intelligence."
                  color={theme.colors.neutral[30]}
                  styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
                />
                <CustomText
                  size={isDesktop ? 20 : 15}
                  text="Through the Adaptive Sentient Learning Grid (ASLG)—a self-optimizing intelligence layer—MindScriber integrates large learning models, personalized voice replication, and real-time biofeedback to create hyper-personalized learning pathways."
                  color={theme.colors.neutral[30]}
                  styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
                />
                <CustomText
                  size={isDesktop ? 20 : 15}
                  text="The Quantum Neural Bridge (QNB) further enhances cross-modal cognition, accurately compressing information by 70% while reducing cognitive fatigue. By decoding neural input at an atomic level, QNB ensures high-speed adaptive feedback, aligning learning sequences to each user’s mental endurance curve."
                  color={theme.colors.neutral[30]}
                  styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
                />
              </div>
            )}
            {!isDesktop && (
              <CustomText
                size={isDesktop ? 20 : 15}
                text={
                  "“High-speed adaptive feedback aligns learning sequences with the mind’s natural rhythm.”"
                }
                color={theme.colors.neutral[30]}
                styles={{
                  fontStyle: "italic",
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: isDesktop ? "28px" : "18px",
                  fontWeight: "300",
                }}
              />
            )}
            {!isDesktop && (
              <div className={styles.centuries}>
                <CustomText
                  size={isDesktop ? 20 : 15}
                  text="The Decentralized Cognitive Infrastructure (DCI) safeguards personal learning data through zero-trust encryption and autonomous AI processing, preventing corporate misuse while fostering independent cognitive evolution."
                  color={theme.colors.neutral[30]}
                  styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
                />
              </div>
            )}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="A New Era of Cognitive Intelligence"
                color={theme.colors.neutral[20]}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber’s technology is not just a tool—it’s a scalable AI learning framework designed to integrate across universities, corporations, and neuroadaptive economies."
                color={theme.colors.neutral[30]}
                styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="By aligning neuroscience, AI, and encrypted data architecture, it fundamentally transforms the way knowledge is acquired, distributed, and mastered at every level."
                color={theme.colors.neutral[30]}
                styles={{ lineHeight: isDesktop ? "24px" : "18px" }}
              />
            </div>
          </div>
        </div>
        <CustomText
          size={isDesktop ? 20 : 15}
          text="“Technology will come full circle—back to nature, back to organic intelligence.”"
          color={theme.colors.neutral[30]}
          styles={{
            fontStyle: "italic",
            textAlign: !isDesktop ? "center" : undefined,
            margin: "0 auto",
            width: isDesktop ? undefined : "80%",
            lineHeight: isDesktop ? "28px" : "18px",
            fontWeight: "300",
          }}
        />
        <div className={styles.blockButtons}>
          {/* <CustomButtonSection
            text="Back"
            colorScheme="green"
            onClick={handClickSection11}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          /> */}
          <CustomButtonSection
            text="Forward"
            colorScheme="green"
            onClick={handClickSection2}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "" : "175px",
            }}
          />
        </div>
      </div>
      <img className={styles.fon} src={isDesktop ? fon : fon2} alt="fon" />
    </div>
  );
};
