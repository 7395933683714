// DetailPage.js
import React, { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import buttonIcon from "assets/images/Listening Button.png";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/Frame 26087010.png";
import fon2 from "assets/images/Frame 26087011.png";
import schemaIcon from "assets/images//Group 1409 1.png";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import close from "assets/images/close-icon.png";
import closeMobile from "assets/images/material-symbols-light_arrow-back.png";
import { Modal } from "components/modal";
import { CloseBlock } from "components/closemomileDetail";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage35: React.FC<DetailPageProps> = ({ handleClose }) => {
  const [translate, setTranslate] = useState(0);
  const [activeSection, setActiveSection] = useState<number>(0);
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const navigate = useNavigate();

  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section4)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);

  const handClickSection9 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/early-diagnosis");
  };
  const handClickSection11 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/adaptive-intelligence");
  };

  return (
    <div className={styles.section10}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        theme="black"
        logoVariant="noLogoText"
        blur
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        {isDesktop && (
          <img
            src={close}
            alt="img"
            className={styles.button}
            onClick={() => handleClose(NavId.Section4, 3)}
          />
        )}
        <div className={styles.content}>
          <div className={styles.block1}>
            {!isDesktop && (
              <CloseBlock
                id={NavId.Section4}
                index={3}
                handleClose={handleClose}
              />
            )}
            <div className={styles.centuries}>
              {isDesktop ? (
                <CustomHeadline
                  size={isDesktop ? 32 : 24}
                  text="Scriber Wearable – Unlocking Human Potential"
                  color={theme.colors.neutral[20]}
                />
              ) : (
                <CustomHeadline
                  size={isDesktop ? 32 : 24}
                  text="A Mission to Unlock a Billion Minds with Early Diagnosis"
                  color={theme.colors.neutral[20]}
                />
              )}

              <div className={styles.p}>
                <img src={buttonIcon} alt="buttonIcon" />
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text="Tap to hear this page come to life."
                  color={theme.colors.neutral[30]}
                  styles={{ fontStyle: "italic", lineHeight: "28px" }}
                />
              </div>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="The next evolution in AI-driven cognition is here. The MindScriber Wearable is not just another biometric device—it is the foundation of a new era of sentient AI. By seamlessly integrating hardware and software, it enables real-time cognitive optimization, adaptive neurofeedback, and emotion-aware AI training."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="For the first time, AI can evolve based on real-time human emotions, unlocking a deeper, more intuitive connection between machines and human cognition."
                color={theme.colors.neutral[30]}
              />
            </div>

            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="The Neural Bridge: The Missing Link in AI Evolution"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Traditional AI models rely on static data sets, limited by pre-trained inputs and external programming. The Neural Bridge changes this paradigm by capturing real-time autonomic nervous system signals—enabling AI to learn, adapt, and refine itself dynamically."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="What makes this possible?"
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="• The MindScriber Wearable integrates a multi-layered sensor system, capturing HRV, RR intervals, vascular fluctuations, and cognitive state markers."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="• Edge AI processing ensures that data remains decentralized, private, and on-device before securely interacting with the MindScriber Sentient AI framework."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="• Proof of Knowledge Protocol redefines AI-human interaction—training models not on brute-force computation, but on biofeedback-driven intelligence."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>

          <div className={styles.line}></div>

          <div className={styles.block2}>
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="A Breakthrough in Emotionally Intelligent AI"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="For decades, AI lacked true emotional intelligence. The MindScriber Wearable changes that by interpreting human emotions, stress levels, and cognitive states in real-time. This enables:"
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="• AI agents that respond dynamically to human needs."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text=" • A training model that evolves based on genuine human experiences."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="• A decentralized, privacy-first ecosystem that prioritizes user control over AI interactions."
                color={theme.colors.neutral[30]}
                styles={{ width: "90%" }}
              />
            </div>

            <img className={styles.icon} src={schemaIcon} alt="icon" />

            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="The Future of AI Training Begins Here"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="With 18,000 participants validating neuroadaptive AI, the MindScriber ecosystem is proving that human cognition is the missing key to AI’s full potential. The MindScriber Wearable is not just a device—it’s the foundation of an entirely new approach to artificial intelligence."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
        </div>
        <CustomText
          size={isDesktop ? 20 : 15}
          text="“The greatest danger in times of turbulence is not the turbulence—it is to act with yesterday’s logic.” – Peter Drucker"
          color={theme.colors.neutral[30]}
          styles={{
            fontStyle: "italic",
            textAlign: "center",
            margin: "0 auto",
            width: isDesktop ? "37%" : "80%",
          }}
        />
        <div className={styles.blockButtons}>
          <CustomButtonSection
            text="Back"
            colorScheme="green"
            onClick={handClickSection9}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
          <CustomButtonSection
            text="Forward"
            colorScheme="green"
            onClick={handClickSection11}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
        </div>
      </div>
      <img className={styles.fon} src={isDesktop ? fon : fon2} alt="fon" />
    </div>
  );
};
