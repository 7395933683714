import "./App.css";
import { Provider } from "react-redux";
import { store } from "store";
import { useLayoutEffect, useMemo } from "react";
import { setThemeVariables } from "constants/theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage } from "HomePage";
import { WebThree } from "web3-landing-page/index";
import DetailPageContainer from "DetailPageContainer";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { config } from "./web3-landing-page/components/presale/wagmi";
import { Navigate } from "react-router-dom";
import { AnalyticsProvider } from "components/analytics/analyticsProvider";

function App() {
  useLayoutEffect(() => {
    setThemeVariables();
  }, []);

  const isWeb3 = window.location.hostname.startsWith("web3");

  const queryClient = new QueryClient();

  return (
    <Provider store={store}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter basename="/">
            <AnalyticsProvider>
              <Routes>
                {!isWeb3 && (
                  <>
                    <Route path="/" element={<HomePage />} />
                    <Route
                      path="/detail/trouble-connecting"
                      element={<Navigate to="/" replace />}
                    />
                    <Route
                      path="/detail/:detailId"
                      element={<DetailPageContainer />}
                    />
                  </>
                )}
                {isWeb3 && (
                  <>
                    <Route path="/" element={<WebThree />} />
                    <Route
                      path="/detail/:detailId"
                      element={<DetailPageContainer />}
                    />
                  </>
                )}
              </Routes>
            </AnalyticsProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </WagmiProvider>
    </Provider>
  );
}

export default App;
