export enum NavId {
  Section1 = "Home",
  Section2 = "Our Story",
  Section3 = "Mission & Vision",
  Section4 = "Scriber Wearable",
  Section5 = "AI Agents & Intelligence",
  Section6 = "Sentient AI Framework",
  Section7 = "Cognitive Mastery Tools",
  Section8 = "Voice Evolution Tech",
  Section9 = "SCRB Tokenomics",
  Section10 = "Enterprise AI Solutions",
  Section11 = "Partner With Us",
  Section12 = "Meet the Team",
  Section13 = "Insights & Research",
  Section14 = "Null",
}
export enum NavLable {
  Section1 = "Home",
  Section2 = "Our Story",
  Section3 = "Mission & Vision",
  Section4 = "Scriber Wearable",
  Section5 = "AI Agents & Intelligence",
  Section6 = "Sentient AI Framework",
  Section7 = "Cognitive Mastery Tools",
  Section8 = "Voice Evolution Tech",
  Section9 = "SCRB Tokenomics",
  Section10 = "Enterprise AI Solutions",
  Section11 = "Partner With Us",
  Section12 = "Meet the Team",
  Section13 = "Insights & Research",
  Section14 = "Null",
}

export interface ILink {
  id: string;
  value: string;
}
