// DetailPage.js
import React, { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import buttonIcon from "assets/images/Listening Button.png";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/fon-section11-info.png";
import fon2 from "assets/images/fon-section11-info-mobile.png";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import close from "assets/images/close-icon.png";
import { Modal } from "components/modal";
import { CloseBlock } from "components/closemomileDetail";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage11: React.FC<DetailPageProps> = ({ handleClose }) => {
  const [translate, setTranslate] = useState(0);
  const [activeSection, setActiveSection] = useState<number>(0);
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const navigate = useNavigate();

  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section4)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);

  const handClickSection10 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/token-economy");
  };
  const handClickSection1 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/sentient-framework");
  };

  return (
    <div className={styles.section11}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        theme="black"
        logoVariant="noLogoText"
        blur
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        <img
          src={close}
          alt="img"
          className={styles.button}
          onClick={() => handleClose(NavId.Section11, 11)}
        />
        <div className={styles.content}>
          <div className={styles.block1}>
            {!isDesktop && (
              <CloseBlock
                id={NavId.Section11}
                index={11}
                handleClose={handleClose}
              />
            )}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="AI-Powered Productivity for Organizations"
                color={theme.colors.neutral[20]}
              />
              <div className={styles.p}>
                <img src={buttonIcon} alt="buttonIcon" />
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text="Tap to hear this page come to life."
                  color={theme.colors.neutral[30]}
                  styles={{ fontStyle: "italic", lineHeight: "28px" }}
                />
              </div>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber delivers a decentralized AI-learning protocol designed for corporations, universities, and developers looking to scale intelligence-driven learning solutions. By integrating real-time neurofeedback with machine learning, it enables organizations to refine knowledge delivery, optimize workforce training, and advance cognitive research."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Traditional learning models struggle to keep up with technological acceleration. Static content and one-size-fits-all programs limit innovation. Organizations that rely on adaptable, AI-driven intelligence systems gain a competitive advantage in skill development, decision-making, and knowledge retention."
                color={theme.colors.neutral[30]}
              />
            </div>
            <CustomText
              size={isDesktop ? 20 : 15}
              text="“The shift to intelligence-driven learning is already happening.”"
              color={theme.colors.neutral[30]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
              }}
            />
            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber merges neuroscience, adaptive AI, and blockchain to create a scalable, self-optimizing learning infrastructure. Cognitive performance tracking allows organizations to measure learning engagement with precision, refining processes based on real-time feedback. Developers leverage modular AI agents to build custom solutions, integrating them seamlessly into enterprise systems."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
          <div className={styles.line}></div>

          <div className={styles.block2}>
            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Workforce training, executive learning, and institutional research benefit from AI models that adapt to human cognition. Learning frameworks adjust dynamically, delivering knowledge in a way that enhances retention and application. Universities and research institutions integrate MindScriber’s intelligence protocol to explore new methods of cognitive adaptation and performance enhancement."
                color={theme.colors.neutral[30]}
              />
            </div>
            <CustomText
              size={isDesktop ? 20 : 15}
              text="“Technology evolves. Intelligence should evolve with it.”"
              color={theme.colors.neutral[30]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
                width: isDesktop ? undefined : "85%",
              }}
            />
            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Organizations access MindScriber’s decentralized learning tools through SCRB staking, which unlocks enterprise-level AI solutions without transactional limits. This model ensures full flexibility while maintaining continuous engagement with the protocol. The integration process is designed for seamless adoption, allowing corporations, universities, and developers to deploy adaptive AI frameworks that grow with their needs"
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="AI-driven cognitive learning is setting a new standard for knowledge systems. Enterprises that embrace intelligent adaptation are positioned to lead."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
        </div>
        <CustomText
          size={isDesktop ? 20 : 15}
          text="“Precision in learning leads to mastery in action.”"
          color={theme.colors.neutral[30]}
          styles={{
            fontStyle: "italic",
            textAlign: !isDesktop ? "center" : undefined,
            margin: "0 auto",
            width: isDesktop ? undefined : "80%",
          }}
        />
        <div className={styles.blockButtons}>
          <CustomButtonSection
            text="Back"
            colorScheme="green"
            onClick={handClickSection10}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
          <CustomButtonSection
            text="Forward"
            colorScheme="green"
            onClick={handClickSection1}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
        </div>
      </div>
      <img className={styles.fon} src={isDesktop ? fon : fon2} alt="fon" />
    </div>
  );
};
