import React, { useState, useEffect } from "react";

interface InstagramIconProps {
  heightFactor?: number; // Коэффициент масштабирования
  color?: string;
  width?: number;
  height?: number;
}

export const InstagramIcon: React.FC<InstagramIconProps> = ({
  width = 32,
  height = 32,
  heightFactor = 20, // Можно подстраивать коэффициент
  color = "#000",
}) => {
  const [size, setSize] = useState(32); // Начальный размер

  useEffect(() => {
    const updateSize = () => {
      const newSize = Math.min(
        40,
        Math.max(24, window.innerHeight / heightFactor)
      );
      setSize(newSize);
    };

    updateSize(); // Вызываем при монтировании
    window.addEventListener("resize", updateSize); // Слушаем изменения экрана
    return () => window.removeEventListener("resize", updateSize); // Убираем слушатель
  }, [heightFactor]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}px`}
      height={`${size * (32 / 40)}px`}
      viewBox="0 0 32 32"
      fill="none"
      style={{ display: "block" }}
    >
      <path fill={color} d="M22.668 11.334a2 2 0 1 0 0-4 2 2 0 0 0 0 4"></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.667"
        d="M21.333 4A6.67 6.67 0 0 1 28 10.667v10.666A6.67 6.67 0 0 1 21.333 28H10.667A6.67 6.67 0 0 1 4 21.333V10.667A6.67 6.67 0 0 1 10.667 4h10.666"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.667"
        d="M16.001 10.666a5.33 5.33 0 0 1 5.334 5.333A5.33 5.33 0 0 1 16 21.333a5.33 5.33 0 0 1-5.333-5.334 5.33 5.33 0 0 1 5.333-5.333"
      ></path>
    </svg>
  );
};
