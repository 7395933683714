export enum NavIdWeb3 {
  Section1 = "Framework",
  Section2 = "presale-now",
  Section3 = "Neuroadaptive Intelligence",
  Section4 = "Economy",
  Section5 = "Developing-system",
  Section6 = "IDO-Details",
  Section7 = "GreenToken",
  Section8 = "Night-Learning",
  Section9 = "Neural-Bridge",
  Section10 = "Developing-system",
  Section11 = "On-Chain",
  Section12 = "Roadmap",
  Section13 = "Team",
  Section14 = "FAQ",
  Section15 = "Form",
}

export enum NavLableWeb3 {
  Section1 = "Framework",
  Section2 = "presale-now",
  Section3 = "Neuroadaptive Intelligence",
  Section4 = "Economy",
  Section5 = "Developing-system",
  Section6 = "IDO-Details",
  Section7 = "GreenToken",
  Section8 = "Night-Learning",
  Section9 = "Neural-Bridge",
  Section10 = "Developing-system",
  Section11 = "On-Chain",
  Section12 = "Roadmap",
  Section13 = "Team",
  Section14 = "FAQ",
  Section15 = "Form",
}
