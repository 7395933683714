import styles from "./selectableCard.module.css";

interface SelectableCardProps {
  text: string;
  icon: string;
  onClick: () => void;
  isSelected: boolean;
  textSize?: string;
  lineHeight?: string;
}

export const SelectableCard: React.FC<SelectableCardProps> = ({
  text,
  icon,
  onClick,
  isSelected,
  textSize,
  lineHeight,
}) => {
  return (
    <div
      className={`${styles.card} ${isSelected ? styles.selected : ""}`}
      onClick={onClick}
    >
      <img src={icon} alt={text} className={styles.icon} />
      <span
        className={styles.text}
        style={{ fontSize: textSize, lineHeight: lineHeight || "normal" }}
      >
        {text}
      </span>
    </div>
  );
};
