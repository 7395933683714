import { useDispatch, useSelector } from "react-redux";
import { closeModal, RootState } from "store";
import styles from "./styles.module.css";
import { ILink } from "types/navigation";
import { LINKS, LINKS_DESKTOP } from "constants/links";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDeviceType } from "hooks";
import logo from "assets/images/logo-black.png";
import close from "assets/images/Close.png";
import { BurgerBlock } from "./burger-block-modal";
import { LinkBlock } from "./link-social";
import { useNavigate } from "react-router-dom";

interface IProps {
  setTranslate: Dispatch<SetStateAction<number>>;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

export const Modal = ({ setTranslate, setActiveSection }: IProps) => {
  const { device } = useDeviceType();
  const dispatch = useDispatch();
  const { isOpen, modalType } = useSelector((state: RootState) => state.modal);
  const [exiting, setExiting] = useState(false);
  const [active, setActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      setExiting(false);
      requestAnimationFrame(() => {
        setActive(true);
      });
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setActive(false);
    setExiting(true);
    setTimeout(() => {
      dispatch(closeModal());
    }, 500);
  }, [dispatch]);

  const navigateToSection = useCallback(
    (sectionId: string, sectionNumber: number) => {
      setActive(false);
      setExiting(true);
      setTimeout(() => {
        const currentHost = window.location.hostname;
        const currentPath = window.location.pathname;

        if (currentHost === "web3.mindscriber.com") {
          window.location.href = `https://mindscriber.com#${sectionId}`;
        } else {
          dispatch(closeModal());

          if (currentPath !== "/") {
            navigate("/", {
              replace: true,
              state: {
                activeId: sectionId,
                activeSectionNumber: sectionNumber,
              },
            });

            setTimeout(() => {
              if (device === "desktop") {
                document
                  .getElementById(sectionId)
                  ?.scrollIntoView({ behavior: "smooth" });
              } else {
                const sectionHeight = window.innerHeight;
                setActiveSection(sectionNumber);
                setTranslate(sectionNumber * sectionHeight);
              }
            }, 250);
          } else {
            if (device === "desktop") {
              setTimeout(() => {
                document
                  .getElementById(sectionId)
                  ?.scrollIntoView({ behavior: "smooth" });
              }, 100);
            } else {
              const sectionHeight = window.innerHeight;
              setActiveSection(sectionNumber);
              setTranslate(sectionNumber * sectionHeight);
            }
          }
        }
      }, 500);
    },
    [dispatch, device, setActiveSection, setTranslate, navigate]
  );

  if (!isOpen) return null;

  const renderLinks = () => {
    if (device === "mobile") {
      return LINKS.map((link: ILink, index) => {
        if (link.id === "Null") return null;

        return (
          <li
            key={link.value}
            onClick={() => navigateToSection(link.id, index)}
          >
            {link.value}
          </li>
        );
      }).filter(Boolean);
    } else {
      return LINKS_DESKTOP.map((link: ILink, index) => (
        <li key={link.value} onClick={() => navigateToSection(link.id, index)}>
          {link.value}
        </li>
      ));
    }
  };

  let content;
  switch (modalType) {
    case "burger":
      content = <BurgerBlock />;
      break;
    case "linkSocial":
      content = <LinkBlock />;
      break;
    default:
      content = renderLinks();
      break;
  }

  return (
    <div className={styles.container}>
      <ul
        className={`${styles.modalContent} ${active ? styles.active : ""} ${
          exiting ? styles.exit : ""
        }`}
      >
        <div className={styles.headerModal}>
          <img src={logo} alt="logo" style={{ width: "38px" }} />
          <img
            src={close}
            alt="close"
            onClick={handleClose}
            style={{ width: "32px" }}
          />
        </div>
        {content}
      </ul>
      <div
        className={`${styles.blur} ${active ? styles.active : ""} ${
          exiting ? styles.exit : ""
        }`}
        onClick={handleClose}
      />
    </div>
  );
};
