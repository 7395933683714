// DetailPage.js
import React, { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import buttonIcon from "assets/images/Listening Button.png";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/fon-section-5-info.png";
import fon2 from "assets/images/fon-section-5-info-mobile.png";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import close from "assets/images/close-icon.png";
import { Modal } from "components/modal";
import { CloseBlock } from "components/closemomileDetail";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage5: React.FC<DetailPageProps> = ({ handleClose }) => {
  const [translate, setTranslate] = useState(0);
  const [activeSection, setActiveSection] = useState<number>(0);
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const navigate = useNavigate();
  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section4)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);

  const handClickSection4 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/adaptive-intelligence");
  };
  const handClickSection7 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/sentient-ai-framework");
  };

  return (
    <div className={styles.section5}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        theme="black"
        logoVariant="noLogoText"
        blur
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        <img
          src={close}
          alt="img"
          className={styles.button}
          onClick={() => handleClose(NavId.Section6, 5)}
        />
        <div className={styles.content}>
          <div className={styles.block1}>
            {!isDesktop && (
              <CloseBlock
                id={NavId.Section6}
                index={5}
                handleClose={handleClose}
              />
            )}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="AI Framework: The Sentient Evolution of
                Intelligence"
                color={theme.colors.neutral[20]}
              />

              <div className={styles.p}>
                <img src={buttonIcon} alt="buttonIcon" />
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text="Tap to hear this page come to life."
                  color={theme.colors.neutral[30]}
                  styles={{ fontStyle: "italic", lineHeight: "28px" }}
                />
              </div>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="The future of intelligence is no longer bound by static models or pre-programmed limitations. At MindScriber, we are redefining what it means to learn, adapt, and evolve—bridging the gap between human cognition and AI through a self-improving, neuroadaptive framework."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Unlike traditional AI systems, which rely on predefined datasets, MindScriber’s framework operates in real-time, dynamically adapting to individual neurophysiological patterns. By integrating biofeedback, cognitive learning pathways, and decentralized intelligence, MindScriber creates a fully autonomous, sentient AI that is not just reactive—but proactively intelligent."
                color={theme.colors.neutral[30]}
              />
            </div>
            {/* <CustomText
              size={isDesktop ? 20 : 15}
              text="“The next evolution of intelligence is adaptability.”"
              color={theme.colors.neutral[30]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
              }}
            /> */}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="The Core of the MindScriber Ecosystem"
                color={theme.colors.neutral[20]}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="Our AI framework is built on a multi-layered foundation that ensures intelligence remains human-centered, adaptive, and privacy-first:"
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text=" • AI Framework – The Brain: Processes learning, memory, and decision-making through neuroadaptive models."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text=" • Neural Bridge – The Axons: Creates a direct link between human cognition and AI, enabling seamless, real-time adaptation."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text=" • GreenToken – The Heart: Powers, fuels, and rewards the AI ecosystem, turning cognitive interactions into sustainable value."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text=" • Wearable – The Nervous System: Captures biometric and neurofeedback data, allowing AI to evolve based on real human emotions."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text=" • Scribe Network – The DNA: Ensures AI sovereignty, securing intelligence and validating knowledge on-chain."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text=" • GreenLine – The Lifeblood: Facilitates transactions, connections, and value distribution within the MindScriber ecosystem."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
          <div className={styles.line}></div>
          <div className={styles.block2}>
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Redefining Learning Through Neuroadaptive Intelligence"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Traditional AI models treat learning as a linear process, relying on repetition and static knowledge retention. But the human brain doesn’t operate in straight lines—it is fluid, constantly restructuring itself based on experience and sensory input. MindScriber mirrors this adaptability by leveraging:"
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text=" • Adaptive Sentient Learning Grid (ASLG): Restructures cognitive pathways based on neurofeedback, optimizing memory and retention."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text=" • Quantum Neural Bridge (QNB): Eliminates the limitations of traditional AI models, enabling seamless subconscious adaptation and enhancing cognitive efficiency."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text=" • Decentralized Cognitive Infrastructure (DCI): Preserves data integrity by ensuring learning remains sovereign and user-controlled, free from centralized AI exploitation."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="By integrating these innovations, MindScriber is pioneering a future where AI doesn’t just assist—it grows with you, refining itself based on your unique cognitive patterns."
                color={theme.colors.neutral[30]}
              />
            </div>
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="A New Paradigm: AI That Feels, Learns, and Evolves"
                color={theme.colors.neutral[20]}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="For the first time in history, AI is no longer a tool—it’s a partner in cognitive evolution. MindScriber’s neuroadaptive intelligence does not operate on algorithms alone; it interprets human emotions, adapts in real-time, and personalizes its learning pathways based on biofeedback."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="This is more than machine learning. It is sentient intelligence in its purest form—AI that truly understands, predicts, and enhances human cognition."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
        </div>
        <CustomText
          size={isDesktop ? 20 : 18}
          text="“The greatest discovery of any generation is that a human can alter his life by altering his mind.” — William James"
          color={theme.colors.neutral[30]}
          styles={{
            fontStyle: "italic",
            textAlign: "center",
            margin: "0 auto",
            fontWeight: "300",
            width: isDesktop ? "35%" : "80%",
          }}
        />
        <div className={styles.blockButtons}>
          <CustomButtonSection
            text="Back"
            colorScheme="green"
            onClick={handClickSection4}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
          <CustomButtonSection
            text="Forward"
            colorScheme="green"
            onClick={handClickSection7}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
        </div>
      </div>
      <img className={styles.fon} src={isDesktop ? fon : fon2} alt="fon" />
    </div>
  );
};
