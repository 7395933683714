import { useRef, ReactNode, Children } from "react";
import styles from "./styles.module.css";

interface WebThreeScrollableContainerProps {
  children: ReactNode;
}

export const WebThreeScrollableContainer = ({
  children,
}: WebThreeScrollableContainerProps) => {
  const scrollbarRef = useRef<HTMLDivElement | null>(null);
  const thumbRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={styles.container}>
      <div className={styles.content} style={{ gap: 0 }}>
        {Children.map(children, (child, index) => (
          <div className={styles.section} key={index}>
            {child}
          </div>
        ))}
      </div>
      <div ref={scrollbarRef} className={styles.scrollbar}>
        <div ref={thumbRef} className={styles.scrollbarThumb} />
      </div>
    </div>
  );
};
