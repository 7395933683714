import React from "react";
import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { theme } from "constants/theme";
import { useDeviceType } from "hooks";

interface InfoItemProps {
  icon: string;
  title: string;
  description: string;
}

export const InfoItem: React.FC<InfoItemProps> = ({
  icon,
  title,
  description,
}) => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  return (
    <div className={styles.infoItem}>
      <div className={styles.iconWrapper}>
        <img src={icon} alt="Icon" className={styles.icon} />
        <CustomHeadline
          size={isDesktop ? 20 : 14}
          text={title}
          color={theme.colors.neutral[0]}
          styles={{
            fontWeight: 500,
            lineHeight: isDesktop ? "20" : "14px",
          }}
        />
      </div>
      <CustomText
        size={isDesktop ? 13 : 12}
        text={description}
        color={theme.colors.neutral[0]}
        styles={{
          fontStyle: "italic",
          lineHeight: "12px",
          fontWeight: 400,
        }}
      />
    </div>
  );
};
