import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import {
  IconCustom1,
  IconCustom2,
  IconCustom3,
  IconCustom4,
  IconCustom5,
} from "web3-landing-page/assets-web3/icons";

export const Section12Web3 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.centuries}>
          <CustomHeadline
            size={isDesktop ? 60 : 24}
            text="Roadmap"
            color={
              isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[20]
            }
            styles={{
              fontWeight: 600,
              lineHeight: isDesktop ? "54px" : "26px",
              textAlign: isDesktop ? "left" : "center",
            }}
          />
          <CustomText
            size={isDesktop ? 24 : 19}
            text="MindScriber’s Initial Decentralized Offering (IDO) powers the ecosystem."
            color={isDesktop ? theme.colors.neutral[30] : theme.colors.neutral[20]}
            styles={{
              textAlign: isDesktop ? "left" : "center",
              margin: isDesktop ? "0" : "0 auto",
              lineHeight: isDesktop ? "28px" : "22px",
              fontWeight: 400,
            }}
          />
        </div>
        <div className={styles.centuries}>
          <div className={styles.infoBlock}>
            <div className={styles.icon}>
              <IconCustom1 />
            </div>
            <div className={styles.blockText}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber App Launch"
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 600,
                }}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="AI-powered neuroadaptive learning with voice cloning in five Indian languages."
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 500,
                }}
              />
            </div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.icon}>
              <IconCustom2 />
            </div>
            <div className={styles.blockText}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Wearable Neural Bridge"
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 600,
                }}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Launch of the non-invasive biofeedback device for research and corporate use."
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 500,
                }}
              />
            </div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.icon}>
              <IconCustom3 />
            </div>
            <div className={styles.blockText}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="AI Agent Framework"
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 600,
                }}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Developer API for neuroadaptive AI in education, healthcare, and enterprise."
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 500,
                }}
              />
            </div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.icon}>
              <IconCustom4 />
            </div>
            <div className={styles.blockText}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Global Expansion"
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 600,
                }}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Scaling SCRB-powered AI in the U.S. and EU markets."
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 500,
                }}
              />
            </div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.icon}>
              <IconCustom5 />
            </div>
            <div className={styles.blockText}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Advanced AI Research"
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 600,
                }}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Enhancing Quantum Neural Bridge (QNB) for cognitive and mental health applications."
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 500,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
