import React, { useState } from "react";
import styles from "./styles.module.css";
import { CustomText } from "components/ui";
import { theme } from "constants/theme";
import { useDeviceType } from "hooks";
import { CustomButtonSection } from "components/custom-button-section";
import { db } from "config/firebase";
import { collection, addDoc } from "firebase/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

export const ContactFormBlock1: React.FC = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  const [submitStatus, setSubmitStatus] = useState<{
    success: boolean | null;
    message: string;
  }>({ success: null, message: "" });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await addDoc(collection(db, "reachus"), {
          ...values,
          createdAt: new Date(),
        });
        resetForm();
        setSubmitStatus({
          success: true,
          message: "Your message is on its way.",
        });
      } catch (error: any) {
        console.error("Error saving form data to Firestore: ", error);
        setSubmitStatus({
          success: false,
          message: error.message || "Message not sent. Try again.",
        });
      }
    },
  });

  const isButtonDisabled = !(formik.isValid && formik.dirty);

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <label className={styles.label}>
        <CustomText
          size={isDesktop ? 18 : 14}
          text="My name is"
          color={theme.colors.neutral[20]}
        />
      </label>
      <input
        type="text"
        name="name"
        placeholder="Your name"
        value={formik.values.name}
        onChange={formik.handleChange}
        className={styles.input}
      />

      <label className={styles.label}>
        <CustomText
          size={isDesktop ? 18 : 14}
          text="Reach me at"
          color={theme.colors.neutral[20]}
        />
      </label>
      <input
        type="email"
        name="email"
        placeholder="Your@email.com"
        value={formik.values.email}
        onChange={formik.handleChange}
        className={styles.input}
      />

      <label className={styles.label}>
        <CustomText
          size={isDesktop ? 18 : 14}
          text="Message"
          color={theme.colors.neutral[20]}
        />
      </label>
      <textarea
        name="message"
        placeholder="Start typing here..."
        value={formik.values.message}
        onChange={formik.handleChange}
        className={styles.textarea}
        style={{ height: isDesktop ? "3dvw" : "36dvw" }}
      />
      <CustomButtonSection
        text="Submit"
        colorScheme="green"
        type="submit"
        onClick={() => formik.handleSubmit()}
        disabled={isButtonDisabled}
        style={{
          color: theme.colors.neutral[20],
          alignSelf: isDesktop ? "" : "center",
          marginBottom: isDesktop ? "0.75dvw" : "10dvw",
        }}
      />
    </form>
  );
};
