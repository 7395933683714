import * as React from "react";

interface IconProps {
  width?: number | string; // Опциональный пропс для ширины
}

export const Icon4Section7: React.FC<IconProps> = ({ width = 52 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="36"
    fill="none"
    viewBox="0 0 52 36"
  >
    <g clipPath="url(#clip0_410_12611)">
      <path
        fill="#fff"
        d="M34.387 1.292H17.613C8.35 1.292.84 8.858.84 18.191c0 9.335 7.51 16.902 16.774 16.902h16.774c9.264 0 16.774-7.567 16.774-16.902 0-9.334-7.51-16.9-16.774-16.9"
      ></path>
      <path
        stroke="#00CB94"
        d="M34.387 1.292H17.613C8.35 1.292.84 8.858.84 18.191c0 9.335 7.51 16.902 16.774 16.902h16.774c9.264 0 16.774-7.567 16.774-16.902 0-9.334-7.51-16.9-16.774-16.9Z"
      ></path>
      <path
        fill="#00CB94"
        d="M20.96 26.087c-3.279 0-5.419-1.813-5.621-4.377l-.01-.128h2.278l.01.107c.15 1.405 1.438 2.392 3.344 2.392 1.874 0 3.14-1.062 3.14-2.585v-.022c0-1.748-1.256-2.725-3.3-2.725h-1.874v-1.866h1.8c1.756 0 2.949-1.041 2.949-2.479v-.021c0-1.502-1-2.382-2.736-2.382-1.704 0-2.897.912-3.046 2.371l-.01.107h-2.236l.01-.117c.235-2.607 2.279-4.335 5.281-4.335 3.056 0 5.058 1.653 5.058 4.045v.021c0 1.92-1.363 3.208-3.237 3.605v.054c2.258.204 3.77 1.577 3.77 3.744v.021c0 2.66-2.311 4.57-5.57 4.57m6.871-.29 3.844-5.803-3.876-5.847h2.673l2.491 4.162h.181l2.47-4.162h2.556l-3.823 5.75 3.865 5.9h-2.63l-2.534-4.205h-.17l-2.534 4.206z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_410_12611">
        <path fill="#fff" d="M0 .446h52v35.492H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
