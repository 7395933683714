import React from "react";
import { useDeviceType } from "hooks";

interface IconProps extends React.SVGProps<SVGSVGElement> {}

export const IconCustom2: React.FC<IconProps> = (props) => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  
  return (
    <svg
      width="59"
      height="60"
      viewBox="0 0 59 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props} // Позволяет переопределять класс, стили и т.д.
    >
      <path d="M8.47297 38.7294L13.5212 49.6665C14.341 51.305 18.4378 54.5832 28.2712 54.5832C38.1045 54.5832 42.4066 49.6665 42.4066 49.6665L46.6228 41.8398C47.1105 40.9346 47.5553 40.3561 47.7867 39.3543C48.1545 37.7629 47.3031 35.9015 46.4384 34.5159C46.2172 34.1613 45.9965 33.8068 45.8026 33.4934C45.1741 32.4778 45.3247 32.6171 44.4868 31.3338C44.0783 30.708 43.239 29.992 42.5712 29.6566C41.2296 28.9829 40.4233 28.0607 39.8355 26.6794L39.7902 26.5729C39.4867 25.8591 39.3337 25.0917 39.3337 24.316V9.30072C39.3337 8.38868 38.9711 7.51351 38.3257 6.86943C37.9269 6.47037 37.3949 6.23243 36.8315 6.20113C36.2682 6.16983 35.7131 6.34739 35.2725 6.6998L35.0193 6.90262C34.6342 7.21067 34.3149 7.59283 34.0801 8.02649C33.8453 8.46014 33.6999 8.93648 33.6525 9.42732L32.0201 26.2964C32.0048 26.4662 31.9279 26.6245 31.8039 26.7415C31.68 26.8585 31.5174 26.9261 31.3471 26.9315C31.1767 26.937 31.0102 26.8799 30.879 26.771C30.7478 26.6622 30.661 26.5091 30.6349 26.3407L27.8827 9.27982C27.7372 8.37638 27.261 7.55931 26.5466 6.98743L26.082 6.61622C25.67 6.2866 25.1509 6.12053 24.624 6.14978C24.0972 6.17904 23.5997 6.40157 23.2267 6.77478C22.8836 7.11843 22.6228 7.53519 22.4635 7.99389C22.3043 8.4526 22.2509 8.94137 22.3072 9.42364L24.285 26.2276C24.3122 26.4578 24.2738 26.691 24.1742 26.9004C24.0746 27.1097 23.9178 27.2866 23.7219 27.4106C23.526 27.5346 23.2991 27.6007 23.0673 27.6013C22.8354 27.6018 22.6082 27.5368 22.4117 27.4137L21.5673 26.8852C20.9944 26.5273 20.3342 26.3337 19.6588 26.3255C18.9834 26.3173 18.3187 26.4947 17.7372 26.8385L10.9264 30.8628C9.61574 31.6373 8.63921 32.8706 8.18595 34.324C7.73268 35.7773 7.83496 37.3472 8.47297 38.7294Z" stroke={isDesktop ? "black" : "#00CB94"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};
