import { ILink, NavId, NavLable } from "types/navigation";

export const LINKS: ILink[] = [
  {
    id: NavId.Section1,
    value: NavLable.Section1,
  },
  {
    id: NavId.Section2,
    value: NavLable.Section2,
  },
  {
    id: NavId.Section3,
    value: NavLable.Section3,
  },
  {
    id: NavId.Section4,
    value: NavLable.Section4,
  },
  {
    id: NavId.Section5,
    value: NavLable.Section5,
  },
  {
    id: NavId.Section6,
    value: NavLable.Section6,
  },
  {
    id: NavId.Section14,
    value: NavLable.Section14,
  },
  {
    id: NavId.Section7,
    value: NavLable.Section7,
  },

  {
    id: NavId.Section8,
    value: NavLable.Section8,
  },

  {
    id: NavId.Section9,
    value: NavLable.Section9,
  },
  {
    id: NavId.Section10,
    value: NavLable.Section10,
  },
  {
    id: NavId.Section11,
    value: NavLable.Section11,
  },
  {
    id: NavId.Section14,
    value: NavLable.Section14,
  },
  {
    id: NavId.Section12,
    value: NavLable.Section12,
  },
];

export const LINKS_DESKTOP: ILink[] = [
  {
    id: NavId.Section1,
    value: NavLable.Section1,
  },
  {
    id: NavId.Section2,
    value: NavLable.Section2,
  },
  {
    id: NavId.Section3,
    value: NavLable.Section3,
  },
  {
    id: NavId.Section4,
    value: NavLable.Section4,
  },
  {
    id: NavId.Section5,
    value: NavLable.Section5,
  },
  {
    id: NavId.Section6,
    value: NavLable.Section6,
  },
  {
    id: NavId.Section7,
    value: NavLable.Section7,
  },

  {
    id: NavId.Section8,
    value: NavLable.Section8,
  },

  {
    id: NavId.Section9,
    value: NavLable.Section9,
  },
  {
    id: NavId.Section10,
    value: NavLable.Section10,
  },
  {
    id: NavId.Section11,
    value: NavLable.Section11,
  },
  {
    id: NavId.Section12,
    value: NavLable.Section12,
  },
];
