import React from "react";
import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "../../../constants/theme";

interface IProps {
  per: string;
  p: string;
  link: string;
  textLink: string;
}

const Card = ({ per, p, textLink, link }: IProps) => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  return (
    <div className={styles.cardContainer}>
      <CustomText
        text={per}
        size={isDesktop ? 100 : 54}
        color={theme.colors.green[90]}
        styles={{ fontFamily: "Poppins" }}
      />

      <p>
        <CustomText
          text={p}
          size={isDesktop ? 25 : 17}
          color={theme.colors.green[90]}
          styles={{
            textAlign: isDesktop ? "left" : "center",
            lineHeight: isDesktop ? 26 : 18 + "!impontant",
          }}
        />
      </p>
      <span>
        <CustomText
          text="Source:&nbsp;"
          size={isDesktop ? 18 : 12}
          color={theme.colors.neutral[30]}
          styles={{ display: "inline-block" }}
        />
        <a href={link} target="_blank">
          <CustomText
            text={textLink}
            size={isDesktop ? 18 : 12}
            color={theme.colors.neutral[30]}
            styles={{
              display: "inline",
            }}
          />
        </a>
      </span>
    </div>
  );
};

export default Card;
