// DetailPage.js
import React, { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import buttonIcon from "assets/images/Listening Button.png";
import icon from "assets/images/icon-section-1-info.png";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/fon-section3-info.png";
import fon2 from "assets/images/fon-section3-info-mobile.png";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import close from "assets/images/close-icon.png";
import { Modal } from "components/modal";
import closeMobile from "assets/images/material-symbols-light_arrow-back.png";
import { CloseBlock } from "components/closemomileDetail";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage3: React.FC<DetailPageProps> = ({ handleClose }) => {
  const [translate, setTranslate] = useState(0);

  const [activeSection, setActiveSection] = useState<number>(0);

  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  const navigate = useNavigate();

  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section1)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);
  const handClickSection2 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/neurodivergent-learning");
  };
  const handClickSection4 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/scriber wearable");
  };

  return (
    <div className={styles.section3}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        theme="black"
        logoVariant="noLogoText"
        blur
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        {isDesktop && (
          <img
            src={close}
            alt="img"
            className={styles.button}
            onClick={() => handleClose(NavId.Section3, 2)}
          />
        )}

        <div className={styles.content}>
          <div className={styles.block1}>
            {!isDesktop && (
              <CloseBlock
                id={NavId.Section3}
                index={2}
                handleClose={handleClose}
              />
            )}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="A Mission to Unlock a Billion Minds with Early Diagnosis"
                color={theme.colors.neutral[20]}
              />

              <div className={styles.p}>
                <img src={buttonIcon} alt="buttonIcon" />
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text="Tap to hear this page come to life."
                  color={theme.colors.neutral[30]}
                  styles={{ fontStyle: "italic", lineHeight: "28px" }}
                />
              </div>

              <CustomText
                size={isDesktop ? 20 : 15}
                text="The world has never been designed for minds that think differently. For centuries, neurodivergent individuals have been miscategorized, overlooked, or left to navigate a system built for a singular way of learning. It wasn’t a question of intelligence—it was a failure of understanding."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Imagine a child in a classroom, staring at the board, knowing the answers but struggling to express them. Their mind operates on a different wavelength—rich with creativity, insight, and untapped potential—yet they are told they are slow, unfocused, or not trying hard enough. This cycle plays out for millions every day. In that silence, brilliance is buried."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Only five percent of neurodivergent individuals worldwide receive a proper diagnosis and the tailored support they need. "
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="The rest are left to adapt to a world that wasn’t designed for them, often without ever realizing their true capabilities."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
          <div className={styles.line}></div>

          <div className={styles.block2}>
            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="This is why we exist."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber is breaking this cycle at scale. We are providing free AI-driven diagnostic tools to identify neurodivergence early—giving every individual clarity on their unique strengths. Our sentient AI framework is designed not only to recognize neurodivergence but to personalize and accelerate learning, ensuring that every mind has the opportunity to thrive"
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="This is not just about technology. This is about systemic change on a global scale."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="For too long, neurodivergence has been treated as a limitation. We see it differently. It is not a flaw—it is an evolutionary advantage. Some of the greatest minds in history—Einstein, Da Vinci, Tesla, Jobs—were neurodivergent. How many more remain undiscovered?"
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="We are building a world where every mind is seen, understood, and given the tools to realize its full potenial."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
        </div>
        <CustomText
          size={isDesktop ? 20 : 15}
          text="“If a child can’t learn the way we teach, maybe we should teach the way they learn.” — Ignacio Estrada"
          color={theme.colors.neutral[30]}
          styles={{
            fontStyle: "italic",
            textAlign: !isDesktop ? "center" : undefined,
            margin: "0 auto",
            width: isDesktop ? undefined : "85%",
            fontWeight: "300",
          }}
        />
        <div className={styles.blockButtons}>
          <CustomButtonSection
            text="Back"
            colorScheme="green"
            onClick={handClickSection2}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
          <CustomButtonSection
            text="Forward"
            colorScheme="green"
            onClick={handClickSection4}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
        </div>
      </div>
      <img className={styles.fon} src={isDesktop ? fon : fon2} alt="fon" />
    </div>
  );
};
