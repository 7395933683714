import styles from "./styles.module.css";
import Card from "./card";

export const WeOffer: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Card
          per="400%"
          p="memory retention boost – Night learning reshapes how we retain information."
          textLink="BMC Psychology"
          link="https://bmcpsychology.biomedcentral.com/"
        />

        <Card
          per="50%"
          p="burnout reduction – AI automates learning, reducing cognitive overload."
          textLink="EdTech Magazine"
          link="https://edtechmagazine.com/"
        />
        <Card
          per="60%"
          p="higher engagement – Emotional AI fosters deeper learner connections."
          textLink="American Psychological Association (APA)"
          link="https://www.apa.org/"
        />
      </div>
    </div>
  );
};
