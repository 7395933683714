import * as React from "react";

interface IconProps {
  width?: number | string; // Опциональный пропс для ширины
}

export const Icon1Section7: React.FC<IconProps> = ({ width = 44 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="37"
    fill="none"
    viewBox="0 0 44 37"
  >
    <g clipPath="url(#clip0_410_12615)">
      <path
        fill="#00CB94"
        d="M26.23 0h-8.46C7.955 0 0 8.145 0 18.192s7.956 18.193 17.77 18.193h8.46c9.814 0 17.77-8.145 17.77-18.193C44 8.145 36.044 0 26.23 0"
      ></path>
      <path
        fill="#fff"
        d="M14.022 29.454q-.898 0-1.537-.665a2.18 2.18 0 0 1-.64-1.562V10.89q0-.898.64-1.563a2.06 2.06 0 0 1 1.537-.665h15.956q.897 0 1.536.665.64.666.64 1.563v16.336q0 .897-.64 1.562-.639.666-1.536.665zm0-2.227h15.956V10.89H14.02zm1.45-2.228h13.055l-4.352-5.94-3.263 4.455-2.176-2.97zm2.176-8.91q.604 0 1.027-.434a1.45 1.45 0 0 0 .423-1.052q0-.619-.423-1.051a1.38 1.38 0 0 0-1.027-.434q-.604 0-1.028.434a1.45 1.45 0 0 0-.423 1.051q0 .62.423 1.052.423.434 1.028.434"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_410_12615">
        <path fill="#fff" d="M0 0h44v36.385H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
