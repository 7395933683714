import styles from "./styles.module.css";
import { useDeviceType } from "hooks";

import { useNavigate } from "react-router-dom";
import { CardTeam } from "components/sections/section-12/block-2/card-team";
import IgarIcon from "../../assets-web3/image/igar.png";
import MarkIcon from "../../assets-web3/image/mark.png";
import Alexander from "../../assets-web3/image/alexander.png";
import Omer from "../../assets-web3/image/omer.png";
import Sachdev from "../../assets-web3/image/sachdev.png";
import Oleg from "../../assets-web3/image/oleg.png";
import Marta from "../../assets-web3/image/marta.png";
import Yevheniia from "../../assets-web3/image/yevheniia.png";
import { CustomHeadline } from "components/ui";
import { theme } from "constants/theme";

export const Section13Web3 = () => {
  const { device } = useDeviceType();
  const navigate = useNavigate();

  const isDesktop = device === "desktop";

  return (
    <>
      <div className={styles.container}>
        <CustomHeadline
          size={24}
          text="Team"
          color={theme.colors.neutral[0]}
          styles={{
            fontWeight: 600,
            lineHeight: "26px",
            textAlign: "center",
            // marginTop: "9.3dvw",
          }}
        />
        <div className={styles.teamBlock}>
          <CardTeam
            img={IgarIcon}
            p1="Igar Dyachenko"
            p2="Co-Founder, CEO"
            p3="4x Founder | AI & Neurotech Innovator"
            // p3=" A dyslexic visionary redefining learning tools for
            //     neurodivergent individuals."
          />
          <CardTeam
            img={MarkIcon}
            p1="Dr. Mark Litvak"
            p2="Chief Science Officer"
            p3="Cognitive AI & Neurofeedback Pioneer"
            // p3="A neuroscientist pushing cognitive performance breakthroughs."
          />
          <CardTeam
            img={Alexander}
            p1="Alexander Riftine"
            p2="Chief Algorithm Officer"
            p3="Expert in Health & Neuro AI"
            // p3="Specialist in integrating health monitoring and neurotech systems."
          />
          <CardTeam
            img={Omer}
            p1="Omer Cohen"
            p2="Chief Technology Officer"
            p3="Developer & AI Systems Architect"
            // p3="Full Stack developer advancing innovative learning tools.."
          />
          <CardTeam
            img={Sachdev}
            p1="Dr. Nisheta Sachdev"
            p2="CMO and Advisor"
            p3="Web3 Growth & Investor Relations"
            // p3="Marketing strategist fostering growth, innovation, and partnerships in web3."
          />
          {/* <CardTeam
            img={Oleg}
            p1="Oleg Kryvak "
            p2="Lead Mobile Developer"
            p3="Specialist in mobile ecosystems for neurodivergent tools."
          /> */}
          <CardTeam
            img={Marta}
            p1="Marta Haievyshyn"
            p2="Chief Design Officer"
            p3="UX leader crafting intuitive designs."
            // p3="UX leader crafting intuitive, learner-friendly designs."
          />
          {/* <CardTeam
            img={Yevheniia}
            p1="Yevheniia Yefymova"
            p2="Frontend Developer"
            p3="Expert in HTML, CSS, JS, React | Performance & Scalable Solutions"
          /> */}
        </div>
      </div>
    </>
  );
};
