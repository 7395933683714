import styles from "./styles.module.css";
import { useDeviceType } from "hooks";
import { theme } from "../../../constants/theme";
import { CustomHeadline, CustomText } from "components/ui";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/fon-section-4.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import AudioOn from "assets/svg/audio-on";
import AudioOff from "assets/svg/audio-off";
import { storage, ref, getDownloadURL } from "../../../config/firebase";
import fallbackImage from "../../../assets/images/fon-section-4-mobile.png";

export const TechFramework: React.FC = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const [isAudioOn, setIsAudioOn] = useState(true);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoUrlMp4, setVideoUrlMp4] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchVideoUrls = async () => {
      try {
        const timeout = setTimeout(() => {
          setVideoError(true);
          console.error("Video fetch timeout: Falling back to image.");
        }, 5000);

        const videoFile = device === "mobile" ? "3(400).webm" : "3.webm";
        const mp4File = "3.mp4";

        try {
          const webmUrl = await getDownloadURL(ref(storage, videoFile));

          const canPlayWebM = document
            .createElement("video")
            .canPlayType("video/webm");
          if (canPlayWebM) {
            setVideoUrl(webmUrl);
          } else {
            throw new Error("WebM not supported");
          }
        } catch (error) {
          console.warn("WebM not found or not supported, using MP4 fallback.");

          const mp4Url = await getDownloadURL(ref(storage, mp4File));
          setVideoUrlMp4(mp4Url);
        }

        clearTimeout(timeout);
      } catch (error) {
        console.error("Error fetching video:", error);
        setVideoError(true);
      }
    };

    fetchVideoUrls();
  }, [device]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute("playsinline", "");
      videoRef.current.setAttribute("muted", "");
      videoRef.current.play().catch(() => console.log("Autoplay blocked"));
    }
  }, [videoUrl]);

  const toggleAudio = () => {
    setIsAudioOn((prev) => !prev);
  };

  const navigate = useNavigate();

  const handleExploreClick = () => {
    navigate("/detail/adaptive-intelligence");
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  const [videoError, setVideoError] = useState(false);

  return (
    <div
      className={`${styles.container} ${videoError ? styles.fallbackBg : ""}`}
    >
      {!isLoaded && (
        <img
          src={fallbackImage}
          alt="Video placeholder"
          className={styles.videoBg}
        />
      )}
      {!videoError && videoUrl && (
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          className={styles.videoBg}
          onError={() => setVideoError(true)}
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
          preload="auto"
          onLoadedData={() => setIsLoaded(true)}
          poster={fallbackImage}
        >
          <source src={videoUrl} type="video/webm" />
          {videoUrlMp4 && <source src={videoUrlMp4} type="video/mp4" />}
        </video>
      )}
      <div className={styles.blockContent}>
        <div className={styles.content}>
          <CustomHeadline
            size={isDesktop ? 55 : 24}
            text="Understanding AI Agents and Intelligence"
            color={
              isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[0]
            }
            styles={{ lineHeight: `${isDesktop ? 52 : 26}px` }}
          />
          <div className={styles.p}>
            <CustomText
              size={isDesktop ? 24 : 14}
              text="AI agents evolve—learning, adapting, and making decisions autonomously, bridging cognition with real-time intelligence."
              color={
                isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[0]
              }
              styles={{
                maxWidth: "450px",
                lineHeight: `${isDesktop ? 28 : 17}px`,
              }}
            />
          </div>
          <CustomButtonSection
            text="Discover More"
            colorScheme="green"
            onClick={handleExploreClick}
            style={{
              color: isDesktop
                ? theme.colors.neutral[20]
                : theme.colors.neutral[0],
              marginTop: isDesktop ? "2%" : "7%",
            }}
          />
        </div>
      </div>
      <img className={styles.image} src={fon} alt="fase" />
      {/* <div className={styles.imgAudio} onClick={toggleAudio}>
        {isAudioOn ? <AudioOn /> : <AudioOff />}
      </div> */}
    </div>
  );
};
