import React, { useCallback, useContext, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import close from "assets/images/close-icon.png";
import { Modal } from "components/modal";
import { CloseBlock } from "components/closemomileDetail";
import { ScrollContext } from "web3-landing-page";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage1Web3: React.FC<DetailPageProps> = () => {
  const { scrollToSection } = useContext(ScrollContext);
  const [translate, setTranslate] = useState(0);

  const [activeSection, setActiveSection] = useState<number>(0);

  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  const navigate = useNavigate();

  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section1)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);

  const handleClose = async (sectionId: string) => {
    navigate("/");
    await new Promise((resolve) => requestAnimationFrame(resolve));
    scrollToSection(sectionId);
  };

  return (
    <div className={styles.section3}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        theme="black"
        logoVariant="noLogoText"
        blur
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        {isDesktop && (
          <img
            src={close}
            alt="img"
            className={styles.button}
            onClick={() => handleClose("payment")}
          />
        )}

        <div className={styles.content}>
          <div className={styles.block1}>
            {!isDesktop && (
              <CloseBlock
                id={"payment"}
                index={1}
                handleClose={(id) => handleClose(id)}
              />
            )}
            <div className={styles.centuries}>
              <h2>Troubleshooting Web3 Wallet Connection Issues</h2>
              <CustomHeadline
                size={isDesktop ? 24 : 18}
                text="You're Connected!"
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: isDesktop ? "32px" : "26px",
                }}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="1. Restart your browser. If the issue persists, continue. "
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="2. Clear your browser’s cache and disable any VPN. Try again."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="3. Deactivate & reactivate your wallet extension. If that fails, reinstall the wallet (ensure your seed phrase is backed up)."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="4. Switch wallets. If using MetaMask, try Rabby Wallet, Trust Wallet, or another Web3-compatible wallet."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
          <div className={styles.line}></div>

          <div className={styles.block2}>
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 24 : 18}
                text="For Mobile"
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: isDesktop ? "32px" : "26px",
                  width: "100%",
                }}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="1. Use your wallet’s in-app browser. Copy and paste the website link into MetaMask or Trust Wallet’s built-in browser."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="2. Clear the app’s cache and retry the transaction."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="3. Try a different wallet app. SafePal, 1inch Wallet, or another Web3 wallet may work."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="4. Use a PC instead. Some issues resolve more easily on a desktop browser."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
