import { CustomHeadline, CustomText } from "components/ui";
import styles from "./styles.module.css";
import { useDeviceType } from "hooks";
import { CustomButtonSection } from "components/custom-button-section";
import { theme } from "../../../constants/theme";
import img1 from "assets/images/img1-section8.png";
import img2 from "assets/images/img2-section8.png";
import img3 from "assets/images/img3-section8.png";
import img4 from "assets/images/img4-section8..png";
import img5 from "assets/images/img5-section8..png";
import img6 from "assets/images/img6-section8..png";
import img7 from "assets/images/img7-section8.png";
import img8 from "assets/images/img8-section8.png";
import img9 from "assets/images/img9-section8.png";
import bgPlayer from "assets/images/fon-hover-section8.png";
import playIcon from "assets/images/play_circle-section8.png";

import { useNavigate } from "react-router-dom";

export const Team = () => {
  const { device } = useDeviceType();

  const isDesktop = device === "desktop";

  const navigate = useNavigate();
  const handleExploreClick = () => {
    navigate("/detail/voice-cloning-science");
  };

  return (
    <>
      <div className={styles.container}>
        <div className={`${styles.content} ${styles.left}`}>
          <CustomHeadline
            size={isDesktop ? 55 : 24}
            text="Voices That Resonate, Stories That Stay"
            color={theme.colors.neutral[20]}
            styles={{ lineHeight: `${isDesktop ? 52 : 26}px` }}
          />
          <div className={styles.p}>
            <CustomText
              size={isDesktop ? 24 : 14}
              text="Clone the voices that inspire you—celebrities, family, friends—bridging memory, learning, and deep emotional connection."
              color={theme.colors.neutral[20]}
              styles={{
                lineHeight: `${isDesktop ? 28 : 17}px`,
                // maxWidth: isDesktop ? undefined : "255px",
              }}
            />
          </div>
          <CustomButtonSection
            text="Discover More"
            colorScheme="green"
            onClick={handleExploreClick}
            style={{
              color: theme.colors.neutral[20],
              marginTop: isDesktop ? "2%" : "7%",
            }}
          />
        </div>

        <div className={`${styles.images} ${styles.right}`}>
          <div className={styles.containerImages}>
            <div className={styles.imageWrapper}>
              <img src={img1} alt="people" />
              <div className={styles.hoverOverlay}>
                <img
                  src={bgPlayer}
                  alt="player background"
                  className={styles.hoverBackground}
                />
                <img src={playIcon} alt="play" className={styles.playIcon} />
              </div>
            </div>

            <div className={styles.imageWrapper}>
              <img src={img2} alt="people" />
              <div className={styles.hoverOverlay}>
                <img
                  src={bgPlayer}
                  alt="player background"
                  className={styles.hoverBackground}
                />
                <img src={playIcon} alt="play" className={styles.playIcon} />
              </div>
            </div>

            <div className={styles.imageWrapper}>
              <img src={img3} alt="people" />
              <div className={styles.hoverOverlay}>
                <img
                  src={bgPlayer}
                  alt="player background"
                  className={styles.hoverBackground}
                />
                <img src={playIcon} alt="play" className={styles.playIcon} />
              </div>
            </div>

            <div className={styles.imageWrapper}>
              <img src={img4} alt="people" />
              <div className={styles.hoverOverlay}>
                <img
                  src={bgPlayer}
                  alt="player background"
                  className={styles.hoverBackground}
                />
                <img src={playIcon} alt="play" className={styles.playIcon} />
              </div>
            </div>

            <div className={styles.imageWrapper}>
              <img src={img5} alt="people" />
              <div className={styles.hoverOverlay}>
                <img
                  src={bgPlayer}
                  alt="player background"
                  className={styles.hoverBackground}
                />
                <img src={playIcon} alt="play" className={styles.playIcon} />
              </div>
            </div>
            {isDesktop ? (
              <>
                <div className={styles.imageWrapper}>
                  <img src={img6} alt="people" />
                  <div className={styles.hoverOverlay}>
                    <img
                      src={bgPlayer}
                      alt="player background"
                      className={styles.hoverBackground}
                    />
                    <img
                      src={playIcon}
                      alt="play"
                      className={styles.playIcon}
                    />
                  </div>
                </div>

                <div className={styles.imageWrapper}>
                  <img src={img7} alt="people" />
                  <div className={styles.hoverOverlay}>
                    <img
                      src={bgPlayer}
                      alt="player background"
                      className={styles.hoverBackground}
                    />
                    <img
                      src={playIcon}
                      alt="play"
                      className={styles.playIcon}
                    />
                  </div>
                </div>

                <div className={styles.imageWrapper}>
                  <img src={img8} alt="people" />
                  <div className={styles.hoverOverlay}>
                    <img
                      src={bgPlayer}
                      alt="player background"
                      className={styles.hoverBackground}
                    />
                    <img
                      src={playIcon}
                      alt="play"
                      className={styles.playIcon}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div className={styles.imageWrapper}>
              {" "}
              <img src={img9} alt="people" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
