import { SVGProps } from "react";

export const UsdtIcon = ({
  width = 19,
  height = 18,
  fill = "white", 
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2014_4854)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 18C4.52919 18 0.5 13.9708 0.5 9C0.5 4.02919 4.52919 0 9.5 0C14.4708 0 18.5 4.02919 18.5 9C18.5 13.9708 14.4708 18 9.5 18ZM10.5811 7.75856V6.42769H13.6265V4.39819H5.33469V6.42769H8.38006V7.758C5.90506 7.87162 4.04375 8.36212 4.04375 8.94938C4.04375 9.53662 5.90506 10.0266 8.38006 10.1407V14.4056H10.5811V10.1396C13.0522 10.026 14.909 9.53606 14.909 8.94938C14.909 8.36269 13.0522 7.87275 10.5811 7.75856ZM10.5811 9.77794V9.77681C10.5193 9.78131 10.2003 9.80044 9.48875 9.80044C8.92062 9.80044 8.52069 9.78356 8.38006 9.77681V9.7785C6.19306 9.68231 4.56069 9.3015 4.56069 8.84587C4.56069 8.39025 6.19306 8.01 8.38006 7.91213V9.39937C8.52294 9.4095 8.93244 9.43369 9.49831 9.43369C10.1772 9.43369 10.5176 9.40556 10.5811 9.39994V7.91325C12.7636 8.01056 14.3921 8.39137 14.3921 8.84587C14.3921 9.3015 12.7636 9.68119 10.5811 9.77794Z"
        fill={fill} 
      />
    </g>
    <defs>
      <clipPath id="clip0_2014_4854">
        <rect width="18" height="18" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);