import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import manImageBig from "../../assets-web3/image/big.png";
import manImageSmall from "../../assets-web3/image/small.png";
import icon1 from "../../assets-web3/image/iconoir_brain.png";
import icon2 from "../../assets-web3/image/streamline_bacteria-virus-cells-biology.png";
import icon3 from "../../assets-web3/image/line-md_heart.png";
import icon4 from "../../assets-web3/image/lets-icons_link-alt.png";
import icon5 from "../../assets-web3/image/solar_dna-broken.png";
import icon6 from "../../assets-web3/image/lets-icons_blood.png";
import { InfoItem } from "./card";
import { useEffect, useRef, useState } from "react";
import Slideshow from "components/animation-forbes";
import { storage, ref, getDownloadURL } from "../../../config/firebase";
import fallbackImage from "../../../assets/images/fon-section-4-mobile.png";

export const Section3Web3 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoError, setVideoError] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoUrlMp4, setVideoUrlMp4] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchVideoUrls = async () => {
      try {
        const timeout = setTimeout(() => {
          setVideoError(true);
          console.error("Video fetch timeout: Falling back to image.");
        }, 5000);

        const videoFile = device === "mobile" ? "3(400).webm" : "3.webm";
        const mp4File = "3.mp4";

        try {
          const webmUrl = await getDownloadURL(ref(storage, videoFile));

          const canPlayWebM = document
            .createElement("video")
            .canPlayType("video/webm");
          if (canPlayWebM) {
            setVideoUrl(webmUrl);
          } else {
            throw new Error("WebM not supported");
          }
        } catch (error) {
          console.warn("WebM not found or not supported, using MP4 fallback.");

          const mp4Url = await getDownloadURL(ref(storage, mp4File));
          setVideoUrlMp4(mp4Url);
        }

        clearTimeout(timeout);
      } catch (error) {
        console.error("Error fetching video:", error);
        setVideoError(true);
      }
    };

    fetchVideoUrls();
  }, [device]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute("playsinline", "");
      videoRef.current.setAttribute("muted", "");
      videoRef.current.play().catch(() => console.log("Autoplay blocked"));
    }
  }, [videoUrl]);

  return (
    <div
      className={`${styles.container} ${videoError ? styles.fallbackBg : ""}`}
    >
      {!isLoaded && (
        <img
          src={fallbackImage}
          alt="Video placeholder"
          className={styles.videoBg}
        />
      )}
      {!videoError && videoUrl && (
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          className={styles.videoBg}
          onError={() => setVideoError(true)}
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
          preload="auto"
        >
          <source src={videoUrl} type="video/webm" />
          {videoUrlMp4 && <source src={videoUrlMp4} type="video/mp4" />}
        </video>
      )}
      <div className={styles.content}>
        <div className={styles.centuries}>
          <CustomHeadline
            size={isDesktop ? 60 : 23}
            text="Neuroadaptive Intelligence Without Compromise"
            color={
              isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[0]
            }
            styles={{
              fontWeight: 600,
              lineHeight: isDesktop ? "54px" : "26px",
              textAlign: isDesktop ? "left" : "center",
              // width: "80%",
            }}
          />
          <CustomText
            size={isDesktop ? 24 : 18}
            text={
              isDesktop
                ? "MindScriber’s AI-driven cognitive ecosystem bridges the gap between human cognition and machine intelligence. Through neuroadaptive AI, we enable real-time interaction between the autonomic nervous system and decentralized intelligence, setting a new standard for cognitive augmentation."
                : "The foundation of sentient AI—powering all clones & replicas."
            }
            color={theme.colors.neutral[0]}
            styles={{
              textAlign: isDesktop ? "left" : "center",
              margin: isDesktop ? "" : "0 auto",
              lineHeight: isDesktop ? "38px" : "21px",
              fontWeight: 400,
              width: isDesktop ? "70%" : "",
            }}
          />
          {isDesktop && (
            <div className={styles.blockAnimation}>
              <CustomHeadline
                size={isDesktop ? 24 : 23}
                text="Backed by:"
                color={
                  isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[0]
                }
                styles={{
                  fontWeight: 400,
                  lineHeight: isDesktop ? "28px" : "26px",
                  textAlign: isDesktop ? "left" : "center",
                }}
              />
              <Slideshow variant="white2" />
            </div>
          )}
        </div>

        <div className={styles.blockInfo}>
          <img
            src={isDesktop ? manImageBig : manImageSmall}
            alt="Standing man"
            className={styles.manImage}
          />

          <div className={styles.blockText}>
            <InfoItem
              icon={icon1}
              title="AI Framework – The Brain"
              description="Processes learning, memory, & decisions."
            />
            <InfoItem
              icon={icon2}
              title="Neural Bridge – The Axons"
              description="Links human cognition with AI."
            />
            <InfoItem
              icon={icon3}
              title="GreenToken – The Heart"
              description="Powers, fuels & rewards the ecosystem."
            />
            <InfoItem
              icon={icon4}
              title="Wearable – The Nervous System"
              description="Captures biofeedback, enabling AI adaptation."
            />
            <InfoItem
              icon={icon5}
              title="Scribe Network – The DNA"
              description="Secures and verifies intelligence on-chain."
            />
            <InfoItem
              icon={icon6}
              title="GreenLine – The Lifeblood"
              description="Connects intelligence, transactions & value."
            />
          </div>
        </div>
      </div>
      {!isDesktop && (
        <div className={styles.blockAnimation}>
          <CustomHeadline
            size={isDesktop ? 24 : 16}
            text="Backed by:"
            color={
              isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[0]
            }
            styles={{
              fontWeight: 400,
              lineHeight: isDesktop ? "28px" : "23px",
              textAlign: "left",
              // width: "80%",
            }}
          />
          <Slideshow variant="white2" />
        </div>
      )}
    </div>
  );
};
