import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { CustomButtonSection } from "components/custom-button-section";

import { ScrollContext } from "web3-landing-page";
import { useContext } from "react";
import { CardSection11 } from "./card";
import Slideshow from "components/animation-forbes";

export const Section11Web3 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const { scrollToSection } = useContext(ScrollContext);
  const handleOurMissionClick = () => {
    window.location.href = "https://mindscriber.com/detail/early-diagnosis";
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.centuries}>
          <CustomHeadline
            size={isDesktop ? 60 : 24}
            text="On-Chain Intelligence That Adapts & Evolves."
            color={theme.colors.neutral[0]}
            styles={{
              fontWeight: 600,
              lineHeight: isDesktop ? "53px" : "26px",
              textAlign: isDesktop ? "left" : "center",
            }}
          />
          <CustomText
            size={isDesktop ? 24 : 18}
            text={
              isDesktop
                ? "MindScriber’s blockchain is the foundation for decentralized intelligence—ensuring sovereignty, security, and ethical AI evolution. Human-powered AI drives adaptation through Proof of Knowledge Consensus Algorithm (POKCA), creating a self-learning, privacy-first intelligence network."
                : "MindScriber’s blockchain is the foundation for all serious replicas, clones, and angel networks, ensuring real-time, sovereign, human-powered AI adaptation through Proof of Knowledge Consensus Algorithm (POKCA)."
            }
            color={theme.colors.neutral[0]}
            styles={{
              width: isDesktop ? "75%" : "",
              textAlign: isDesktop ? "left" : "center",
              margin: isDesktop ? "" : "0 auto",
              lineHeight: isDesktop ? "28px" : "22px",
              fontWeight: 400,
            }}
          />
        </div>
        {!isDesktop && <CardSection11 />}

        <div className={styles.blockBotton}>
          {isDesktop && (
            <CustomButtonSection
              text="Our Mission"
              colorScheme="green"
              onClick={handleOurMissionClick}
              style={{
                color: theme.colors.neutral[0],
                marginTop: isDesktop ? "" : "1.35dvw",
              }}
            />
          )}
          <CustomButtonSection
            text="Get $SCRB"
            colorScheme="green"
            onClick={() => scrollToSection("payment")}
            style={{
              color: theme.colors.neutral[0],
              marginTop: isDesktop ? "" : "1.35dvw",
            }}
          />
        </div>
        {isDesktop && (
          <div className={styles.blockSnimation}>
            <CustomHeadline
              size={isDesktop ? 20 : 16}
              text="Supported by:"
              color={
                isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[0]
              }
              styles={{
                fontWeight: 400,
                lineHeight: isDesktop ? "28px" : "23px",
                textAlign: "left",
              }}
            />
            <Slideshow variant="white5" styleImg={{ height: "1.8dvw" }} />
          </div>
        )}
      </div>
      {isDesktop && (
        <div className={styles.block2Desktop}>
          <CardSection11 />
        </div>
      )}
    </div>
  );
};
