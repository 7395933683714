import * as React from "react";

interface TelegramSection12Props {
  width: string | number;
}

export const TelegramSection12: React.FC<TelegramSection12Props> = ({
  width,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="40"
    fill="none"
    viewBox="0 0 40 40"
  >
    <g clipPath="url(#clip0_106_2812)">
      <path
        fill="#fff"
        d="M20 0C8.96 0 0 8.96 0 20s8.96 20 20 20 20-8.96 20-20S31.04 0 20 0m9.28 13.6c-.3 3.16-1.6 10.84-2.26 14.38-.28 1.5-.84 2-1.36 2.06-1.16.1-2.04-.76-3.16-1.5-1.76-1.16-2.76-1.88-4.46-3-1.98-1.3-.7-2.02.44-3.18.3-.3 5.42-4.96 5.52-5.38a.4.4 0 0 0-.1-.36c-.12-.1-.28-.06-.42-.04-.18.04-2.98 1.9-8.44 5.58-.8.54-1.52.82-2.16.8-.72-.02-2.08-.4-3.1-.74-1.26-.4-2.24-.62-2.16-1.32.04-.36.54-.72 1.48-1.1 5.84-2.54 9.72-4.22 11.66-5.02 5.56-2.32 6.7-2.72 7.46-2.72.16 0 .54.04.78.24.2.16.26.38.28.54-.02.12.02.48 0 .76"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_106_2812">
        <path fill="#fff" d="M0 0h40v40H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
