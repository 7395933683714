import { CustomText } from "components/ui";
import styles from "./styles.module.css";
import icon1 from "../../assets-web3/image/grommet-icons_nodes.png";
import icon2 from "../../assets-web3/image/hugeicons_bio-energy.png";
import icon3 from "../../assets-web3/image/hugeicons_ai-security-02.png";
import icon4 from "../../assets-web3/image/tabler_building-bridge.png";
import icon5 from "../../assets-web3/image/arcticons_globeone.png";
import { theme } from "constants/theme";
import { useDeviceType } from "hooks";

export const CardSection11 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  return (
    <div className={styles.centuries2}>
      <div className={styles.infoBlock}>
        <img src={icon1} alt="img" />
        <CustomText
          size={isDesktop ? 24 : 15}
          text="Scribe Network → The backbone of AI sovereignty."
          color={theme.colors.neutral[0]}
          styles={{
            lineHeight: isDesktop ? "28px" : "15px",
            fontWeight: 500,
          }}
        />
      </div>
      <div className={styles.infoBlock}>
        <img src={icon2} alt="img" />
        <CustomText
          size={isDesktop ? 24 : 15}
          text="Proof of Knowledge (POKCA) → Transforms human emotional energy into monetary energy."
          color={theme.colors.neutral[0]}
          styles={{
            lineHeight: isDesktop ? "28px" : "15px",
            fontWeight: 500,
          }}
        />
      </div>
      <div className={styles.infoBlock}>
        <img src={icon3} alt="img" />
        <CustomText
          size={isDesktop ? 24 : 15}
          text="Zero-Knowledge AI → Personalization without surveillance."
          color={theme.colors.neutral[0]}
          styles={{
            lineHeight: isDesktop ? "28px" : "15px",
            fontWeight: 500,
          }}
        />
      </div>
      <div className={styles.infoBlock}>
        <img src={icon4} alt="img" />
        <CustomText
          size={isDesktop ? 24 : 15}
          text="Neural Bridge On-Chain → Real-time, privacy-first neurofeedback integration."
          color={theme.colors.neutral[0]}
          styles={{
            lineHeight: isDesktop ? "28px" : "15px",
            fontWeight: 500,
          }}
        />
      </div>
      <div className={styles.infoBlock}>
        <img src={icon5} alt="img" />
        <CustomText
          size={isDesktop ? 24 : 15}
          text="Essential for AI Evolution → All serious AI clones & replicas rely on Sentient Framework & Blockchain."
          color={theme.colors.neutral[0]}
          styles={{
            lineHeight: isDesktop ? "28px" : "15px",
            fontWeight: 500,
          }}
        />
      </div>
    </div>
  );
};
