import * as React from "react";
interface TelegramSection12Props {
  width: string | number;
}

export const InstSection12: React.FC<TelegramSection12Props> = ({ width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="40"
    fill="none"
    viewBox="0 0 40 40"
  >
    <g clipPath="url(#clip0_106_2814)">
      <path
        fill="#fff"
        d="M22.056 0c2.25.006 3.392.018 4.378.046l.388.014c.448.016.89.036 1.424.06 2.128.1 3.58.436 4.854.93 1.32.508 2.431 1.196 3.543 2.306A9.8 9.8 0 0 1 38.95 6.9c.494 1.274.83 2.726.93 4.856.024.532.044.974.06 1.424l.012.388c.03.984.042 2.126.046 4.376L40 19.436v2.62q.008 2.188-.046 4.375l-.012.388c-.016.45-.036.892-.06 1.424-.1 2.13-.44 3.58-.932 4.856a9.8 9.8 0 0 1-2.306 3.544A9.8 9.8 0 0 1 33.1 38.95c-1.273.494-2.725.83-4.853.93l-1.424.06-.388.012c-.986.028-2.128.042-4.378.046l-1.492.002h-2.618q-2.19.008-4.378-.046l-.387-.012q-.713-.027-1.424-.062c-2.128-.1-3.58-.436-4.856-.93a9.8 9.8 0 0 1-3.542-2.306A9.8 9.8 0 0 1 1.051 33.1c-.494-1.274-.83-2.726-.93-4.856l-.06-1.424-.01-.388a158 158 0 0 1-.05-4.376v-4.111q-.007-2.19.044-4.376l.014-.388c.016-.45.036-.892.06-1.424.1-2.13.436-3.58.93-4.856a9.8 9.8 0 0 1 2.312-3.544 9.8 9.8 0 0 1 3.54-2.306C8.177.556 9.627.22 11.757.12c.532-.024.976-.044 1.424-.06l.388-.012q2.187-.053 4.375-.046zM20 10a10 10 0 1 0 0 19.999 10 10 0 0 0 0-20m0 4a5.999 5.999 0 0 1 2.298 11.542 6 6 0 0 1-6.538-1.3A6 6 0 0 1 20.002 14m10.5-7a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_106_2814">
        <path fill="#fff" d="M0 0h40v40H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
