import { SVGProps } from "react";

export const BnbIcon = ({
  width = 19,
  height = 18,
  fill = "white",
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2014_4865)">
      <path
        d="M9.5 18C4.52919 18 0.5 13.9708 0.5 9C0.5 4.02919 4.52919 0 9.5 0C14.4708 0 18.5 4.02919 18.5 9C18.5 13.9708 14.4708 18 9.5 18ZM7.31525 8.10225L9.5 5.9175L11.6859 8.10338L12.9571 6.83212L9.5 3.375L6.044 6.831L7.31525 8.10225ZM3.875 9L5.14625 10.2712L6.4175 9L5.14625 7.72875L3.875 9ZM7.31525 9.89775L6.04231 11.1673L6.044 11.169L9.5 14.625L12.9571 11.1679L11.6859 9.89606L9.5 12.0825L7.31525 9.89775ZM12.5825 9L13.8538 10.2712L15.125 9L13.8538 7.72875L12.5825 9ZM10.7892 8.99887L9.5 7.70963L8.54656 8.6625L8.43687 8.77219L8.21131 8.99831L8.20906 9L8.21131 9.00169L9.49944 10.2904L10.7898 9.00056L10.7904 9L10.7892 8.99887Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_2014_4865">
        <rect width="18" height="18" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);