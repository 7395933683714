import React, { ReactNode, useCallback, useState } from "react";
import styles from "./styles.module.css";
import { Modal, Navbar } from "components";
import { WebThreeScrollableContainer } from "web3-landing-page/components/scrolling";

type ContainerWeb3Props = {
  children: ReactNode;
};

export const ContainerWeb3: React.FC<ContainerWeb3Props> = ({ children }) => {
  const [translate, setTranslate] = useState(0);
  const [activeSection, setActiveSection] = useState(0);
  const navigateToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <main className={styles.containerWeb3}>
      <WebThreeScrollableContainer>{children}</WebThreeScrollableContainer>
    </main>
  );
};
