import { useState, useEffect } from "react";
import styles from "./styles.module.css";

interface CountdownTimerProps {
  targetDate: string;
}

export const CountdownTimer: React.FC<CountdownTimerProps> = ({
  targetDate,
}) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const formatTime = (time: number) => {
    return String(time).padStart(2, "0");
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div className={styles.timerBlock}>
      <div>
        <span className={styles.time}>{formatTime(timeLeft.days)}</span>{" "}
        <span className={styles.textTime}>day</span>
      </div>
      :
      <div>
        <span className={styles.time}>{formatTime(timeLeft.hours)}</span>
        <span className={styles.textTime}> hour</span>
      </div>
      :
      <div>
        <span className={styles.time}>{formatTime(timeLeft.minutes)}</span>
        <span className={styles.textTime}>min</span>
      </div>
      :
      <div>
        <span className={styles.time}>{formatTime(timeLeft.seconds)}</span>
        <span className={styles.textTime}>sec</span>
      </div>
    </div>
  );
};
