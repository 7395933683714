import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import iconGreen from "../../assets-web3/image/akar-icons_question-green.png";
import logoFooter from "../../assets-web3/image/logo-footer.png";

export const Section14Web3 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const styleText = {
    width: "60%",
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.centuries}>
          <CustomHeadline
            size={isDesktop ? 60 : 24}
            text="FAQ"
            color={
              isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[20]
            }
            styles={{
              fontWeight: 600,
              lineHeight: isDesktop ? 54 : 26,
              textAlign: isDesktop ? "left" : "center",
            }}
          />
        </div>

        <div className={styles.centuries}>
          <div className={styles.infoBlock}>
            <img className={styles.icon} src={iconGreen} alt="icon" />
            <div className={styles.blockText}>
              <CustomText
                size={isDesktop ? 24 : 15}
                text="What is MindScriber?"
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 600,
                  ...styleText,
                }}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="The first AI-driven learning and intelligence-sharing platform, powered by SCRB – a tokenized ecosystem for cognitive evolution."
                color={isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 500,
                }}
              />
            </div>
          </div>

          <div className={styles.infoBlock}>
            <img className={styles.icon} src={iconGreen} alt="icon" />
            <div className={styles.blockText}>
              <CustomText
                size={isDesktop ? 24 : 15}
                text="How does the MindScriber wearable work"
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 600,
                  ...styleText,
                }}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="It reads autonomic nervous system signals, allowing AI to respond to attention, focus, and cognitive states."
                color={isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 500,
                }}
              />
            </div>
          </div>
          <div className={styles.infoBlock}>
            <img className={styles.icon} src={iconGreen} alt="icon" />
            <div className={styles.blockText}>
              <CustomText
                size={isDesktop ? 24 : 15}
                text="How is this different from traditional AI?"
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 600,
                  ...styleText,
                }}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Developer API for neuroadaptive AI in education, healthcare, and enterprise."
                color={isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 500,
                }}
              />
            </div>
          </div>
          <div className={styles.infoBlock}>
            <img className={styles.icon} src={iconGreen} alt="icon" />
            <div className={styles.blockText}>
              <CustomText
                size={isDesktop ? 24 : 15}
                text="Why is night learning revolutionary?"
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 600,
                  ...styleText,
                }}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber’s neurofeedback AI is clinically proven to increase memory retention by 400% – a game-changer for AI-driven self-improvement."
                color={isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 500,
                }}
              />
            </div>
          </div>
          <div className={styles.infoBlock}>
            <img className={styles.icon} src={iconGreen} alt="icon" />
            <div className={styles.blockText}>
              <CustomText
                size={isDesktop ? 24 : 15}
                text="What is SCRB?"
                color={theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 600,
                  ...styleText,
                }}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="SCRB is the foundation of AI-driven intelligence—governing, rewarding, and scaling neuroadaptive AI."
                color={isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[20]}
                styles={{
                  lineHeight: "15px",
                  fontWeight: 500,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
