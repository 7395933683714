import * as React from "react";

interface IconProps {
  width?: number | string; // Опциональный пропс для ширины
}

export const Icon2Section7: React.FC<IconProps> = ({ width = 44 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="37"
    fill="none"
    viewBox="0 0 44 37"
  >
    <g clipPath="url(#clip0_410_12607)">
      <path
        fill="#fff"
        d="M26.23.866H17.77C8.422.866.846 8.623.846 18.192s7.576 17.326 16.923 17.326h8.461c9.347 0 16.923-7.757 16.923-17.326S35.577.866 26.23.866"
      ></path>
      <path
        stroke="#00CB94"
        d="M26.23.866H17.77C8.422.866.846 8.623.846 18.192s7.576 17.326 16.923 17.326h8.461c9.347 0 16.923-7.757 16.923-17.326S35.577.866 26.23.866Z"
      ></path>
      <path
        fill="#00CB94"
        d="M30.928 20.435 29.71 18.92a5.8 5.8 0 0 0 1.52-1.961 5.55 5.55 0 0 0 .543-2.418q0-1.281-.542-2.423a5.8 5.8 0 0 0-1.52-1.967l1.216-1.488a8 8 0 0 1 2.023 2.638 7.4 7.4 0 0 1 .727 3.235 7.4 7.4 0 0 1-.74 3.242 8.3 8.3 0 0 1-2.01 2.657m-2.433-2.976-1.216-1.462q.343-.266.515-.65a1.97 1.97 0 0 0 0-1.608 1.7 1.7 0 0 0-.515-.638l1.216-1.488q.66.531 1.018 1.302.357.771.357 1.62a3.8 3.8 0 0 1-.357 1.622 3.6 3.6 0 0 1-1.018 1.302m-7.51.877q-1.585 0-2.697-1.103-1.11-1.102-1.11-2.694 0-1.59 1.11-2.723a3.64 3.64 0 0 1 2.697-1.133q1.587 0 2.698 1.133a3.75 3.75 0 0 1 1.11 2.723q0 1.592-1.11 2.694-1.11 1.103-2.698 1.103m-7.615 7.653v-2.578q0-.638.344-1.169.344-.53.899-.877a12.4 12.4 0 0 1 3.067-1.328 12.2 12.2 0 0 1 3.305-.452q1.693 0 3.306.452 1.613.451 3.067 1.328.53.345.886.877.357.531.357 1.17v2.577zm1.904-1.913h11.423v-.665a.34.34 0 0 0-.08-.22.8.8 0 0 0-.21-.178 9.8 9.8 0 0 0-2.619-1.143 10.7 10.7 0 0 0-2.803-.372q-1.427 0-2.802.372a9.8 9.8 0 0 0-2.618 1.143.8.8 0 0 0-.212.178.34.34 0 0 0-.079.22zm5.717-7.653q.788 0 1.343-.545.555-.544.555-1.344a1.88 1.88 0 0 0-.56-1.369 1.83 1.83 0 0 0-1.35-.569q-.787 0-1.342.57a1.9 1.9 0 0 0-.555 1.368q0 .799.56 1.344.561.545 1.35.545"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_410_12607">
        <path fill="#fff" d="M0 0h44v36.385H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
