// DetailPage.js
import React, { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import buttonIcon from "assets/images/Listening Button.png";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/fon-section9-info.png";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import close from "assets/images/close-icon.png";
import { Modal } from "components/modal";
import { CloseBlock } from "components/closemomileDetail";
import fonMob from "assets/images/iPhone 14 & 15 Pro Max - 1.png";
interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage9: React.FC<DetailPageProps> = ({ handleClose }) => {
  const [translate, setTranslate] = useState(0);
  const [activeSection, setActiveSection] = useState<number>(0);
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const navigate = useNavigate();

  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section4)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);

  const handClickSection8 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/voice-cloning-science");
  };
  const handClickSection10 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/token-economy");
  };

  return (
    <div className={styles.section11}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        theme="black"
        logoVariant="noLogoText"
        blur
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        <img
          src={close}
          alt="img"
          className={styles.button}
          onClick={() => handleClose(NavId.Section9, 9)}
        />
        <div className={styles.content}>
          <div className={styles.block1}>
            {!isDesktop && (
              <CloseBlock
                id={NavId.Section9}
                index={9}
                handleClose={handleClose}
              />
            )}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="GreenToken: AI Belongs to People"
                color={theme.colors.neutral[20]}
              />
              <div className={styles.p}>
                <img src={buttonIcon} alt="buttonIcon" />
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text="Tap to hear this page come to life."
                  color={theme.colors.neutral[30]}
                  styles={{ fontStyle: "italic", lineHeight: "28px" }}
                />
              </div>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber’s GreenToken ($SCRB) is the foundation of a sovereign AI economy—where human cognition fuels AI evolution, and intelligence itself becomes a tangible, tradable asset. Unlike traditional tokens designed for speculation, $SCRB operates on the Proof-of-Knowledge Consensus Algorithm (POKCA), validating knowledge, engagement, and neuroadaptive interactions rather than computational brute force."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="At its core, GreenToken ensures that AI remains decentralized, ethical, and adaptive—rewarding users for their contributions to intelligence rather than extracting value from them. The human potential mines the token on our blockchain, ensuring that learning, interaction, and knowledge creation translate into economic energy."
                color={theme.colors.neutral[30]}
              />
            </div>

            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Proof-of-Knowledge: Mining Human Potential"
                color={theme.colors.neutral[20]}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="Traditional blockchains reward computational power. MindScriber rewards thinking. Through the Neural Bridge and POKCA, human cognitive effort—learning, problem-solving, and interaction—becomes the foundation of decentralized AI validation. Instead of energy-intensive mining, GreenToken is fueled by intelligence itself."
                color={theme.colors.neutral[30]}
              />
            </div>
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Expanding the $SCRB Ecosystem"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="GreenToken supports over 2,000+ potential use cases, enabling AI, decentralized intelligence, and human-AI collaboration:"
                color={theme.colors.neutral[30]}
              />
              <p>
                {" "}
                <span className={styles.fontSpan}>
                   • AI Agents & Cognitive Clones
                </span>
                 – $SCRB powers personalized AI replicas that assist in
                research, productivity, and knowledge synthesis.
              </p>
              <p>
                {" "}
                <span className={styles.fontSpan}>
                   • Neuroadaptive Learning Rewards
                </span>
                 – Earn $SCRB through AI-enhanced cognitive development.
              </p>
            </div>
          </div>
          <div className={styles.line}></div>

          <div className={styles.block2}>
            <div className={styles.centuries}>
              <p>
                {" "}
                <span className={styles.fontSpan}>
                   • Decentralized AI Governance
                </span>
                – Participate in shaping AI ethics, policies, and frameworks.
              </p>
              <p>
                {" "}
                <span className={styles.fontSpan}>
                   • AI-Powered Personal Assistants 
                </span>
                – Train and monetize self-learning AI models tailored to
                individual behavior.
              </p>
              <p>
                {" "}
                <span className={styles.fontSpan}>
                   • Gamified Intelligence Staking
                </span>
                 – Users refine AI models, enhance performance, and submit
                cognitive challenges to earn rewards.
              </p>
              <p>
                {" "}
                <span className={styles.fontSpan}>
                   • Sentient AI-Driven Virtual Economies
                </span>
                 – $SCRB integrates with decentralized digital environments
                where AI and human intelligence merge seamlessly.
              </p>
            </div>

            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="A Token Designed for the Cognitive Economy"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber’s GreenToken isn’t just a tool for AI—it’s a new paradigm in decentralized intelligence. While initially built on BASE, the long-term roadmap envisions a proprietary blockchain where neuroadaptive transactions, cognitive staking, and AI-driven economies converge. A system where knowledge, rather than capital, fuels technological progress."
                color={theme.colors.neutral[30]}
              />
            </div>
            <CustomText
              size={isDesktop ? 20 : 15}
              text="“We are entering an age where knowledge isn’t just power—it’s currency.”—Ray Kurzweil"
              color={theme.colors.neutral[30]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
                fontWeight: "300",
              }}
            />
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="From Token to Ecosystem"
                color={theme.colors.neutral[20]}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="The future of intelligence is decentralized, ethical, and sovereign. With $SCRB, MindScriber ensures that AI remains in human hands—rewarding those who contribute knowledge, rather than those who extract it. This is not just an evolution of technology; it’s a redefinition of value itself."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
        </div>
        <CustomText
          size={isDesktop ? 20 : 15}
          text="“Precision in learning leads to mastery in action.”"
          color={theme.colors.neutral[30]}
          styles={{
            fontStyle: "italic",
            textAlign: !isDesktop ? "center" : undefined,
            margin: "0 auto",
            width: isDesktop ? undefined : "80%",
          }}
        />
        <div className={styles.blockButtons}>
          <CustomButtonSection
            text="Back"
            colorScheme="green"
            onClick={handClickSection8}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
          <CustomButtonSection
            text="Forward"
            colorScheme="green"
            onClick={handClickSection10}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
        </div>
      </div>
      <img className={styles.fon} src={isDesktop ? fon : fonMob} alt="fon" />
    </div>
  );
};
