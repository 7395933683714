// DetailPage.js
import React, { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import buttonIcon from "assets/images/Listening Button.png";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/fon_section10-info.png";
import fon2 from "assets/images/fon2-section10-info-mobile.png";
import schemaIcon from "assets/images/icon-section10-info.png";
import schema2Icon from "assets/images//Frame 26086998.png";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import close from "assets/images/close-icon.png";
import { Modal } from "components/modal";
import check from "../../../../web3-landing-page/assets-web3/image/check-section6web3.png";
import { NavIdWeb3 } from "types/navigation-web3";
import { CloseBlock } from "components/closemomileDetail";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage10: React.FC<DetailPageProps> = ({ handleClose }) => {
  const [translate, setTranslate] = useState(0);
  const [activeSection, setActiveSection] = useState<number>(0);
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const navigate = useNavigate();

  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section4)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);

  const handClickSection9 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/ai-powered");
  };
  const handClickSection11 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/scaling-intelligence");
  };
  const handleSection2Web3Click = () => {
    navigate(`/web3#${NavIdWeb3.Section2}`);
  };

  return (
    <div className={styles.section10}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        theme="black"
        logoVariant="noLogoText"
        blur
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        <img
          src={close}
          alt="img"
          className={styles.button}
          onClick={() => handleClose(NavId.Section10, 10)}
        />
        <div className={styles.content}>
          <div className={styles.block1}>
            {!isDesktop && (
              <CloseBlock
                id={NavId.Section10}
                index={10}
                handleClose={handleClose}
              />
            )}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="$SCRB IDO – Fueling the AI Economy."
                color={theme.colors.neutral[20]}
              />
              <div className={styles.p}>
                <img src={buttonIcon} alt="buttonIcon" />
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text="Tap to hear this page come to life."
                  color={theme.colors.neutral[30]}
                  styles={{ fontStyle: "italic", lineHeight: "28px" }}
                />
              </div>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="$SCRB isn’t just another token—it’s the foundation of an AI-driven economy where intelligence, engagement, and value converge. Designed to power decentralized learning, neuroadaptive AI, and interactive cognitive rewards, $SCRB ensures sustainable growth while keeping AI in human hands. This isn’t just a Web3 project—it’s an entirely new category of intelligence-driven economies."
                color={theme.colors.neutral[30]}
              />
            </div>
            <CustomText
              size={isDesktop ? 20 : 15}
              text="“The future of intelligence isn’t artificial. It’s adaptive.”"
              color={theme.colors.neutral[30]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
                fontWeight: "300",
              }}
            />
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Why Join the $SCRB Ecosystem?"
                color={theme.colors.neutral[20]}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber’s GreenToken powers a decentralized AI ecosystem where users earn, stake, and govern through cognitive engagement. Whether you’re learning, training AI models, or contributing to decentralized intelligence, every action fuels the ecosystem."
                color={theme.colors.neutral[30]}
              />
              <p>
                {" "}
                <span className={styles.fontSpan}>
                   • Gamified AI-powered learning rewards
                </span>
                 – Earn $SCRB through neuroadaptive learning and AI interaction
              </p>
              <p>
                {" "}
                <span className={styles.fontSpan}>
                   • Decentralized intelligence staking 
                </span>
                – Shape AI development and governance
              </p>
              <p>
                {" "}
                <span className={styles.fontSpan}>
                   • Cross-industry integrations
                </span>
                 – Connect with learning, AI, and neuroadaptive ecosystems
              </p>
              <p>
                {" "}
                <span className={styles.fontSpan}>
                   • Early access to premium AI tools
                </span>
                 – Unlock next-gen cognitive enhancement features
              </p>
            </div>
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="How to Participate"
                color={theme.colors.neutral[20]}
              />
              <div className={styles.blockInfo}>
                <div className={styles.card}>
                  <img src={check} alt="check" />
                  <CustomText
                    size={isDesktop ? 20 : 15}
                    text="Use a Base-compatible wallet"
                    color={theme.colors.neutral[20]}
                    styles={{
                      textAlign: "center",
                      // margin: "0 auto",
                      lineHeight: "21px",
                      fontWeight: 400,
                    }}
                  />
                </div>

                <div className={styles.card}>
                  <img src={check} alt="check" />
                  <CustomText
                    size={isDesktop ? 20 : 15}
                    text="Acquire ETH for transaction fees"
                    color={theme.colors.neutral[20]}
                    styles={{
                      textAlign: "center",
                      // margin: "0 auto",
                      lineHeight: "21px",
                      fontWeight: 400,
                    }}
                  />
                </div>

                <div className={styles.card}>
                  <img src={check} alt="check" />
                  <CustomText
                    size={isDesktop ? 20 : 15}
                    text="Register for whitelist access"
                    color={theme.colors.neutral[20]}
                    styles={{
                      textAlign: "center",
                      // margin: "0 auto",
                      lineHeight: "21px",
                      fontWeight: 400,
                    }}
                  />
                </div>

                <div className={styles.card}>
                  <img src={check} alt="check" />
                  <CustomText
                    size={isDesktop ? 20 : 15}
                    text="Participate via a verified launchpad"
                    color={theme.colors.neutral[20]}
                    styles={{
                      textAlign: "center",
                      // margin: "0 auto",
                      lineHeight: "21px",
                      fontWeight: 400,
                    }}
                  />
                </div>

                <div className={styles.card}>
                  <img src={check} alt="check" />
                  <CustomText
                    size={isDesktop ? 20 : 15}
                    text="Secure your SCRB.You’re good to go. 🚀"
                    color={theme.colors.neutral[20]}
                    styles={{
                      textAlign: "center",
                      // margin: "0 auto",
                      lineHeight: "21px",
                      fontWeight: 400,
                    }}
                  />
                </div>
              </div>
              <CustomButtonSection
                text="Buy $SCRB"
                colorScheme="green"
                onClick={handleSection2Web3Click}
                style={{
                  width: isDesktop ? "157px" : "132px",
                  color: "black",
                  margin: "0 auto",
                }}
              />
            </div>
            {!isDesktop && (
              <CustomText
                size={15}
                text="“Decentralization is not just about ownership—it’s about intelligence sovereignty.”"
                color={theme.colors.neutral[30]}
                styles={{
                  fontStyle: "italic",
                  textAlign: "center",
                  margin: "0 auto",
                  fontWeight: "300",
                }}
              />
            )}
          </div>

          <div className={styles.line}></div>

          <div className={styles.block2}>
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Vesting & Governance: Sustainable AI Integration"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber’s tokenomics ensure long-term stability, balancing reward distribution with AI development."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="$SCRB staking unlocks governance rights and interactive participation models, allowing users to earn influence in decentralized AI policy-making, neuroadaptive framework evolution, and interactive learning economies."
                color={theme.colors.neutral[30]}
              />
            </div>
            <CustomText
              size={isDesktop ? 20 : 15}
              text="“Decentralization is not just about ownership—it’s about intelligence sovereignty.”"
              color={theme.colors.neutral[30]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
                fontWeight: "300",
              }}
            />
            <img className={styles.icon} src={schema2Icon} alt="icon" />
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="From Token to Ecosystem"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber isn’t just launching a token—it’s establishing the cognitive backbone of decentralized AI. By bridging neuroadaptive intelligence with blockchain security, we’re creating a future where AI evolves with us, not against us."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Through cognitive staking, interactive AI training, and intelligence-driven engagement, users can progress, earn, and shape the future of AI-powered knowledge systems. Unlike speculative tokens, $SCRB is deeply embedded into real-time intelligence loops, ensuring every interaction enhances AI’s adaptability and personal learning experiences."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="$SCRB is the key to a next-generation AI economy, where users don’t just consume knowledge—they own their learning, train AI, and shape the neuroadaptive future."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
        </div>

        <div className={styles.blockButtons}>
          <CustomButtonSection
            text="Back"
            colorScheme="green"
            onClick={handClickSection9}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
          <CustomButtonSection
            text="Forward"
            colorScheme="green"
            onClick={handClickSection11}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
        </div>
      </div>
      <img className={styles.fon} src={isDesktop ? fon : fon2} alt="fon" />
    </div>
  );
};
