import React, { useState, useEffect } from "react";

interface TelegramIconProps {
  heightFactor?: number;
  color?: string;
  width?: number;
  height?: number;
}

export const TelegramIcon: React.FC<TelegramIconProps> = ({
  width = 32,
  height = 32,
  heightFactor = 20,
  color = "#000",
}) => {
  const [size, setSize] = useState(32);

  useEffect(() => {
    const updateSize = () => {
      const newSize = Math.min(
        40,
        Math.max(24, window.innerHeight / heightFactor)
      );
      setSize(newSize);
    };

    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [heightFactor]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}px`}
      height={`${size * (32 / 40)}px`}
      viewBox="0 0 32 32"
      fill="none"
      style={{ display: "block" }}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M26.37 5.907a2 2 0 0 1 2.75 2.168l-3.024 18.342c-.294 1.77-2.235 2.784-3.858 1.903-1.357-.737-3.373-1.873-5.186-3.059-.907-.593-3.684-2.493-3.343-3.845.293-1.156 4.96-5.5 7.627-8.083 1.046-1.014.569-1.6-.667-.666-3.07 2.317-7.998 5.841-9.627 6.833-1.437.875-2.186 1.024-3.082.875-1.635-.272-3.151-.694-4.388-1.207-1.672-.693-1.591-2.992-.002-3.661z"
        clipRule="evenodd"
      />
    </svg>
  );
};
