import styles from "./styles.module.css";
import { useDeviceType } from "hooks";
import { CustomHeadline, CustomText } from "components/ui";
import { theme } from "../../../constants/theme";
import AudioOn from "assets/svg/audio-on";
import AudioOff from "assets/svg/audio-off";
import { useEffect, useRef, useState } from "react";
import { CustomButtonSection } from "components/custom-button-section";
import { useNavigate } from "react-router-dom";
import { storage, ref, getDownloadURL } from "../../../config/firebase";
import fallbackImage from "../../../assets/images/hero-bg-moss.png";

export const OurMissions = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const [isAudioOn, setIsAudioOn] = useState(true);
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoError, setVideoError] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoUrlMp4, setVideoUrlMp4] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchVideoUrls = async () => {
      try {
        const timeout = setTimeout(() => {
          setVideoError(true);
          console.error("Video fetch timeout: Falling back to image.");
        }, 5000);

        const videoFile = device === "mobile" ? "4(400).webm" : "4(1920).webm";
        const mp4File = "4(1920).mp4";

        try {
          const webmUrl = await getDownloadURL(ref(storage, videoFile));

          const canPlayWebM = document
            .createElement("video")
            .canPlayType("video/webm");
          if (canPlayWebM) {
            setVideoUrl(webmUrl);
          } else {
            throw new Error("WebM not supported");
          }
        } catch (error) {
          console.warn("WebM not found or not supported, using MP4 fallback.");

          const mp4Url = await getDownloadURL(ref(storage, mp4File));
          setVideoUrlMp4(mp4Url);
        }

        clearTimeout(timeout);
      } catch (error) {
        console.error("Error fetching video:", error);
        setVideoError(true);
      }
    };

    fetchVideoUrls();
  }, [device]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute("playsinline", "");
      videoRef.current.setAttribute("muted", "");
      videoRef.current.play().catch(() => console.log("Autoplay blocked"));
    }
  }, [videoUrl]);

  const toggleAudio = () => {
    setIsAudioOn((prev) => !prev);
  };

  const handleExploreClick = () => {
    navigate("/detail/sentient-framework");
  };

  const handleSection2Web3Click = () => {
    window.location.href = "https://web3.mindscriber.com/#payment";
  };

  return (
    <div
      className={`${styles.container} ${videoError ? styles.fallbackBg : ""}`}
    >
      {!isLoaded && (
        <img
          src={fallbackImage}
          alt="Video placeholder"
          className={styles.videoBg}
        />
      )}
      {!videoError && videoUrl && (
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          className={styles.videoBg}
          onError={() => setVideoError(true)}
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
          preload="auto"
          onLoadedData={() => setIsLoaded(true)}
          poster={fallbackImage}
        >
          <source src={videoUrl} type="video/webm" />
          {videoUrlMp4 && <source src={videoUrlMp4} type="video/mp4" />}
        </video>
      )}
      <div className={styles.blockContent}>
        <div className={styles.content}>
          <CustomHeadline
            size={isDesktop ? 55 : 24}
            text="The First Sentient AI Framework with Neurofeedback"
            color={theme.colors.neutral[0]}
            styles={{ lineHeight: `${isDesktop ? 52 : 26}px` }}
          />
          <div className={styles.p}>
            <CustomText
              size={isDesktop ? 24 : 14}
              text={
                "Bridging AI and human cognition—adaptive, emotional, and built to empower intelligence."
              }
              color={theme.colors.neutral[0]}
              styles={{
                lineHeight: `${isDesktop ? 28 : 17}px`,
                maxWidth: isDesktop ? undefined : "258px",
              }}
            />
          </div>
          <div className={styles.blockButton}>
            <CustomButtonSection
              text="Explore Now"
              colorScheme="green"
              onClick={handleExploreClick}
            />
            <CustomButtonSection
              text="Buy $SCRB"
              colorScheme="green"
              onClick={handleSection2Web3Click}
            />
          </div>
        </div>
        {/* <div className={styles.imgAudio} onClick={toggleAudio}>
          {isAudioOn ? <AudioOn /> : <AudioOff />}
        </div> */}
      </div>
    </div>
  );
};
