import { useMemo, useState } from "react";
import styles from "./styles.module.css";
import { logEvent } from "../../utils/analytics";

interface CustomButtonProps {
  text: string;
  colorScheme?: "green" | "white" | "gradient";
  type?: "button" | "submit" | "reset";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  className?: string;
  // Optional analytics properties
  buttonName?: string;
  section?: string;
  additionalParams?: Record<string, any>;
}

export const CustomButtonSection: React.FC<CustomButtonProps> = ({
  text,
  colorScheme = "green",
  type = "button",
  onClick,
  disabled = false,
  width,
  height,
  style,
  className = "",
  // Analytics props with defaults
  buttonName,
  section,
  additionalParams = {},
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const buttonClass = useMemo(() => {
    let colorClass;
    if (colorScheme === "green") colorClass = styles.green;
    else if (colorScheme === "white") colorClass = styles.white;
    else if (colorScheme === "gradient") {
      colorClass = isClicked ? styles.gradientInactive : styles.gradient;
    }

    return [
      styles.button,
      colorClass,
      isClicked ? styles.disabled : "",
      className,
    ]
      .filter(Boolean)
      .join(" ");
  }, [colorScheme, isClicked, className]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled && !isClicked) {
      logEvent("button_click", {
        button_text: text,
        button_name: buttonName || text,
        button_color: colorScheme,
        section: section || "unknown",
        page_path: window.location.pathname,
        ...additionalParams,
      });

      if (colorScheme === "gradient") {
        setIsClicked(true);
      }
      onClick?.(event);
    }
  };

  return (
    <button
      type={type}
      onClick={handleClick}
      disabled={disabled || isClicked}
      style={{
        ...style,
        ...(width ? { width } : {}),
        ...(height ? { height } : {}),
      }}
      className={buttonClass}
    >
      {text}
    </button>
  );
};
