import * as React from "react";
interface TelegramSection12Props {
  width: string | number;
}

export const TwitterSection12: React.FC<TelegramSection12Props> = ({
  width,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="40"
    fill="none"
    viewBox="0 0 40 40"
  >
    <path
      fill="#fff"
      d="m17.05 25.402 9.292 12.933H40L24.667 16.99 37.424 1.668h-5.17l-9.985 11.99-8.61-11.99H0l14.654 20.4L1.112 38.336h5.17zm11.243 8.859L7.805 5.742h3.902l20.488 28.519z"
    ></path>
  </svg>
);
