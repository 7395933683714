import { CustomText } from "components/ui";
import styles from "./styles.module.css";
import { CountdownTimer } from "../countdownTimer";
import { theme } from "constants/theme";
import { useDeviceType } from "hooks";
import { useEffect, useState } from "react";

export const PriseIncrease = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const [targetDate, setTargetDate] = useState(() => {
    const now = new Date();
    const nextReset = new Date(now);
    nextReset.setDate(now.getDate() + 3);
    nextReset.setHours(0, 0, 0, 0);
    return nextReset.toISOString();
  });

  useEffect(() => {
    const checkReset = setInterval(() => {
      const now = new Date();
      const target = new Date(targetDate);
      if (now >= target) {
        const nextReset = new Date(now);
        nextReset.setDate(now.getDate() + 3);
        nextReset.setHours(0, 0, 0, 0);
        setTargetDate(nextReset.toISOString());
      }
    }, 1000);
    return () => clearInterval(checkReset);
  }, [targetDate]);
  return (
    <div className={styles.blockPrice}>
      <CustomText
        size={20}
        text="Next Price Increase In"
        color={theme.colors.neutral[0]}
        styles={{
          textAlign: "center",
          margin: "0 auto",
          lineHeight: "22px",
          fontWeight: 500,
        }}
      />
      <CountdownTimer targetDate={targetDate} />
      <div className={styles.blockCurrentListing}>
        <CustomText
          size={isDesktop ? 18 : 16}
          text={`Current Price $0.0009`}
          color={theme.colors.neutral[0]}
          styles={{
            lineHeight: "18px",
            fontWeight: 400,
          }}
        />
        <CustomText
          size={isDesktop ? 18 : 16}
          text="Listing Price $0.0018"
          color="rgba(255, 255, 255, 0.67)"
          styles={{
            lineHeight: "22px",
            fontWeight: 400,
          }}
        />
      </div>
    </div>
  );
};
