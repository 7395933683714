import styles from "./styles.module.css";

export const LinkBlock = () => {
  return (
    <div className={styles.link}>
      <a
        href="https://t.me/mindscriber"
        target="_blank"
        rel="noopener noreferrer"
      >
        Telegram
      </a>
      <a
        href="https://www.instagram.com/scriber_scrb/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </a>
      <a
        href="https://x.com/MindScriber"
        target="_blank"
        rel="noopener noreferrer"
      >
        Twitter
      </a>
    </div>
  );
};
