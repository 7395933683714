import { CustomHeadline, CustomText } from "components/ui";
import styles from "./styles.module.css";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { useEffect, useRef, useState } from "react";
import AudioOn from "assets/svg/audio-on";
import AudioOff from "assets/svg/audio-off";
import iconSLogo from "assets/images/S-logo.png";
import { CustomButtonSection } from "components/custom-button-section";
import { useNavigate } from "react-router-dom";
import { storage, ref, getDownloadURL } from "../../../config/firebase";
import fallbackImage from "../../../assets/images/fon-section-9-mobile.png";

export const GetInTouch = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const [isAudioOn, setIsAudioOn] = useState(true);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoUrlMp4, setVideoUrlMp4] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchVideoUrls = async () => {
      try {
        const timeout = setTimeout(() => {
          setVideoError(true);
          console.error("Video fetch timeout: Falling back to image.");
        }, 5000);

        const videoFile = device === "mobile" ? "5(400).webm" : "5(1920).webm";
        const mp4File = "5(1920).mp4";

        try {
          const webmUrl = await getDownloadURL(ref(storage, videoFile));

          const canPlayWebM = document
            .createElement("video")
            .canPlayType("video/webm");
          if (canPlayWebM) {
            setVideoUrl(webmUrl);
          } else {
            throw new Error("WebM not supported");
          }
        } catch (error) {
          console.warn("WebM not found or not supported, using MP4 fallback.");

          const mp4Url = await getDownloadURL(ref(storage, mp4File));
          setVideoUrlMp4(mp4Url);
        }

        clearTimeout(timeout);
      } catch (error) {
        console.error("Error fetching video:", error);
        setVideoError(true);
      }
    };

    fetchVideoUrls();
  }, [device]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute("playsinline", "");
      videoRef.current.setAttribute("muted", "");
      videoRef.current.play().catch(() => console.log("Autoplay blocked"));
    }
  }, [videoUrl]);

  const toggleAudio = () => {
    setIsAudioOn((prev) => !prev);
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  const [videoError, setVideoError] = useState(false);

  const navigate = useNavigate();

  const handleExploreClick = () => {
    navigate("/detail/ai-powered");
  };

  const handleBuyTokenClick = () => {
    window.location.href = "https://web3.mindscriber.com/#payment";
  };
  return (
    <>
      <div
        className={`${styles.container} ${videoError ? styles.fallbackBg : ""}`}
      >
        {!isLoaded && (
          <img
            src={fallbackImage}
            alt="Video placeholder"
            className={styles.videoBg}
          />
        )}
        {!videoError && videoUrl && (
          <video
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
            className={styles.videoBg}
            onError={() => setVideoError(true)}
            controlsList="nodownload"
            onContextMenu={(e) => e.preventDefault()}
            preload="auto"
            onLoadedData={() => setIsLoaded(true)}
          >
            <source src={videoUrl} type="video/webm" />
            {videoUrlMp4 && <source src={videoUrlMp4} type="video/mp4" />}
          </video>
        )}
        {!isDesktop && (
          <div className={styles.fonSlogo}>
            <img src={iconSLogo} alt="icon" className={styles.iconSLogo} />
          </div>
        )}
        <div className={styles.content}>
          <CustomHeadline
            size={isDesktop ? 55 : 24}
            text="GreenToken – AI Belongs to People."
            color={theme.colors.neutral[0]}
            styles={{
              lineHeight: `${isDesktop ? 52 : 26}px`,
            }}
          />
          <div className={styles.p}>
            <CustomText
              size={isDesktop ? 24 : 14}
              text="$SCRB fuels a human-driven AI future—rewarding intelligence, ensuring sovereignty, and keeping learning in our hands."
              color={theme.colors.neutral[0]}
              styles={{
                lineHeight: `${isDesktop ? 28 : 17}px`,
              }}
            />
          </div>
          <div
            className={styles.blockButton}
            style={{ marginTop: isDesktop ? "2%" : "7%" }}
          >
            <CustomButtonSection
              text="Learn More"
              colorScheme="green"
              onClick={handleExploreClick}
            />
            <CustomButtonSection
              text="Buy $SCRB"
              colorScheme="green"
              onClick={handleBuyTokenClick}
            />
          </div>
        </div>
        {isDesktop && (
          <div className={styles.fonSlogo}>
            <img src={iconSLogo} alt="icon" className={styles.iconSLogo} />
          </div>
        )}
        {/* <div className={styles.imgAudio} onClick={toggleAudio}>
          {isAudioOn ? <AudioOn /> : <AudioOff />}
        </div> */}
      </div>
    </>
  );
};
