import { CustomHeadline, CustomText } from "components/ui";
import styles from "../styles.module.css";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { ContactFormBlock1 } from "./contact-form";

export const Block1 = () => {
  const { device } = useDeviceType();

  const isDesktop = device === "desktop";

  return (
    <>
      <div className={styles.containerBlock1}>
        <div className={`${styles.content} ${styles.left}`}>
          <CustomHeadline
            size={isDesktop ? 54 : 24}
            text="Let’s Build the Future Together"
            color={theme.colors.neutral[20]}
            styles={{
              lineHeight: `${isDesktop ? 52 : 26}px`,
              width: isDesktop ? "" : "60dvw",
              maxWidth: isDesktop ? "70%" : "",
            }}
          />
          <div className={styles.p}>
            <CustomText
              size={isDesktop ? 24 : 14}
              text="Have something big to offer? Partnerships, contributions, or ideas—fill out the form."
              color={theme.colors.neutral[20]}
              styles={{
                lineHeight: `${isDesktop ? 28 : 17}px`,
                width: isDesktop ? "" : "60dvw",
                maxWidth: isDesktop ? "70%" : "",
              }}
            />
          </div>

          <div className={styles.form}>
            <ContactFormBlock1 />
          </div>
        </div>
      </div>
    </>
  );
};
