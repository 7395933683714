import type { ReactNode } from "react";
import { usePageTracking } from "../../hooks/usePageTracking";
import { useButtonTracking } from "../../hooks/useButtonTracking";

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  usePageTracking();

  useButtonTracking();

  return <>{children}</>;
};
