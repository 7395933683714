import React from "react";
import { useDeviceType } from "hooks";

interface IconProps extends React.SVGProps<SVGSVGElement> {}

export const IconCustom1: React.FC<IconProps> = (props) => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  return (
    <svg
      width="59"
      height="60"
      viewBox="0 0 59 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props} // Передаёт любые пропсы дальше внутрь <svg>
    >
      <path d="M8.53068 38.852L13.5211 49.6662C14.341 51.3047 18.4378 54.5829 28.2711 54.5829C38.1044 54.5829 42.4065 49.6662 42.4065 49.6662L46.8021 41.4219C47.6549 39.8224 47.8532 37.9542 47.3553 36.2113C47.3553 36.2113 46.3932 32.8798 44.25 31.1652C42.3092 29.6126 43.1013 30.3889 41.9211 29.6126C41.4387 29.2953 40.9652 28.7335 40.6066 28.2412C40.1589 27.6264 39.8772 26.9131 39.6734 26.1804C39.4471 25.367 39.3324 24.5267 39.3324 23.6824V9.30039C39.3324 8.38834 38.9698 7.51318 38.3244 6.86909C37.9256 6.47004 37.3936 6.23209 36.8302 6.2008C36.2669 6.1695 35.7118 6.34705 35.2712 6.69947L35.0069 6.91089C34.6251 7.21634 34.3078 7.59469 34.0736 8.0239C33.8394 8.45312 33.6929 8.92464 33.6426 9.41101L32.1823 23.5378C32.1599 23.7542 32.0805 23.9608 31.9522 24.1365C31.8239 24.3122 31.6512 24.4507 31.4518 24.5378C31.2525 24.6249 31.0336 24.6576 30.8174 24.6324C30.6013 24.6072 30.3958 24.5252 30.2218 24.3946L28.9607 23.4481C28.3896 23.0199 27.7065 22.7667 26.9944 22.7192C26.2822 22.6716 25.5716 22.8317 24.9487 23.1801L11.1119 30.9116C9.76082 31.6668 8.74445 32.9041 8.26592 34.3762C7.78739 35.8482 7.88194 37.4466 8.53068 38.852Z" stroke={isDesktop ? "black" : "#00CB94"} stroke-width="2"/>
      </svg>
  );
};


