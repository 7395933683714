import { useEffect } from "react";
import { logEvent } from "../utils/analytics";

export const useButtonTracking = () => {
  useEffect(() => {
    const handleButtonClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const button = target.closest("button") || target.closest("a");

      if (!button) return;

      const buttonText = button.textContent?.trim() || "";
      const buttonId = button.id || "";
      const buttonClass = button.className || "";
      const section = findSectionName(button);

      logEvent("button_click", {
        button_text: buttonText,
        button_id: buttonId,
        button_class: buttonClass,
        section: section,
        page_path: window.location.pathname,
      });
    };

    const findSectionName = (element: Element): string => {
      let current = element;
      while (current && current !== document.body) {
        if (current.getAttribute("data-section")) {
          return current.getAttribute("data-section") || "";
        }
        if (
          current.tagName.toLowerCase() === "section" ||
          current.tagName.toLowerCase() === "article"
        ) {
          return current.id || "unnamed-section";
        }
        current = current.parentElement as Element;
      }
      return "unknown-section";
    };

    document.addEventListener("click", handleButtonClick);

    return () => {
      document.removeEventListener("click", handleButtonClick);
    };
  }, []);
};

/**
 * Custom hook for tracking button clicks globally
 * @param buttonName Name or identifier for the button
 * @param additionalParams Any additional parameters to log
 */
export const trackButtonClick = (buttonName: string, additionalParams = {}) => {
  logEvent("button_click", {
    button_name: buttonName,
    ...additionalParams,
  });
};
