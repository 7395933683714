import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { CustomButtonSection } from "components/custom-button-section";
import icon1 from "../../assets-web3/image/famicons_cloudy-night-outline.png";
import icon2 from "../../assets-web3/image/arcticons_voice.png";
import icon3 from "../../assets-web3/image/hugeicons_brain-02.png";
import icon4 from "../../assets-web3/image/si_fact-check-line.png";
import icon5 from "../../assets-web3/image/lucide_image.png";
import img from "../../assets-web3/image/Frame 26087033.png";
import { ScrollContext } from "web3-landing-page";
import { useContext } from "react";

export const Section8Web3 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const { scrollToSection } = useContext(ScrollContext);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.centuries}>
          <CustomHeadline
            size={isDesktop ? 60 : 24}
            text="MindScriber App: The Night Learning Revolution"
            color={
              isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[20]
            }
            styles={{
              fontWeight: 600,
              lineHeight: isDesktop ? "54" : "26px",
              textAlign: isDesktop ? "left" : "center",
            }}
          />
          <CustomText
            size={isDesktop ? 24 : 18}
            text="AI-optimized memory reinforcement during sleep enhances subconscious learning and cognitive retention."
            color={theme.colors.neutral[20]}
            styles={{
              textAlign: isDesktop ? "left" : "center",
              margin: "0 auto",
              lineHeight: isDesktop ? "28" : "21px",
              fontWeight: 400,
            }}
          />
        </div>
        <div className={styles.centuries2}>
          <div className={styles.infoBlock}>
            <img src={icon1} alt="img" />
            <CustomText
              size={isDesktop ? 20 : 15}
              text="Night learning mode processes knowledge while you sleep"
              color={theme.colors.neutral[20]}
              styles={{
                lineHeight: "15px",
                fontWeight: 500,
              }}
            />
          </div>
          <div className={styles.infoBlock}>
            <img src={icon2} alt="img" />
            <CustomText
              size={isDesktop ? 20 : 15}
              text="Self-voice cloning strengthens memory using your own voice"
              color={theme.colors.neutral[20]}
              styles={{
                lineHeight: "15px",
                fontWeight: 500,
              }}
            />
          </div>
          <div className={styles.infoBlock}>
            <img src={icon3} alt="img" />
            <CustomText
              size={isDesktop ? 20 : 15}
              text="AI-powered summarization condenses information for rapid learning"
              color={theme.colors.neutral[20]}
              styles={{
                lineHeight: "15px",
                fontWeight: 500,
              }}
            />
          </div>
          <div className={styles.infoBlock}>
            <img src={icon4} alt="img" />
            <CustomText
              size={isDesktop ? 20 : 15}
              text="Adaptive self-testing creates a personalized cognitive optimization system"
              color={theme.colors.neutral[20]}
              styles={{
                lineHeight: "15px",
                fontWeight: 500,
              }}
            />
          </div>
          <div className={styles.infoBlock}>
            <img src={icon5} alt="img" />
            <CustomText
              size={isDesktop ? 20 : 15}
              text="Text-to-image speech creates multimodal learning for deeper understanding."
              color={theme.colors.neutral[20]}
              styles={{
                lineHeight: "15px",
                fontWeight: 500,
              }}
            />
          </div>
        </div>
        {!isDesktop && (
          <CustomText
            size={isDesktop ? 20 : 16}
            text="The AI Future Starts Here – Own Your Share"
            color={theme.colors.neutral[20]}
            styles={{
              fontStyle: "italic",
              lineHeight: "21px",
              fontWeight: 500,
            }}
          />
        )}
        <CustomButtonSection
          text="Buy $SCRB Now"
          colorScheme="green"
          onClick={() => scrollToSection("payment")}
          style={{
            color: theme.colors.neutral[20],
            marginTop: isDesktop ? "" : "1.35dvw",
          }}
        />
      </div>
      {isDesktop && (
        <div className={styles.blockImg}>
          <img src={img} alt="img" />

          <CustomText
            size={isDesktop ? 20 : 16}
            text="The AI Future Starts Here – Own Your Share"
            color={theme.colors.neutral[20]}
            styles={{
              fontStyle: "italic",
              lineHeight: "21px",
              fontWeight: 500,
            }}
          />
        </div>
      )}
    </div>
  );
};
