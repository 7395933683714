import { createConfig } from 'wagmi'
import { walletConnect } from '@wagmi/connectors'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { type Chain,  base, mainnet,  } from 'wagmi/chains'
import { http } from 'viem'

const projectId = "c12cda8d6756dabcc065522227fcd2b3"

const binanceSmartChain: Chain = {
  id: 56,
  name: 'BNB Smart Chain',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: { http: ['https://bsc-dataseed.binance.org'] },
  },
  blockExplorers: {
    default: {
      name: 'BscScan',
      url: 'https://bscscan.com',
      apiUrl: 'https://api.bscscan.com/api',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 15921452,
    },
  },
}


const chains: [Chain, ...Chain[]] = [base, mainnet, binanceSmartChain]
export const config = createConfig({
  chains,
  connectors: [
    walletConnect({ projectId: projectId, showQrModal: false })
  ],
  transports: {
    [base.id]: http(),
    [mainnet.id]: http(),
    [binanceSmartChain.id]: http()
  }
})

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  themeMode: 'dark'
})

declare module 'wagmi' {
  interface Register {
    config: typeof config
  }
}

