import { FC, FunctionComponent, SVGAttributes } from "react";
import Select, {
  ActionMeta,
  GroupBase,
  OptionProps,
  SingleValue,
  SingleValueProps,
  StylesConfig,
  components,
} from "react-select";
import { PresaleOffer } from "utils";
import { EthIcon } from "../../../assets/svg/eth";
import { UsdtIcon } from "../../../assets/svg/usdt";
import { UsdcIcon } from "../../../assets/svg/usdc";
import { BnbIcon } from "../../../assets/svg/bnb";
import { useChainId } from "wagmi";
import { useDeviceType } from "hooks";

interface Iprops {
  selectedCurrency: any;
  handleCurrencyChange: (
    newValue: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => void;
}
type OptionType = {
  value: "BNB" | "ETH" | "USDT" | "USDC";
  label: string;
  svg: FunctionComponent<SVGAttributes<SVGSVGElement>>;
};

export const CurrensySelect: FC<Iprops> = ({
  selectedCurrency,
  handleCurrencyChange,
}) => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const chainId = useChainId();
  const currencyOptions: OptionType[] = [
    { value: "ETH", label: "", svg: EthIcon },
    { value: "BNB", label: "", svg: BnbIcon },
    { value: "USDT", label: "", svg: UsdtIcon },
    { value: "USDC", label: "", svg: UsdcIcon },
  ];

  const getFilteredCurrencies = (chainId: number): OptionType[] => {
    const selectedNetwork = PresaleOffer.getCurrentNetwork(chainId);
    if (selectedNetwork === "BSC") {
      return currencyOptions.filter((option) => option.value !== "ETH");
    } else if (selectedNetwork === "ETH" || selectedNetwork === "BASE") {
      return currencyOptions.filter((option) => option.value !== "BNB");
    }
    return currencyOptions;
  };
  const customStyles: StylesConfig<OptionType, false, GroupBase<OptionType>> = {
    control: (base: any, props: any) => ({
      ...base,
      border: "none",
      borderRadius: "0 20px 20px 0",
      backgroundColor: "transparent",
      minHeight: isDesktop ? "2" : "9.3dvw",
      width: isDesktop ? "3.4dvw" : "15.34dvw",
      borderLeft: "1px solid #ffffff",
      boxShadow: "none",
      "&:hover": {
        border: "none",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      },
      padding: isDesktop ? "0 0.2dvw" : "0 2dvw",
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: "0",
      color: "#fff",
    }),
    input: (base: any) => ({
      ...base,
      color: "#fff",
      margin: 0,
      padding: 0,
      width: 0,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "#fff",
      marginLeft: "0",
      display: "flex",
      alignItems: "center",
      width: "20px",
    }),
    placeholder: (base: any) => ({
      ...base,
      display: "none",
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: "#1A8C7A",
      borderRadius: "10px",
      marginTop: "2px",
      width: "75px", // Match control width for visibility
      position: "absolute", // Ensure it’s positioned correctly
      zIndex: 9999,
    }),
    option: (base: any, props: any) => ({
      ...base,
      backgroundColor: props.isFocused ? "#ffffff20" : "#1A8C7A",
      color: "#fff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "5px 10px",
      fontSize: "12px",
      "&:hover": {
        backgroundColor: "#ffffff20",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: "#fff",
      padding: "0 5px",
    }),
  };

  const CustomSingleValue = ({
    children,
    ...props
  }: SingleValueProps<OptionType, false, GroupBase<OptionType>>) => {
    const Icon = props.data.svg;
    return (
      <components.SingleValue {...props}>
        <Icon width={18} height={18} />
      </components.SingleValue>
    );
  };

  const CustomOption = ({
    children,
    ...props
  }: OptionProps<OptionType, false, GroupBase<OptionType>>) => {
    const Icon = props.data.svg;
    return (
      <components.Option {...props}>
        <Icon width={18} height={18} />
        {children}
      </components.Option>
    );
  };
  return (
    <Select
      value={selectedCurrency}
      onChange={handleCurrencyChange}
      options={getFilteredCurrencies(chainId)}
      styles={customStyles}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => (
          <span
            style={{
              color: "#fff",
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 16L5 9.09307L6.1078 8L12 13.8139L17.8922 8L19 9.09307L12 16Z"
                fill="white"
              />
            </svg>
          </span>
        ),
        SingleValue: CustomSingleValue,
        Option: CustomOption,
      }}
      isSearchable={false}
    />
  );
};
