// DetailPage.js
import React, { useCallback, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import buttonIcon from "assets/images/Listening Button.png";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/fon-info7-baby.png";
import fon2 from "assets/images/fon-info7-baby-mobile.png";
import iconTel from "assets/images/icon-tel-section-7.png";
import iconTel2 from "assets/images/icon-tel2-section-7.png";
import iconTel3 from "assets/images//skdfh 1.png";
import iconTel4 from "assets/images/Untitled 1.png";
import schemaIcon from "assets/images/schema-section7.png";
import spdxIcon from "assets/images/spdxicon-section7.png";
import { Navbar } from "components/navbar/index";
import { NavId } from "types/navigation";
import { Icon1Section7 } from "assets/svg/icon1-section-7";
import { Icon2Section7 } from "assets/svg/icon-2-section-7";
import { Icon3Section7 } from "assets/svg/icon-3-section-7";
import { Icon4Section7 } from "assets/svg/icon-4-section-7";
import { Icon5Section7 } from "assets/svg/icon-5-section-7";
import close from "assets/images/close-icon.png";
import { Modal } from "components/modal";
import { CloseBlock } from "components/closemomileDetail";

interface DetailPageProps {
  handleClose: (sectionId: string, sectionNumber: number) => void;
}

export const DetailPage7: React.FC<DetailPageProps> = ({ handleClose }) => {
  const [translate, setTranslate] = useState(0);
  const [activeSection, setActiveSection] = useState<number>(0);
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const navigate = useNavigate();

  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.Section4)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);
  const handClickSection5 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/ai-framework");
  };
  const handClickSection8 = () => {
    window.scrollTo(0, 0);
    navigate("/detail/voice-cloning-science");
  };

  return (
    <div className={styles.section7}>
      <Navbar
        activeSection={activeSection}
        navigateToTop={navigateToTop}
        theme="black"
        logoVariant="noLogoText"
        blur
      />
      <Modal setTranslate={setTranslate} setActiveSection={setActiveSection} />
      <div className={styles.container}>
        <img
          src={close}
          alt="img"
          className={styles.button}
          onClick={() => handleClose(NavId.Section7, 7)}
        />
        <div className={styles.content}>
          <div className={styles.block1}>
            {!isDesktop && (
              <CloseBlock
                id={NavId.Section7}
                index={7}
                handleClose={handleClose}
              />
            )}
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Intelligent Tools. Effortless Learning."
                color={theme.colors.neutral[20]}
              />
              <div className={styles.p}>
                <img src={buttonIcon} alt="buttonIcon" />
                <CustomText
                  size={isDesktop ? 16 : 14}
                  text="Tap to hear this page come to life."
                  color={theme.colors.neutral[30]}
                  styles={{ fontStyle: "italic", lineHeight: "28px" }}
                />
              </div>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="True learning isn’t passive—it’s dynamic, evolving, and deeply personal. MindScriber’s AI agent framework does more than deliver information. It optimizes retention, refines cognitive efficiency, and adapts to individual learning patterns in real time. MindScriber ensures that learning is fluid, secure, and uniquely tailored. For dyslexics, learning isn’t a linear process—it’s a web of connections, patterns, and associations that traditional systems often overlook. Their minds absorb information in unique ways, yet they are forced into rigid structures that don’t align with their strengths."
                color={theme.colors.neutral[30]}
              />
            </div>
            <div className={styles.blockIcon}>
              <Icon1Section7 />
              <Icon2Section7 />
              <Icon3Section7 />
              <Icon4Section7 />
              <Icon5Section7 />
            </div>
            <CustomText
              size={isDesktop ? 20 : 20}
              text="“The next evolution of intelligence is adaptability.”"
              color={theme.colors.neutral[30]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
                fontWeight: "300",
              }}
            />
            <div className={styles.centuries}>
              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber bridges this gap by creating a seamless interaction between conscious focus and subconscious absorption. Learning becomes intuitive, tailored to how students naturally process information."
                color={theme.colors.neutral[30]}
              />
            </div>
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Night Learning Mode – Unlocking Subconscious Retention"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Learning doesn’t stop when the mind rests. MindScriber’s Night Learning Mode strengthens memory encoding, using EEG-based biofeedback and AI-driven adaptation to synchronize learning with subconscious processing. Neural reinforcement happens when the body is at rest, allowing knowledge to embed deeper into long-term memory."
                color={theme.colors.neutral[30]}
              />
            </div>
            <img className={styles.iconTel} src={iconTel} alt="icon" />
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="AI Lecture Summarization – Condensing Hours into Minutes"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Time is critical. MindScriber’s AI Lecture Summarization compresses dense educational material into structured, high-fidelity summaries without losing meaning. The Quantum Neural Bridge (QNB) intelligently processes vast linguistic structures in real time, delivering adaptive, personalized summaries based on the user’s comprehension level."
                color={theme.colors.neutral[30]}
              />
            </div>
            <img className={styles.iconTel3} src={iconTel3} alt="icon" />

            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Self-Testing Mode – Adaptive Recall for Maximum Retention"
                color={theme.colors.neutral[20]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Mastery isn’t just about exposure to information—it’s about interaction, engagement, and real-time adaptation. MindScriber’s Self-Testing Mode dynamically generates personalized assessments, adjusting cognitive structure based on neuro-adaptive signals. AI models read physiological responses, adjusting recall exercises to align with peak mental receptivity, improving both efficiency and retention."
                color={theme.colors.neutral[30]}
              />
            </div>
            <img className={styles.iconTel3} src={iconTel4} alt="icon" />
          </div>
          <div className={styles.line}></div>
          <div className={styles.block2}>
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Text-to-Speech-to-Image – Multi-Sensory Learning for Deeper
                Understanding"
                color={theme.colors.neutral[20]}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="Understanding extends beyond words. MindScriber’s Text-to-Speech-to-Image AI bridges auditory and visual comprehension, transforming complex ideas into structured, interactive learning experiences. AI-driven cognitive shifts, real-time contextual mapping, and adaptive visual interpretations refine clarity and engagement. The system adjusts dynamically to user preferences and cognitive load, ensuring an intuitive and personalized experience."
                color={theme.colors.neutral[30]}
              />
            </div>
            <img className={styles.iconTel} src={iconTel2} alt="icon" />
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Decentralized AI Learning – Privacy-First, Adaptive Intelligence"
                color={theme.colors.neutral[20]}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber’s Decentralized Cognitive Infrastructure (DCI) enables privacy-first learning, where all cognitive insights remain on encrypted servers. This ensures that AI learning data remains private, safeguarding autonomy while models train on-device, personalizing education without ever exposing raw data."
                color={theme.colors.neutral[30]}
              />
            </div>
            <img className={styles.iconTel} src={schemaIcon} alt="icon" />

            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="Enterprise & API Solutions – AI for Institutions & Corporations"
                color={theme.colors.neutral[20]}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber’s enterprise-grade AI solutions empower universities, corporations, and e-learning platforms with tools to enhance engagement and retention at scale. AI mentorship models reduce cognitive workload, while blockchain-backed smart contracts validate learning milestones with immutable, tamper-proof tracking."
                color={theme.colors.neutral[30]}
              />
            </div>
            <img className={styles.spdxIcon} src={spdxIcon} alt="icon" />
            <div className={styles.centuries}>
              <CustomHeadline
                size={isDesktop ? 32 : 24}
                text="A New Era of Cognitive Mastery"
                color={theme.colors.neutral[20]}
              />

              <CustomText
                size={isDesktop ? 20 : 15}
                text="MindScriber is redefining intelligence by merging neuroscience, encrypted AI, and decentralized learning. With GreenLine, users retain full control over their cognitive data, ensuring privacy and autonomy. Unlike traditional AI, MindScriber enables decentralized on-device training, allowing individuals to govern their AI-assisted learning."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Our gamified intelligence economy rewards cognitive performance and knowledge contribution. Through GreenToken, learning becomes interactive, with users earning digital rewards for engagement, skill refinement, and neuroadaptive challenges."
                color={theme.colors.neutral[30]}
              />
              <CustomText
                size={isDesktop ? 20 : 15}
                text="Blockchain-backed smart contracts validate learning milestones, making achievements verifiable and transferable. Whether for academic growth, workforce training, or cognitive performance, MindScriber creates a seamless, decentralized framework where intelligence fuels evolution."
                color={theme.colors.neutral[30]}
              />
            </div>
          </div>
        </div>
        <CustomText
          size={isDesktop ? 20 : 15}
          text="“The mind is not a vessel to be filled, but a fire to be kindled.” — Plutarch"
          color={theme.colors.neutral[30]}
          styles={{
            fontStyle: "italic",
            textAlign: !isDesktop ? "center" : undefined,
            margin: "0 auto",
            width: isDesktop ? undefined : "80%",
          }}
        />
        <div className={styles.blockButtons}>
          <CustomButtonSection
            text="Back"
            colorScheme="green"
            onClick={handClickSection5}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
          <CustomButtonSection
            text="Forward"
            colorScheme="green"
            onClick={handClickSection8}
            style={{
              color: theme.colors.neutral[20],
              width: isDesktop ? "8.177dvw" : "40.69dvw",
            }}
          />
        </div>
      </div>
      <img className={styles.fon} src={isDesktop ? fon : fon2} alt="fon" />
    </div>
  );
};
