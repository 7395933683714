import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { CustomButtonSection } from "components/custom-button-section";
import { useWeb3Modal } from "@web3modal/wagmi/react";

export const Section10Web3 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const { open } = useWeb3Modal();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.centuries}>
          <CustomHeadline
            size={isDesktop ? 60 : 24}
            text="A Framework That Evolves With You"
            color={
              isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[20]
            }
            styles={{
              lineHeight: isDesktop ? "54" : "26px",
              textAlign: "center",
            }}
          />
          <CustomText
            size={isDesktop ? 24 : 16}
            text="MindScriber is the first non-invasive AI framework designed for cognitive enhancement, adaptive learning, and real-time intelligence."
            color={theme.colors.neutral[20]}
            styles={{
              textAlign: "center",
              margin: "20 auto",
              lineHeight: isDesktop ? "28px" : "21px",
              fontWeight: 400,
              width: isDesktop ? "80%" : "",
            }}
          />
        </div>

        <div className={styles.infoBlock}>
          <div className={styles.card}>
            <CustomHeadline
              size={isDesktop ? 130 : 50}
              text="18k"
              color={theme.colors.green[90]}
              styles={{
                lineHeight: isDesktop ? "130px" : "26px",
                textAlign: isDesktop ? "left" : "center",
                fontWeight: 700,
                paddingBottom: isDesktop ? "" : 15,
              }}
            />
            <CustomText
              size={isDesktop ? 24 : 16}
              text="participants validating neuroadaptive AI"
              color={theme.colors.green[90]}
              styles={{
                textAlign: isDesktop ? "left" : "center",
                margin: "20 auto",
                lineHeight: isDesktop ? "32" : "21px",
                fontWeight: isDesktop ? 500 : 400,
              }}
            />
          </div>
          <div className={styles.card}>
            <CustomHeadline
              size={isDesktop ? 130 : 50}
              text={"30+"}
              color={theme.colors.green[90]}
              styles={{
                lineHeight: isDesktop ? "130px" : "26px",
                textAlign: isDesktop ? "left" : "center",
                fontWeight: 700,
                paddingBottom: isDesktop ? "" : 15,
              }}
            />
            <CustomText
              size={isDesktop ? 24 : 16}
              text="universities exploring neural-AI integration"
              color={theme.colors.green[90]}
              styles={{
                textAlign: isDesktop ? "left" : "center",
                margin: "20 auto",
                lineHeight: isDesktop ? "32" : "21px",
                fontWeight: isDesktop ? 500 : 400,
              }}
            />
          </div>
          <div className={styles.card}>
            <CustomHeadline
              size={isDesktop ? 130 : 50}
              text="15"
              color={theme.colors.green[90]}
              styles={{
                lineHeight: isDesktop ? "130px" : "26px",
                textAlign: isDesktop ? "left" : "center",
                fontWeight: 700,
                paddingBottom: isDesktop ? "" : 15,
              }}
            />
            <CustomText
              size={isDesktop ? 24 : 16}
              text="patents covering neurofeedback, decentralized AI, and cognitive augmentation"
              color={theme.colors.green[90]}
              styles={{
                textAlign: isDesktop ? "left" : "center",
                margin: "20 auto",
                lineHeight: isDesktop ? "32" : "21px",
                fontWeight: isDesktop ? 500 : 400,
              }}
            />
          </div>
          <div className={styles.card}>
            <CustomHeadline
              size={isDesktop ? 130 : 50}
              text="#1"
              color={theme.colors.green[90]}
              styles={{
                lineHeight: isDesktop ? "130px" : "26px",
                textAlign: isDesktop ? "left" : "center",
                fontWeight: 700,
                paddingBottom: isDesktop ? "" : 15,
              }}
            />
            <CustomText
              size={isDesktop ? 24 : 16}
              text="AI system reading deep layers of the autonomic nervous system"
              color={theme.colors.green[90]}
              styles={{
                textAlign: isDesktop ? "left" : "center",
                margin: "20 auto",
                lineHeight: isDesktop ? "32" : "21px",
                fontWeight: isDesktop ? 500 : 400,
              }}
            />
          </div>
        </div>

        {!isDesktop && (
          <CustomButtonSection
            text="Connect wallet"
            colorScheme="green"
            onClick={() => open()}
            style={{
              color: isDesktop
                ? theme.colors.neutral[20]
                : theme.colors.neutral[20],
              marginTop: "1.35dvw",
            }}
          />
        )}
      </div>
    </div>
  );
};
