import {
  useRef,
  type TouchEvent,
  useCallback,
  type ReactNode,
  Children,
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import styles from "./styles.module.css";
import "./desktop.css";
import { useDeviceType } from "hooks";

interface ScrollableContainerProps {
  children: ReactNode;
  snapSensitivity: number;
  itemSpacing: number;
  activeSection: number;
  setActiveSection: Dispatch<SetStateAction<number>>;
  setTranslate: Dispatch<SetStateAction<number>>;
  translate: number;
  isWeb3?: boolean;
}

export const ScrollableContainer = ({
  children,
  snapSensitivity = 50,
  itemSpacing = 0,
  activeSection,
  setActiveSection,
  setTranslate,
  translate,
  isWeb3 = false,
}: ScrollableContainerProps) => {
  const { device } = useDeviceType();
  const [initialized, setInitialized] = useState(false);

  const sectionHeight = useMemo(() => window.innerHeight, []);
  const thumbRef = useRef<HTMLDivElement | null>(null);
  const scrollbarRef = useRef<HTMLDivElement | null>(null);
  const touchStartY = useRef<number>(0);
  const currentTranslate = useRef<number>(translate);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const sectionsAmount = Children.count(children);

  useEffect(() => {
    if (!initialized && device === "mobile") {
      setInitialized(true);
      setTranslate(activeSection * sectionHeight);
    }
  }, [activeSection, device, initialized, sectionHeight, setTranslate]);

  useEffect(() => {
    const handleScroll = () => {
      const container = document.querySelector(".scroll-container");

      if (!container) {
        console.error("Scroll container not found!");
        return;
      }

      const sections = document.querySelectorAll(".scroll-section");
      let index = 0;
      sections.forEach((section, i) => {
        const rect = section.getBoundingClientRect();
        if (rect.top < window.innerHeight / 2) {
          index = i;
        }
      });

      if (index !== activeSection) {
        setActiveSection(index);
      }
    };

    setTimeout(() => {
      const container = document.querySelector(".scroll-container");
      if (!container) {
        return;
      }

      container.addEventListener("scroll", handleScroll);
    }, 500);

    return () => {
      const container = document.querySelector(".scroll-container");
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [activeSection, setActiveSection]);

  useEffect(() => {
    if (device === "mobile" && thumbRef.current) {
      thumbRef.current.style.transform = `translateY(${
        translate / sectionsAmount
      }px)`;
    }
  }, [translate, device, sectionsAmount]);

  const handleTouchStart = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      touchStartY.current = e.touches[0].clientY;
      currentTranslate.current = translate;
    },
    [translate]
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      const touchCurrentY = e.touches[0].clientY;
      const distance = touchStartY.current - touchCurrentY;
      const newTranslate = currentTranslate.current + distance;

      const maxTranslate = sectionHeight * (sectionsAmount - 1);
      setTranslate(Math.max(0, Math.min(newTranslate, maxTranslate)));
    },
    [sectionsAmount, sectionHeight, setTranslate]
  );

  const handleTouchEnd = useCallback(() => {
    const totalSectionHeight = sectionHeight * activeSection;
    const snapDifference = translate - totalSectionHeight;

    if (Math.abs(snapDifference) < snapSensitivity) {
      setTranslate(activeSection * sectionHeight);
    } else {
      const newSection = Math.max(
        0,
        Math.min(
          snapDifference > 0 ? activeSection + 1 : activeSection - 1,
          sectionsAmount - 1
        )
      );

      setActiveSection(newSection);
      setTranslate(newSection * sectionHeight);
    }
  }, [
    translate,
    sectionHeight,
    activeSection,
    snapSensitivity,
    setActiveSection,
    setTranslate,
    sectionsAmount,
  ]);

  useEffect(() => {
    if (device === "mobile") {
      setTranslate(activeSection * sectionHeight);

      if (thumbRef.current) {
        thumbRef.current.style.transform = `translateY(${
          (activeSection * sectionHeight) / sectionsAmount
        }px)`;
      }
    }
  }, [activeSection, device, sectionHeight, setTranslate, sectionsAmount]);

  if (device === "desktop") {
    return (
      <div className="scroll-container">
        {Children.map(children, (child, index) => {
          if (child)
            return (
              <section key={index} className="scroll-section">
                <div>{child}</div>
              </section>
            );
        })}
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className={styles.container}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      data-active-section={activeSection}
    >
      <div
        className={styles.content}
        style={{
          transform: `translateY(-${translate}px)`,
          gap: itemSpacing,
        }}
      >
        {Children.map(children, (child, index) => (
          <div
            className={styles.containerSection10}
            id={`section-${index}`}
            key={index}
            data-section-index={index}
          >
            {child}
          </div>
        ))}
      </div>
      <div ref={scrollbarRef} className={styles.scrollbar}>
        <div
          ref={thumbRef}
          className={styles.scrollbarThumb}
          style={{
            height: `${100 / sectionsAmount}%`,
            transform: `translateY(${
              (activeSection * sectionHeight) / sectionsAmount
            }px)`,
            background: isWeb3
              ? "transparent"
              : [2, 13].includes(activeSection)
              ? "#ffffff"
              : "#00cb94",
          }}
        />
      </div>
    </div>
  );
};
