"use client";

import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { CustomButtonSection } from "components/custom-button-section";
import shemaImg from "../../assets-web3/image/section3-web3-shema.png";
import { DistributionItem } from "./distributionItemProps";
import { ScrollContext } from "web3-landing-page";
import { useContext } from "react";

export const Section4Web3 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const { scrollToSection } = useContext(ScrollContext);

  const leftColumnData = [
    { text: "Seed Round 6.00%", color: "#D0FFF2" },
    { text: "Private Round 12.00%", color: "#BBFAE9" },
    { text: "KOL round 3.00%", color: "#A6F5DF" },
    { text: "Strategic round 6.00%", color: "#92EFD6" },
    { text: "IDO 2.00%", color: "#7DEACC" },
    { text: "Team 9.00%", color: "#53E0BA" },
    { text: "Marketing 9.00%", color: "#2AD5A7" },
  ];

  const rightColumnData = [
    { text: "Advisors 3.00%", color: "#00CB94" },
    { text: "Liquidity 10.00%", color: "#00A276" },
    { text: "Rewards 20.00%", color: "#007A59" },
    { text: "Airdrop 2.00%", color: "#01654A" },
    { text: "Development 9.00%", color: "#015B43" },
    { text: "Treasury 9.00%", color: "#024D38" },
  ];

  return (
    <div className={styles.container}>
      {isDesktop ? (
        <>
          <div className={styles.leftContent}>
            <CustomHeadline
              size={60}
              text="SCRB: The Cognitive Economy"
              color={theme.colors.neutral[20]}
              styles={{
                fontWeight: 600,
                lineHeight: 54,
                textAlign: "left",
                width: "100%",
                position: "relative",
              }}
            />
            <div className={styles.centuries}>
              <CustomText
                size={28}
                text="$SCRB powers AI intelligence, governance & rewards—designed for lasting impact."
                color={theme.colors.neutral[20]}
                styles={{
                  textAlign: "left",
                  margin: "0",
                  lineHeight: 24,
                  fontWeight: 400,
                }}
              />
            </div>
            <CustomButtonSection
              className={styles.buyButton}
              text="Buy $SCRB"
              colorScheme="green"
              onClick={() => scrollToSection("payment")}
              style={{
                color: theme.colors.neutral[20],
              }}
            />
          </div>
          <div className={styles.middleContent}>
            <img
              src={shemaImg || "/placeholder.svg"}
              alt="token distribution schema"
              className={styles.schemaImage}
            />
          </div>
          <div className={styles.rightContent}>
            <div className={styles.containerColor}>
              <div className={styles.column} style={{ fontSize: "1.2dvw" }}>
                {leftColumnData.map((item, index) => (
                  <DistributionItem
                    key={index}
                    text={item.text}
                    color={item.color}
                  />
                ))}
              </div>
              <div className={styles.column} style={{ fontSize: "1.2dvw" }}>
                {rightColumnData.map((item, index) => (
                  <DistributionItem
                    key={index}
                    text={item.text}
                    color={item.color}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.content}>
          <CustomHeadline
            size={22}
            text="SCRB: The Cognitive Economy"
            color={theme.colors.neutral[20]}
            styles={{
              fontWeight: 600,
              lineHeight: 26,
              textAlign: "center",
              width: "auto",
              position: "static",
            }}
          />
          <div className={styles.centuries}>
            <CustomText
              size={16}
              text="$SCRB powers AI intelligence, governance & rewards—designed for lasting impact."
              color={theme.colors.neutral[20]}
              styles={{
                textAlign: "center",
                margin: "0 auto",
                lineHeight: 18,
                fontWeight: 400,
              }}
            />
          </div>
          <img
            src={shemaImg || "/placeholder.svg"}
            alt="token distribution schema"
          />
          <div className={styles.containerColor}>
            <div className={styles.column} style={{ fontSize: 13 }}>
              {leftColumnData.map((item, index) => (
                <DistributionItem
                  key={index}
                  text={item.text}
                  color={item.color}
                />
              ))}
            </div>
            <div className={styles.column} style={{ fontSize: 15 }}>
              {rightColumnData.map((item, index) => (
                <DistributionItem
                  key={index}
                  text={item.text}
                  color={item.color}
                />
              ))}
            </div>
          </div>
          <CustomButtonSection
            className={styles.buyButton}
            text="Buy $SCRB"
            colorScheme="green"
            onClick={() => scrollToSection("payment")}
            style={{
              color: theme.colors.neutral[20],
              marginTop: "6.9dvw",
            }}
          />
        </div>
      )}
    </div>
  );
};
