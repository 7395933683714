import React from "react";
import styles from "./styles.module.css";

interface DistributionItemProps {
  text: string;
  color?: string;
}

export const DistributionItem: React.FC<DistributionItemProps> = ({
  text,
  color = "#48D992",
}) => {
  return (
    <div className={styles.item}>
      <span className={styles.bullet} style={{ backgroundColor: color }} />
      {text}
    </div>
  );
};
