import { useState } from 'react';
import { BrowserProvider, ethers, JsonRpcSigner, parseEther, parseUnits } from 'ethers';
import { useAccount, useChainId } from 'wagmi';
import { toast } from 'react-toastify';

// USDT addresses and decimals for different networks
const USDT_CONFIG: any = {
    1: {  // Ethereum Mainnet
        address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        decimals: 6
    },
    56: { // BSC Mainnet
        address: '0x55d398326f99059fF775485246999027B3197955',
        decimals: 18
    },
    8453: { // BASE Mainnet
        address: '0xfde4C96c8593536E31F229EA8f37b2ADa2699bb2',
        decimals: 6
    }
};

// USDC addresses and decimals for different networks
const USDC_CONFIG: any = {
    1: {  // Ethereum Mainnet
        address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        decimals: 6
    },
    56: { // BSC Mainnet
        address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        decimals: 18
    },
    8453: { // BASE Mainnet
        address: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
        decimals: 6
    }
};

interface EthereumContextProvider {
    provider: BrowserProvider | undefined,
    signer: JsonRpcSigner | undefined,
    buyTokens: (amount: string, tokenType: string, bonusCode: string) => void,
    approveAndContributeLoading: boolean,
    txHash: string | undefined
  }

const COLD_WALLET = '0xBB0eBDF7689B5463891529958867FFAf184EAf7f' as const;

export function useEthereumContext(): EthereumContextProvider  {

    const chainId = useChainId()
    
    const [provider, setProvider] = useState<BrowserProvider | undefined>();
    const [signer, setSigner] = useState<JsonRpcSigner | undefined>();
    const [approveAndContributeLoading, setApproveAndContributeLoading] = useState(false);
    const [txHash, setTxHash] = useState<string | undefined>();

    const account = useAccount();

    const tokenAbi = [
        "function approve(address spender, uint256 amount) external returns (bool)",
        "function transfer(address to, uint256 amount) external returns (bool)",
        "function balanceOf(address account) external view returns (uint256)",
        "function allowance(address owner, address spender) external view returns (uint256)"
    ];

    const saveBonusCode = async (hash: string, code: string) => {
        try {
            await fetch('https://seahorse-app-e5jj2.ondigitalocean.app/backend2/users/bonus', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    address: account.address,
                    transactionHash: hash,
                    network: chainId,
                    code: code
                })
            })
        } catch (_) {
            // fail silently
        }
    }


    const buyTokens = async (amount: string, tokenType: string, bonusCode: string) => {
        const provider = new BrowserProvider(window.ethereum, "any");
        const signer = await provider.getSigner();
        
        // Update current chainId

        setProvider(provider);
        setSigner(signer);
        if (!signer) {
            toast.error('Please connect your wallet');
            return;
        }

        if (!COLD_WALLET) {
            toast.error('Receiver address not configured');
            return;
        }


        setApproveAndContributeLoading(true);
        try {
            if (tokenType === 'USDT' || tokenType === 'USDC') {
                // Get the correct USDT/USDC configuration for the current network
                const tokenConfig = tokenType === 'USDT' ? USDT_CONFIG[chainId] : USDC_CONFIG[chainId];
                if (!tokenConfig) {
                    throw new Error(`${tokenType} not supported on this network`);
                }

                const tokenContract = new ethers.Contract(tokenConfig.address, tokenAbi, signer);
                const tokenAmount = parseUnits(amount.toString(), tokenConfig.decimals);

                // Check token balance
                const balance = await tokenContract.balanceOf(account.address);
                if (balance < tokenAmount) {
                    throw new Error(`Insufficient ${tokenType} balance.`);
                }

                // Transfer token directly
                const transferTx = await tokenContract.transfer(COLD_WALLET, tokenAmount);
                toast.info('Transferring USDT...');
                const receipt = await transferTx.wait();
                setTxHash(receipt.hash);

                if (bonusCode !== '') {
                  await saveBonusCode(receipt.hash, bonusCode)
                }
                toast.success(`${tokenType} transfer successful!`);
            } else {
                // Native token transfer
                const nativeAmount = parseEther(amount.toString());
                
                // Check native token balance
                const balance = await provider!.getBalance(account.address!);
                if (balance < nativeAmount) {
                    throw new Error(`Insufficient ${tokenType} balance`);
                }

                // Direct native token transfer
                const tx = await signer.sendTransaction({
                    to: COLD_WALLET,
                    value: nativeAmount
                });
                toast.info(`Transferring ${tokenType}...`);
                const receipt = await tx.wait();

                toast.success(`${tokenType} transfer successful!`);
                setTxHash(receipt?.hash);
                if (bonusCode !== '') {
                    await saveBonusCode(receipt!.hash, bonusCode)
                }

            }

        } catch (error: any) {
            console.error('Transaction error:', error);
            if (error.code === 4001) {
                toast.error('Transaction rejected by user');
            } else if (error.code === -32603) {
                toast.error('Internal JSON-RPC error. Please check your balance and try again.');
            } else {
                toast.error(error.reason || error.message || 'Error while transferring. Please try again later.');
            }
        } finally {
            setApproveAndContributeLoading(false);
        }
    };

    return {
        provider,
        signer,
        buyTokens,
        approveAndContributeLoading,
        txHash
    };
};
