"use client";

import { useEffect, useState } from "react";
import styles from "./styles.module.css";

interface SocialCounterProps {
  minValue: number | string;
  maxValue: number | string;
  icon: React.ReactNode;
  speed?: number;
  step?: number;
}

export const SocialCounter: React.FC<SocialCounterProps> = ({
  minValue,
  maxValue,
  icon,
  speed = 1,
  step = 5,
}) => {
  const min = Number(minValue);
  const max = Number(maxValue);
  const [count, setCount] = useState(min);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => {
        const next = prev + step;
        return next > max ? min : next;
      });
    }, speed);
    return () => clearInterval(interval);
  }, [min, max, speed, step]);

  const formatted = String(count);
  return (
    <div className={styles.block}>
      <div className={styles.icon}>{icon}</div>
      <span className={styles.text}>{formatted}</span>
    </div>
  );
};
