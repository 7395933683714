import styles from "./styles.module.css";
import { Section14Web3 } from "../section14-web3";
import { Section12Web3 } from "../section12-web3/indev";

export const MobileSection9 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Section12Web3 />
        <Section14Web3 />
      </div>
    </div>
  );
};