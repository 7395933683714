import { CustomText } from "components/ui";
import styles from "./styes.module.css";
import { useDeviceType } from "hooks";
import { CustomButtonSection } from "components/custom-button-section";
import { theme } from "constants/theme";
import { InstSection12, TelegramSection12, TwitterSection12 } from "assets/svg";
import { CardTeam } from "./card-team";
import IgarIcon from "assets/images/igar-section-12.png";
import MarkIcon from "assets/images/mark-section-12.png";
import OmerIcon from "assets/images/omer-section-12.png.png";
import { useNavigate } from "react-router-dom";

export const Block2 = () => {
  const { device } = useDeviceType();
  const navigate = useNavigate();

  const isDesktop = device === "desktop";
  const handleExploreClick = () => {
    navigate("/detail/team");
  };

  return (
    <>
      <div className={styles.container}>
        <div className={`${styles.block2} ${styles.right}`}>
          {/* Contact block */}
          <div className={styles.contactBlock}>
            <CustomText
              size={isDesktop ? 24 : 18}
              text="Prefer email? Reach us at talk@MindScriber.com"
              color={theme.colors.neutral[0]}
              styles={{
                maxWidth: 282,
                textAlign: "left",
                lineHeight: `${isDesktop ? 36 : 30}px`,
              }}
            />
            <div className={styles.icons}>
              <div className={styles.icon}>
                <TelegramSection12 width={isDesktop ? 30 : 24} />
              </div>
              <div className={styles.icon}>
                <InstSection12 width={isDesktop ? 30 : 24} />
              </div>
              <div className={styles.icon}>
                <TwitterSection12 width={isDesktop ? 30 : 24} />
              </div>
            </div>
            <CustomText
              size={14}
              text="Privacy Policy"
              color={theme.colors.neutral[0]}
              styles={{
                lineHeight: 17,
              }}
            />
          </div>

          {/* A block of team cards */}

          <div className={styles.teamBlock}>
            <CardTeam
              img={IgarIcon}
              p1="Igar Dyachenko"
              p2="Co-Founder, CEO"
              p3=" A dyslexic visionary redefining learning tools for
                neurodivergent individuals."
            />
            <CardTeam
              img={MarkIcon}
              p1="Dr. Mark Litvak"
              p2="Co-Founder, Chief Science Officer"
              p3="  A neuroscientist pushing cognitive performance breakthroughs."
            />
            <CardTeam
              img={OmerIcon}
              p1="Omer Cohen"
              p2="Chief Technology Officer"
              p3="  Full Stack developer advancing innovative learning tools.."
            />
          </div>
          <CustomButtonSection
            text="All team"
            colorScheme="white"
            onClick={handleExploreClick}
          />
        </div>
      </div>
    </>
  );
};
