import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { CustomButtonSection } from "components/custom-button-section";
import sImg from "../../assets-web3/image/s-img-section9.png";
import tokenImage from "../../../assets/svg/section9Image.svg";
import { useContext } from "react";
import { ScrollContext } from "web3-landing-page";

export const Section9Web3 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  const { scrollToSection } = useContext(ScrollContext);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.centuries}>
          <CustomHeadline
            size={isDesktop ? 60 : 24}
            text="MindScriber Hardware: The Neural Bridge Device"
            color={
              isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[0]
            }
            styles={{
              fontWeight: 600,
              lineHeight: isDesktop ? 54 : 26,
              textAlign: isDesktop ? "left" : "center",
            }}
          />
          <CustomText
            size={isDesktop ? 24 : 19}
            text="Common wearables track surface metrics like heart rate and steps but lack the depth to capture cognitive and emotional states for real AI adaptation."
            color={theme.colors.neutral[0]}
            styles={{
              textAlign: isDesktop ? "left" : "center",
              margin: isDesktop ? "0" : "0 auto",
              lineHeight: isDesktop ? 28 : 23,
              fontWeight: 400,
              width: isDesktop ? "78%" : "auto",
            }}
          />
          <CustomText
            size={isDesktop ? 24 : 19}
            text="MindScriber’s neuroadaptive wearable changes this. Measuring HRV, RR intervals, and vascular activity, it creates a real-time neural feedback loop, enabling AI to adapt dynamically to stress, focus, and fatigue."
            color={theme.colors.neutral[0]}
            styles={{
              textAlign: isDesktop ? "left" : "center",
              margin: isDesktop ? "0" : "0 auto",
              lineHeight: isDesktop ? 28 : 23,
              fontWeight: 400,
              width: isDesktop ? "78%" : "auto",
            }}
          />
          {isDesktop && (
            <CustomButtonSection
              text="Buy $SCRB Now"
              colorScheme="green"
              onClick={() => scrollToSection("payment")}
              style={{
                color: theme.colors.neutral[0],
              }}
            />
          )}
        </div>

        {isDesktop ? (
          <div className={styles.tokenColumn}>
            <img
              src={isDesktop ? tokenImage : sImg}
              alt="icon"
              className="tokenImage"
            />
            <CustomText
              size={16}
              text="The Most Advanced AI + Neurofeedback Wearable"
              color={theme.colors.neutral[0]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
                lineHeight: "21px",
                fontWeight: 400,
              }}
            />
          </div>
        ) : (
          <>
            <img
              src={!isDesktop ? tokenImage : sImg}
              alt="icon"
              className="tokenImage"
            />
            <CustomText
              size={16}
              text="The Most Advanced AI + Neurofeedback Wearable"
              color={theme.colors.neutral[0]}
              styles={{
                fontStyle: "italic",
                textAlign: "center",
                margin: "0 auto",
                lineHeight: "21px",
                fontWeight: 400,
                width: "95%",
              }}
            />
          </>
        )}

        {!isDesktop && (
          <CustomButtonSection
            text="Buy $SCRB Now"
            colorScheme="green"
            onClick={() => scrollToSection("payment")}
            style={{
              color: theme.colors.neutral[0],
            }}
          />
        )}
      </div>
    </div>
  );
};
