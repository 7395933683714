import styles from "./styles.module.css";
import { useDeviceType } from "hooks";
import { theme } from "../../../constants/theme";
import { CustomHeadline, CustomText } from "components/ui";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/section-10.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AudioOn from "assets/svg/audio-on";
import AudioOff from "assets/svg/audio-off";

export const Section10: React.FC = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const [isAudioOn, setIsAudioOn] = useState(true);

  const toggleAudio = () => {
    setIsAudioOn((prev) => !prev);
  };

  const navigate = useNavigate();
  const handleExploreClick = () => {
    navigate("/detail/token-economy");
  };

  const handleBuyTokenClick = () => {
    window.location.href = "https://web3.mindscriber.com/#payment";
  };

  return (
    <div className={styles.container}>
      <div className={styles.blockContent}>
        <div className={styles.content}>
          <CustomHeadline
            size={isDesktop ? 55 : 24}
            text="$SCRB IDO – Fueling the AI Economy."
            color={
              isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[0]
            }
            styles={{ lineHeight: `${isDesktop ? 52 : 26}px` }}
          />
          <div className={styles.p}>
            <CustomText
              size={isDesktop ? 22 : 14}
              text="Built for AI autonomy and decentralized intelligence, $SCRB scales securely, rewards engagement, and powers sustainable growth."
              color={
                isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[0]
              }
              styles={{
                maxWidth: "450px",
                lineHeight: `${isDesktop ? 28 : 17}px`,
              }}
            />
          </div>
          <div
            className={styles.blockButton}
            style={{ marginTop: isDesktop ? "2%" : "7%" }}
          >
            <CustomButtonSection
              text="How to Join"
              colorScheme="green"
              onClick={handleExploreClick}
              style={{
                color: isDesktop
                  ? theme.colors.neutral[20]
                  : theme.colors.neutral[0],
              }}
            />
            <CustomButtonSection
              text="Buy $SCRB"
              colorScheme="green"
              onClick={handleBuyTokenClick}
              style={{
                color: isDesktop
                  ? theme.colors.neutral[20]
                  : theme.colors.neutral[0],
              }}
            />
          </div>
        </div>
      </div>
      <img className={styles.image} src={fon} alt="fon" />
      {/* <div className={styles.imgAudio} onClick={toggleAudio}>
        {isAudioOn ? <AudioOn /> : <AudioOff />}
      </div> */}
    </div>
  );
};
