import styles from "./styles.module.css";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";
import { theme } from "constants/theme";
import { CustomButtonSection } from "components/custom-button-section";
import check from "../../assets-web3/image/check-section6web3.png";
import network from "../../assets-web3/image/image 91.png";
import tolen from "../../assets-web3/image/Screenshot 2025-01-30 at 21.15.33 2.png";
import { useContext } from "react";
import { ScrollContext } from "web3-landing-page";

export const Section6Web3 = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const { scrollToSection } = useContext(ScrollContext);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {!isDesktop && (
          <div className={styles.centuries}>
            <CustomHeadline
              size={24}
              text="IDO Details"
              color={
                isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[20]
              }
              styles={{
                fontWeight: 600,
                lineHeight: "26px",
                textAlign: "center",
              }}
            />
            <CustomText
              size={isDesktop ? 0 : 17}
              text="MindScriber’s Initial Decentralized Offering (IDO) powers the ecosystem."
              color={theme.colors.neutral[20]}
              styles={{
                textAlign: "center",
                margin: "0 auto",
                lineHeight: "23px",
                fontWeight: 400,
                width: "85%",
              }}
            />
          </div>
        )}

        {!isDesktop && (
          <div className={styles.networkTockenBlock}>
            <div className={styles.network}>
              <CustomText
                size={isDesktop ? 0 : 17}
                text="Network"
                color={theme.colors.neutral[0]}
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: "23px",
                  fontWeight: 400,
                }}
              />
              <img src={network} alt="img" />
              <CustomText
                size={isDesktop ? 0 : 17}
                text="Base"
                color={theme.colors.neutral[0]}
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: "23px",
                  fontWeight: 400,
                }}
              />
            </div>

            <div className={styles.network}>
              <CustomText
                size={isDesktop ? 0 : 17}
                text="Token supply"
                color={theme.colors.neutral[20]}
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: "23px",
                  fontWeight: 400,
                }}
              />
              <img src={tolen} alt="img" />
              <CustomText
                size={isDesktop ? 0 : 17}
                text="9b SCRB"
                color={theme.colors.neutral[20]}
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: "23px",
                  fontWeight: 400,
                }}
              />
            </div>
          </div>
        )}

        <div className={styles.centuries}>
          <CustomHeadline
            size={22}
            text="How to Participate in the IDO"
            color={
              isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[20]
            }
            styles={{
              fontWeight: 600,
              lineHeight: "30px",
              textAlign: isDesktop ? "left" : "center",
            }}
          />
          <div className={styles.blockInfo}>
            <div className={styles.card}>
              <img src={check} alt="check" />
              <CustomText
                size={isDesktop ? 24 : 15}
                text="Use a Base-compatible wallet"
                color={
                  isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[20]
                }
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: isDesktop ? "24px" : "21px",
                  fontWeight: 400,
                }}
              />
            </div>

            <div className={styles.card}>
              <img src={check} alt="check" />
              <CustomText
                size={isDesktop ? 24 : 15}
                text="Acquire ETH for transaction fees"
                color={
                  isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[20]
                }
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: isDesktop ? "24px" : "21px",
                  fontWeight: 400,
                }}
              />
            </div>

            <div className={styles.card}>
              <img src={check} alt="check" />
              <CustomText
                size={isDesktop ? 24 : 15}
                text="Register for whitelist access"
                color={
                  isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[20]
                }
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: isDesktop ? "24px" : "21px",
                  fontWeight: 400,
                }}
              />
            </div>

            <div className={styles.card}>
              <img src={check} alt="check" />
              <CustomText
                size={isDesktop ? 24 : 15}
                text="Participate via a verified launchpad"
                color={
                  isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[20]
                }
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: isDesktop ? "24px" : "21px",
                  fontWeight: 400,
                }}
              />
            </div>

            <div className={styles.card}>
              <img src={check} alt="check" />
              <CustomText
                size={isDesktop ? 24 : 15}
                text="Secure your SCRB.You’re good to go. 🚀"
                color={
                  isDesktop ? theme.colors.neutral[0] : theme.colors.neutral[20]
                }
                styles={{
                  textAlign: "center",
                  margin: "0 auto",
                  lineHeight: isDesktop ? "24px" : "21px",
                  fontWeight: 400,
                }}
              />
            </div>
          </div>
        </div>

        {!isDesktop && (
          <CustomButtonSection
            text="Get $SCRB"
            colorScheme="green"
            onClick={() => scrollToSection("payment")}
            style={{
              color: theme.colors.neutral[20],
              marginTop: "1.35dvw",
              border: "3px solid #00CB94",
            }}
          />
        )}
      </div>
    </div>
  );
};
