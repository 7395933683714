import styles from "./styles.module.css";
import { useDeviceType } from "hooks";
import { theme } from "../../../constants/theme";
import { CustomHeadline, CustomText } from "components/ui";
import { CustomButtonSection } from "components/custom-button-section";
import fon from "assets/images/fon-sectionscriber.png";
import sBlack from "assets/images/s-black.png";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import AudioOn from "assets/svg/audio-on";
import AudioOff from "assets/svg/audio-off";

export const ScriberWearable: React.FC = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const [isAudioOn, setIsAudioOn] = useState(true);
  const toggleAudio = () => {
    setIsAudioOn((prev) => !prev);
  };

  const navigate = useNavigate();
  const handleExploreClick = () => {
    navigate("/detail/scriber wearable");
  };

  return (
    <div className={styles.container}>
      <div className={styles.blockContent}>
        <div className={styles.content}>
          <CustomHeadline
            size={isDesktop ? 55 : 24}
            text="Scriber Wearable – Unlocking Human Potential"
            color={
              isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[0]
            }
            styles={{ lineHeight: `${isDesktop ? 52 : 26}px` }}
          />
          <div className={styles.p}>
            <CustomText
              size={isDesktop ? 24 : 14}
              text="Real-time cognitive optimization. Adaptive neurofeedback. The next evolution in human-AI synergy."
              color={
                isDesktop ? theme.colors.neutral[20] : theme.colors.neutral[0]
              }
              styles={{
                maxWidth: "450px",
                lineHeight: `${isDesktop ? 28 : 17}px`,
              }}
            />
          </div>
          <CustomButtonSection
            text="Discover More"
            colorScheme="green"
            onClick={handleExploreClick}
            style={{
              color: isDesktop
                ? theme.colors.neutral[20]
                : theme.colors.neutral[0],
              marginTop: isDesktop ? "2%" : "7%",
            }}
          />
        </div>
      </div>
      <img className={styles.image} src={fon} alt="fase" />
      <img className={styles.imageSblack} src={sBlack} alt="img" />
      {/* <img className={styles.imageSblack} src={sBlack} alt="img" /> */}
      {/* <div className={styles.imgAudio} onClick={toggleAudio}>
        {isAudioOn ? <AudioOn /> : <AudioOff />}
      </div> */}
    </div>
  );
};
