namespace PresaleOffer {
  export const getCurrentNetwork = (chainId: number) => {
    switch (chainId) {
      case 8453:
      case 84532:
        return "BASE";
      case 1:
        return "ETH";
      case 56:
        return "BSC";
    }
  };

  const getTxLink = (chainId: number, txHash: string) => {
    switch (chainId) {
      case 8453:
        return `https://basescan.org/tx/${txHash}`;
      case 1:
        return `https://etherscan.io/tx/${txHash}`;
      case 84532:
        return `https://sepolia.basescan.org/tx/${txHash}`;
      case 56:
        return `https://bscscan.com/${txHash}`;
    }
  };
}
export { PresaleOffer };
