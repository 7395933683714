import styles from "./styles.module.css";
import { Block1 } from "./block-1/index";
import { Block2 } from "./block-2/index";
import { useDeviceType } from "hooks";

export const Section12 = () => {
  const { device } = useDeviceType();

  const isDesktop = device === "desktop";
  return (
    <>
      <div className={styles.container}>
        <Block1 />
        {isDesktop && <Block2 />}
      </div>
    </>
  );
};
